import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { MaterialAmount } from '../../../components/MaterialAmount/MaterialAmount'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link MaterialAmount} component
 * for developers
 */
export const MaterialAmountComponent: FC = () => {
  return <>
    <div>
      <ComponentWrapper title='Gold large'>
        <MaterialAmount type='GOLD' amount={50} size='large' />
      </ComponentWrapper>
      <ComponentWrapper title='Gold default'>
        <MaterialAmount type='GOLD' amount={50} />
      </ComponentWrapper>
      <ComponentWrapper title='Gold small'>
        <MaterialAmount type='GOLD' amount={50} size='small' />
      </ComponentWrapper>
    </div>
    <div>
      <ComponentWrapper title='Uranium large'>
        <MaterialAmount type='URANIUM' amount={40} size='large' />
      </ComponentWrapper>
      <ComponentWrapper title='Uranium default'>
        <MaterialAmount type='URANIUM' amount={40} />
      </ComponentWrapper>
      <ComponentWrapper title='Uranium small'>
        <MaterialAmount type='URANIUM' amount={40} size='small' />
      </ComponentWrapper>
    </div>
    <div>
      <ComponentWrapper title='Iron large'>
        <MaterialAmount type='IRON' amount={30} size='large' />
      </ComponentWrapper>
      <ComponentWrapper title='Iron default'>
        <MaterialAmount type='IRON' amount={30} />
      </ComponentWrapper>
      <ComponentWrapper title='Iron small'>
        <MaterialAmount type='IRON' amount={30} size='small' />
      </ComponentWrapper>
    </div>
    <div>
      <ComponentWrapper title='Coal large'>
        <MaterialAmount type='COAL' amount={20} size='large' />
      </ComponentWrapper>
      <ComponentWrapper title='Coal default'>
        <MaterialAmount type='COAL' amount={20} />
      </ComponentWrapper>
      <ComponentWrapper title='Coal small'>
        <MaterialAmount type='COAL' amount={20} size='small' />
      </ComponentWrapper>
    </div>
  </>
}
