import { FC, PropsWithChildren } from 'react'
import classnames from 'classnames'

import './RoundedFrame.scss'

type RoundedFrameProps = PropsWithChildren<{
  /**
   * Class name for the component
   */
  className?: string
  /**
   * Data test id is used by Jest
   */
  dataTestId?: string
  /**
   * Hides the additional border layers if passed.
   * By default, the frame shows a few borders.
   */
  hideAdditionalBorders?: boolean
}>

/**
 * Returns an element with a rounded frame around it
 */
export const RoundedFrame: FC<RoundedFrameProps> = ({
  children, className = '', dataTestId = '',
  hideAdditionalBorders,
}) => {
  return <div
    className={classnames('rounded-frame', className, {
      'rounded-frame--additional-borders-hidden': hideAdditionalBorders,
    })}
    data-testid={dataTestId}
  >
    <span className='rounded-frame__corner rounded-frame__corner--top-left' />
    <span className='rounded-frame__corner rounded-frame__corner--top-right' />
    <span className='rounded-frame__corner rounded-frame__corner--bottom-left' />
    <span className='rounded-frame__corner rounded-frame__corner--bottom-right' />
    <div className='rounded-frame__content'>
      {children}
    </div>
  </div>
}
