import { AtomicAsset } from './types/asset'
import { envVars } from '../../services/envVariables'

type GetAssetsOptions = {
  /**
   * Owner of the assets
   */
  owner?: string
  /**
   * Number of the page
   */
  page?: number
  /**
   * Only query assets from these collections
   */
  allowedCollections?: string[]
  /**
   * IDs of assets
   */
  ids?: string[]
  /**
   * Quantity of objects per page
   */
  limit?: number
  /**
   * Schema name
   */
  schemaName?: string
  /**
   * Array of template ids to be filtered out
   */
  templateIds?: number[]
}

type GetAssetsSuccessResponse = {
  /**
   * Status of response
   */
  success: true
  /**
   * Array of assets
   */
  data: AtomicAsset[]
  /**
   * Date of response
   */
  query_time: number
}

type GetAssetsFailedResponse = {
  /**
   * Status of response
   */
  success: false
  /**
   * Error message
   */
  message: string
}

type GetAssetsResponse = GetAssetsSuccessResponse | GetAssetsFailedResponse

export const getAssets = async (options: GetAssetsOptions): Promise<GetAssetsSuccessResponse> => {
  // It's only possible to get 100 NFTs at once from AtomicHub.
  // If more than 100 ids are requested, "paginate".
  if (options.ids && options.ids.length > 100) {
    const currentBatch = await getAssets({
      ...options,
      ids: options.ids.slice(0, 100)
    })
    const otherBatch = await getAssets({
      ...options,
      ids: options.ids.slice(100)
    })
    const allAssets = currentBatch.data.concat(otherBatch.data)
    return {
      ...otherBatch,
      data: allAssets
    }
  }

  const params = new URLSearchParams()
  if (options.ids && options.ids.length > 0) {
    params.append('ids', options.ids.join(','))
  }
  if (options.page) {
    params.append('page', options.page.toString())
  }
  if (options.owner) {
    params.append('owner', options.owner)
  }
  if (options.allowedCollections && options.allowedCollections.length > 0) {
    params.append('collection_whitelist', options.allowedCollections.join(','))
  }
  if (options.limit && options.limit > 0) {
    params.append('limit', options.limit.toString())
  }
  if (options.schemaName) {
    params.append('schema_name', options.schemaName)
  }
  if (options.templateIds && options.templateIds.length > 0) {
    params.append('template_id', options.templateIds.join(','))
  }
  // Create request URL endpoint with the query parameters
  const endpoint = new URL(`${envVars.WAX_ATOMICHUB_API}/atomicassets/v1/assets`)
  endpoint.search = params.toString()

  const response = await fetch(endpoint.toString())
  const formattedResponse: GetAssetsResponse = await response.json()
  if (!formattedResponse.success) {
    throw new Error(formattedResponse.message)
  }
  return formattedResponse
}
