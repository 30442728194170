import { FC, PropsWithChildren } from 'react'
import classnames from 'classnames'
import { RectangleFrame } from '../RectangleFrame/RectangleFrame'
import LockIcon from '../../assets/images/icons/lock.svg'
import './EmptySlot.scss'

/**
 * Represents all possible props for {@link EmptySlot}
 */
type EmptySlotProps = PropsWithChildren<{
  /**
   * Indicates if the icon with a lock is shown
   * @default undefined which means the lock icon is not shown by default
   */
  lockIconShown?: boolean
  /**
   * Text for the warning block. Warning block is text inside with red color.
   * Can be passed any string to warn a user about anything.
   * For example when the slot is locked can be passed "Slot is locked" and then
   * the user is notified by the red "Slot is locked" text inside the component.
   * @default undefined which means there is no warning block by default
   */
  warningLabel?: string
  /**
   * Class name for the component
   * @default undefined which means no class name is applied by default
   */
  className?: string
}>

/**
 * Renders an empty slot with a button. The button can be adjusted via props.
 */
export const EmptySlot: FC<EmptySlotProps> = props => {
  return <div className={classnames('empty-slot', props.className)} data-testid='empty-slot'>
    <RectangleFrame className='empty-slot__frame' dashedBorder>
      {props.lockIconShown && <img src={LockIcon} className='empty-slot__lock-media' alt='Yellow lock' />}
      {props.children}
      {props.warningLabel && (
        <div className='empty-slot__warning'>{props.warningLabel}</div>
      )}
    </RectangleFrame>
  </div>
}
