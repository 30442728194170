import { ComponentWrapper } from '../ComponentsView'
import { FC } from 'react'
import { getMockAsset } from '../../../../test/mocks/asset'
import { LandWithCharacterCard } from '../../../components/LandWithCharacterCard/LandWithCharacterCard'

const mockLandAsset = getMockAsset()
mockLandAsset.data.img = 'QmdJBQFgkvZhK5ssqo7gJxLr3fRB2RvJA7eNaQx7yJCSoR'
mockLandAsset.data.x = 4
mockLandAsset.data.y = 6
mockLandAsset.data.material = 'Gold'
const mockCharacterAsset = getMockAsset()
mockCharacterAsset.data.level = 1
mockCharacterAsset.data.power = 10
mockCharacterAsset.data.rarity = 'Mythic'
if (mockCharacterAsset.template) {
  mockCharacterAsset.template.template_id = '668145'
}

const onClick = () => alert('Hello from land with character card!')

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link LandWithCharacterCard} component
 * for developers
 */
export const LandWithCharacterCardComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Default'>
      <LandWithCharacterCard
        landAsset={mockLandAsset} onClick={onClick}
        characterAsset={mockCharacterAsset}
      />
    </ComponentWrapper>
    <ComponentWrapper title='Selected'>
      <LandWithCharacterCard
        landAsset={mockLandAsset} onClick={onClick}
        characterAsset={mockCharacterAsset} selected
      />
    </ComponentWrapper>
    <ComponentWrapper title='Disabled'>
      <LandWithCharacterCard
        landAsset={mockLandAsset} onClick={onClick}
        characterAsset={mockCharacterAsset} disabled
      />
    </ComponentWrapper>
  </div>
}
