import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { LandCard } from '../../../components/LandCard/LandCard'
import { getMockAsset } from '../../../../test/mocks/asset'

const mockLandGoldAsset = getMockAsset()
mockLandGoldAsset.data.x = 10
mockLandGoldAsset.data.y = 6
mockLandGoldAsset.data.material = 'Gold'
mockLandGoldAsset.data.img = 'QmdJBQFgkvZhK5ssqo7gJxLr3fRB2RvJA7eNaQx7yJCSoR'

const mockLandUraniumAsset = getMockAsset()
mockLandUraniumAsset.data.x = 10
mockLandUraniumAsset.data.y = 6
mockLandUraniumAsset.data.material = 'Uranium'
mockLandUraniumAsset.data.img = 'QmdJBQFgkvZhK5ssqo7gJxLr3fRB2RvJA7eNaQx7yJCSoR'

const mockLandIronAsset = getMockAsset()
mockLandIronAsset.data.x = 10
mockLandIronAsset.data.y = 6
mockLandIronAsset.data.material = 'Iron'
mockLandIronAsset.data.img = 'QmdJBQFgkvZhK5ssqo7gJxLr3fRB2RvJA7eNaQx7yJCSoR'

const mockLandCoalAsset = getMockAsset()
mockLandCoalAsset.data.x = 10
mockLandCoalAsset.data.y = 6
mockLandCoalAsset.data.material = 'Coal'
mockLandCoalAsset.data.img = 'QmdJBQFgkvZhK5ssqo7gJxLr3fRB2RvJA7eNaQx7yJCSoR'

const onClick = () => alert('Hello from land card :)')

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link LandCard} component for developers
 */
export const LandCardGoldComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Gold'>
      <LandCard landAsset={mockLandGoldAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Gold disabled'>
      <LandCard landAsset={mockLandGoldAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Gold selected'>
      <LandCard landAsset={mockLandGoldAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Gold without rounded frame'>
      <LandCard landAsset={mockLandGoldAsset} onClick={onClick} roundedFrameHidden />
    </ComponentWrapper>
    <ComponentWrapper title='Gold short info'>
      <LandCard landAsset={mockLandGoldAsset} onClick={onClick} landInfoShort />
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link LandCard} component for developers
 */
export const LandCardUraniumComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Uranium'>
      <LandCard landAsset={mockLandUraniumAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Uranium disabled'>
      <LandCard landAsset={mockLandUraniumAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Uranium selected'>
      <LandCard landAsset={mockLandUraniumAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Uranium without rounded frame'>
      <LandCard landAsset={mockLandUraniumAsset} onClick={onClick} roundedFrameHidden />
    </ComponentWrapper>
    <ComponentWrapper title='Uranium short info'>
      <LandCard landAsset={mockLandUraniumAsset} onClick={onClick} landInfoShort />
    </ComponentWrapper>
  </div>
}

export const LandCardIronComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Iron'>
      <LandCard landAsset={mockLandIronAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Iron disabled'>
      <LandCard landAsset={mockLandIronAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Iron selected'>
      <LandCard landAsset={mockLandIronAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Iron without rounded frame'>
      <LandCard landAsset={mockLandIronAsset} onClick={onClick} roundedFrameHidden />
    </ComponentWrapper>
    <ComponentWrapper title='Iron short info'>
      <LandCard landAsset={mockLandIronAsset} onClick={onClick} landInfoShort />
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link LandCard} component for developers
 */
export const LandCardCoalComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Coal'>
      <LandCard landAsset={mockLandCoalAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Coal disabled'>
      <LandCard landAsset={mockLandCoalAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Coal selected'>
      <LandCard landAsset={mockLandCoalAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Coal without rounded frame'>
      <LandCard landAsset={mockLandCoalAsset} onClick={onClick} roundedFrameHidden />
    </ComponentWrapper>
    <ComponentWrapper title='Coal short info'>
      <LandCard landAsset={mockLandCoalAsset} onClick={onClick} landInfoShort />
    </ComponentWrapper>
  </div>
}
