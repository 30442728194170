import { useEffect, useRef } from 'react'

/**
 * Hook for calling a callback if a click was done outside a ref
 * @param callback Callback should be called if a click was done outside a ref
 * @return Reference to a Node that should be passed as a `ref` prop to the component
 * you need to handle the click outside for
 */
export function useOutsideClick(callback: () => void) {
  // Reference for the Node you handle the click outside for
  const ref = useRef<HTMLDivElement>(null)

  // Subscribes to the `mousedown` event for the entire application to handle a click outside
  useEffect(() => {
    // It is important to add a listener to the `#app` block, not to the `document`.
    // In this case, we do not involve modals or dialogs for tracking outside clicks because
    // they are rendered via React Portal at the same level as the `#app` block.
    // If we use `document` for events then any click by a modal or a dialog triggers calling the
    // callback function which conflicts with the click outside logic at all.
    const app = document.querySelector('#app')

    if (app) {
      const handleClickOutside = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback()
        }
      }

      app.addEventListener('mousedown', handleClickOutside)
      return () => app.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])

  return ref
}
