import { FC, PropsWithChildren, useEffect } from 'react'
import { RoundedFrame } from '../RoundedFrame/RoundedFrame'
import ReactDOM from 'react-dom'
import './Dialog.scss'
import classnames from 'classnames'

type DialogProps = PropsWithChildren<{
  /**
   * The function is called by click on the backdrop button
   */
  onBackdropClick: () => void
  /**
   * Class name for the component
   */
  className?: string
}>

const DialogComponent: FC<DialogProps> = props => {
  useEffect(() => {
    // Closes dialog by clicking on the "Escape" button
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.onBackdropClick()
      }
    }

    document.body.addEventListener('keydown', onKeyDown)

    return () => document.body.removeEventListener('keydown', onKeyDown)
  }, [])

  return (
    <div className={classnames('dialog', props.className)} data-testid='dialog'>
      <button onClick={props.onBackdropClick} data-testid='dialog-backdrop' className='dialog__backdrop' />
      <RoundedFrame className='dialog__frame' hideAdditionalBorders>
        <div className='dialog__content'>{props.children}</div>
      </RoundedFrame>
    </div>
  )
}

export const Dialog: FC<DialogProps> = props => {
  const container = document.body

  return ReactDOM.createPortal(<DialogComponent {...props} />, container)
}
