import { Dialog } from '../../../../../../components/Dialog/Dialog'
import { FC } from 'react'
import './RecallCharacterFromDojoDialog.scss'
import { RectangleFrame } from '../../../../../../components/RectangleFrame/RectangleFrame'
import { CharacterCard } from '../../../../../../components/CharacterCard/CharacterCard'
import { Button } from '../../../../../../components/Button/Button'
import { useAppDispatch } from '../../../../../../store/types'
import {
  TrainingSlotWithAsset,
  dojoSelector,
  recallCharacterAssetFromDojo,
} from '../../../../../../store/slices/dojoSlice'
import { useSelector } from 'react-redux'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'
import classnames from 'classnames'
import { settingsSelector } from '../../../../../../store/slices/settingsSlice'
import { ButtonsContainer } from '../../../../../../components/ButtonsContainer/ButtonsContainer'

type UnstakeCharacterFromDojoDialogProps = {
  /**
   * Closes the dialog
   */
  closeDialog: () => void
  /**
   * The training slot staked in Dojo with a character asset in there
   */
  trainingSlot: TrainingSlotWithAsset
  /**
   * Function is called after successful character asset unstake
   */
  afterUnstake: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

/**
 * Component for confirming recalling character from dojo.
 */
export const RecallCharacterFromDojoDialog: FC<UnstakeCharacterFromDojoDialogProps> = props => {
  const dispatch = useAppDispatch()
  const dojo = useSelector(dojoSelector)
  const settings = useSelector(settingsSelector)

  const unstake = async () => {
    await dispatch(recallCharacterAssetFromDojo({
      trainingSlot: props.trainingSlot, user: props.user
    }))
    props.afterUnstake()
  }

  const unstakingButtonDisabled = dojo.recallingAssetsFromTrainingSlots[props.trainingSlot.characterAsset.asset_id] === 'pending'

  return <Dialog onBackdropClick={props.closeDialog} className={classnames('recall-character-from-dojo-dialog', {
    'recall-character-from-dojo-dialog--landscape-wide': settings.screenMode === 'LANDSCAPE_WIDE',
  })}>
    <RectangleFrame className='recall-character-from-dojo-dialog__frame'>
      <h4 className='recall-character-from-dojo-dialog__title'>Retrieve this character?</h4>
      <CharacterCard characterAsset={props.trainingSlot.characterAsset} powerDataHidden className='recall-character-from-dojo-dialog__card' />
      <div className='recall-character-from-dojo-dialog__description'>You won’t lose your XP points if you retrieve the wombat in the middle of training.</div>
    </RectangleFrame>
    <ButtonsContainer oneRowInPortraitMode>
      <Button
        onClick={props.closeDialog} appearance='secondary'
        dataTestId='recall-character-from-dojo-dialog-cancel-button'
      >No</Button>
      <Button
        onClick={unstake} disabled={unstakingButtonDisabled}
        dataTestId='recall-character-from-dojo-dialog-confirm-button'
      >Yes</Button>
    </ButtonsContainer>
  </Dialog>
}
