import { FC, PropsWithChildren } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../store/slices/settingsSlice'
import './ButtonsContainer.scss'

type ButtonsContainerProps = PropsWithChildren<{
  /**
   * If true then buttons in the portrait mode are in one row, otherwise
   * buttons are in one column in the portrait mode
   */
  oneRowInPortraitMode?: boolean
}>

/**
 * Renders buttons differently based on the {@link screenMode} in {@link settingsSelector}.
 * If {@link oneRowInPortraitMode} is false and the {@link screenMode} in {@link settingsSelector}
 * is PORTRAIT then renders buttons in a column and moves the last button at the top.
 * Otherwise, renders buttons in a row.
 */
export const ButtonsContainer: FC<ButtonsContainerProps> = ({ children, oneRowInPortraitMode }) => {
  const { screenMode } = useSelector(settingsSelector)

  return <div
    className={classnames('buttons-container', {
      'buttons-container--column': screenMode === 'PORTRAIT' && !oneRowInPortraitMode,
    })} data-testid='buttons-container'
  >{children}</div>
}
