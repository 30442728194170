import React, { FC } from 'react'

import { Container } from '../../components/Container/Container'
import { MineBuilding } from './Buildings/MineBuilding/MineBuilding'
import { DojoBuilding } from './Buildings/DojoBuilding/DojoBuilding'

import './MainView.scss'
import { RegisteredUserData } from '../../api/dungeon-worlds/user'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../store/slices/settingsSlice'
import classnames from 'classnames'
import { useMainView } from './useMainView'

type MainViewProps = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

/**
 * The main page of the application which renders the map and buildings
 */
export const MainView: FC<MainViewProps> = ({ user }) => {
  const settings = useSelector(settingsSelector)

  useMainView(user)

  return <div className='main-view'>
    <div className='main-view__wrapper'>
      <Container>
        <DojoBuilding user={user}
          className={classnames('main-view__dojo-building', {
            'main-view__dojo-building--portrait': settings.screenMode === 'PORTRAIT'
          })}
        />
        <MineBuilding user={user} />
      </Container>
    </div>
  </div>
}
