import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { CharacterCard } from '../../../components/CharacterCard/CharacterCard'
import { getMockAsset } from '../../../../test/mocks/asset'

const baseConfig = { level: '1', power: '22', }

export const mockMythicCharacterAsset = getMockAsset()
if (mockMythicCharacterAsset.template) {
  mockMythicCharacterAsset.template.template_id = '668146'
}
mockMythicCharacterAsset.data = {
  ...mockMythicCharacterAsset.data,
  ...baseConfig,
  rarity: 'Mythic',
}
export const mockLegendaryCharacterAsset = getMockAsset()
if (mockLegendaryCharacterAsset.template) {
  mockLegendaryCharacterAsset.template.template_id = '668145'
}
mockLegendaryCharacterAsset.data = {
  ...mockLegendaryCharacterAsset.data,
  ...baseConfig,
  rarity: 'Legendary',
}
export const mockEpicCharacterAsset = getMockAsset()
if (mockEpicCharacterAsset.template) {
  mockEpicCharacterAsset.template.template_id = '668144'
}
mockEpicCharacterAsset.data = {
  ...mockEpicCharacterAsset.data,
  ...baseConfig,
  rarity: 'Epic',
}
export const mockRareCharacterAsset = getMockAsset()
if (mockRareCharacterAsset.template) {
  mockRareCharacterAsset.template.template_id = '668143'
}
mockRareCharacterAsset.data = {
  ...mockRareCharacterAsset.data,
  ...baseConfig,
  rarity: 'Rare',
}
export const mockCommonCharacterAsset = getMockAsset()
if (mockCommonCharacterAsset.template) {
  mockCommonCharacterAsset.template.template_id = '668142'
}
mockCommonCharacterAsset.data = {
  ...mockCommonCharacterAsset.data,
  ...baseConfig,
  rarity: 'Common',
}
const onClick = () => alert('Hello from character card :)')

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link CharacterCard} component
 * for developers
 */
export const MythicCharacterCardComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Mythic'>
      <CharacterCard characterAsset={mockMythicCharacterAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Mythic disabled'>
      <CharacterCard characterAsset={mockMythicCharacterAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Mythic selected'>
      <CharacterCard characterAsset={mockMythicCharacterAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Mythic without frame'>
      <CharacterCard
        characterAsset={mockMythicCharacterAsset} onClick={onClick}
        roundedFrameHidden
      />
    </ComponentWrapper>
    <ComponentWrapper title='Mythic without click'>
      <CharacterCard characterAsset={mockMythicCharacterAsset} />
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link CharacterCard} component
 * for developers
 */
export const LegendaryCharacterCardComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Legendary'>
      <CharacterCard characterAsset={mockLegendaryCharacterAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Legendary disabled'>
      <CharacterCard characterAsset={mockLegendaryCharacterAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Legendary selected'>
      <CharacterCard characterAsset={mockLegendaryCharacterAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Legendary without frame'>
      <CharacterCard
        characterAsset={mockLegendaryCharacterAsset} onClick={onClick}
        roundedFrameHidden
      />
    </ComponentWrapper>
    <ComponentWrapper title='Legendary without click'>
      <CharacterCard characterAsset={mockLegendaryCharacterAsset} />
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link CharacterCard} component
 * for developers
 */
export const EpicCharacterCardComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Epic'>
      <CharacterCard characterAsset={mockEpicCharacterAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Epic disabled'>
      <CharacterCard characterAsset={mockEpicCharacterAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Epic selected'>
      <CharacterCard characterAsset={mockEpicCharacterAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Epic without frame'>
      <CharacterCard characterAsset={mockEpicCharacterAsset} onClick={onClick} roundedFrameHidden />
    </ComponentWrapper>
    <ComponentWrapper title='Epic without click'>
      <CharacterCard characterAsset={mockEpicCharacterAsset} />
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link CharacterCard} component
 * for developers
 */
export const RareCharacterCardComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Rare'>
      <CharacterCard characterAsset={mockRareCharacterAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Rare disabled'>
      <CharacterCard characterAsset={mockRareCharacterAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Rare selected'>
      <CharacterCard characterAsset={mockRareCharacterAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Rare without frame'>
      <CharacterCard characterAsset={mockRareCharacterAsset} onClick={onClick} roundedFrameHidden />
    </ComponentWrapper>
    <ComponentWrapper title='Rare without click'>
      <CharacterCard characterAsset={mockRareCharacterAsset} />
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link CharacterCard} component
 * for developers
 */
export const CommonCharacterCardComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Common'>
      <CharacterCard characterAsset={mockCommonCharacterAsset} onClick={onClick} />
    </ComponentWrapper>
    <ComponentWrapper title='Common disabled'>
      <CharacterCard characterAsset={mockCommonCharacterAsset} onClick={onClick} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Common selected'>
      <CharacterCard characterAsset={mockCommonCharacterAsset} onClick={onClick} selected />
    </ComponentWrapper>
    <ComponentWrapper title='Common without frame'>
      <CharacterCard
        characterAsset={mockCommonCharacterAsset} onClick={onClick}
        roundedFrameHidden
      />
    </ComponentWrapper>
    <ComponentWrapper title='Common without click'>
      <CharacterCard characterAsset={mockCommonCharacterAsset} />
    </ComponentWrapper>
  </div>
}
