import { FC, PropsWithChildren, useEffect } from 'react'
import './Toast.scss'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { removeToast, toastSelector } from '../../store/slices/toastSlice'

/**
 * How much time the component is shown until unmounting
 */
const TOAST_LIFETIME = 5_000

export type ToastSize = 'default' | 'large'

export type ToastType = 'info' | 'warning'

type ToastProps = PropsWithChildren<{
  /**
   * Toast type
   * @default info
   */
  type?: ToastType
  /**
   * Toast size
   * @default default
   */
  size?: ToastSize
  /**
   * Data test ID used by jest
   * @default undefined
   */
  dataTestId?: string
  /**
   * Toast ID
   * @default undefined
   */
  id?: number
  /**
   * Class name for the component
   * @default undefined
   */
  className?: string
}>

export const Toast: FC<ToastProps> = ({
  children, type = 'info', size = 'default', dataTestId, id,
  className,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      const unmount = setTimeout(() => {
        // Removes toast after some time
        dispatch(removeToast(id))
      }, TOAST_LIFETIME)

      return () => {
        clearTimeout(unmount)
      }
    }
  }, [])


  return <div data-testid={dataTestId} className={classnames('toast', `toast--${type}`, `toast--${size}`, className)}>
    {children}
  </div>
}

export const ToastGroup: FC = () => {
  const toast = useSelector(toastSelector)

  return <div className='toast-group'>
    {toast.toasts.map(toast => (
      <Toast
        key={toast.id} size={toast.size}
        type={toast.type} id={toast.id}
      >{toast.text}</Toast>
    ))}
  </div>
}
