import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { dojoSelector, getSenseiSlots, getTrainingSlots } from '../../../../store/slices/dojoSlice'
import { useAppDispatch } from '../../../../store/types'
import { RegisteredUserData } from '../../../../api/dungeon-worlds/user'

/**
 * Represents returned type of the {@link useDojoBuilding} hook
 */
export type DojoBuildingHook = ReturnType<typeof useDojoBuilding>

/**
 * Hook contains logic for the dojo building
 * @param user Registered user data
 */
export function useDojoBuilding (user: RegisteredUserData) {
  const dispatch = useAppDispatch()

  const dojo = useSelector(dojoSelector)

  // By actions means buttons that are shown by clicking on the building.
  // By default, buttons are hidden and for showing them the user has to click on the building.
  // By click on an action it opens an appropriate modal.
  const [actionsShown, setActionsShown] = useState(false)

  const toggleActions = () => setActionsShown(prev => !prev)
  const closeActions = () => setActionsShown(false)

  // We support only one sensei slot so far
  const senseiSlot = dojo.senseiSlots[0]

  // We show an animated dojo building image if the user has a character asset staked.
  // Otherwise, a static image shown
  const animatedDojoBuildingShown = dojo.trainingSlots.some(item => item.characterAsset)

  const gettingTrainingSlots = dojo.gettingTrainingSlots === 'pending'
  const gettingSenseiSlots = dojo.gettingSenseiSlots === 'pending'

  // Gets training and sensei slots for the user.
  // We need to know this data when the hook in initialized, so requests are called here
  useEffect(() => {
    dispatch(getTrainingSlots({ waxAccountName: user.waxAccount }))
    dispatch(getSenseiSlots({ waxAccountName: user.waxAccount }))
  }, [])

  return {
    /**
     * If the action buttons shown
     */
    actionsShown,
    /**
     * Toggles the {@link actionsShown} state.
     * If the value is `false` then sets the value to `true` and the way around.
     */
    toggleActions,
    /**
     * Closes the actions by setting the {@link actionsShown} state to `false`.
     */
    closeActions,
    /**
     * If the dojo building should be shown in animated view
     */
    animatedDojoBuildingShown,
    /**
     * If the training slots are currently being gotten
     */
    gettingTrainingSlots,
    /**
     * If the sensei slots are currently being gotten
     */
    gettingSenseiSlots,
    /**
     * Sensei slot. So far, we support only one slot for senseis
     */
    senseiSlot,
  }
}
