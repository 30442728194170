import { FC, Fragment, ReactNode, useState } from 'react'

import {
  ButtonGhostComponent, ButtonSecondaryComponent,
  ButtonPrimaryComponent, ButtonTertiaryComponent,
} from './components/ButtonComponent'
import { MaterialAmountComponent } from './components/MaterialAmountComponent'
import { Button } from '../../components/Button/Button'
import { Container } from '../../components/Container/Container'

import './ComponentsView.scss'
import { ToastComponent } from './components/ToastComponent'
import {
  CommonCharacterCardComponent,
  EpicCharacterCardComponent,
  LegendaryCharacterCardComponent,
  MythicCharacterCardComponent, RareCharacterCardComponent,
} from './components/CharacterCardComponent'
import {
  LandCardCoalComponent,
  LandCardGoldComponent,
  LandCardIronComponent,
  LandCardUraniumComponent,
} from './components/LandCardComponent'
import { LandWithCharacterCardComponent } from './components/LandWithCharacterCardComponent'
import { LocationBannerComponent } from './components/LocationBannerComponent'
import { RarityBadgeComponent } from './components/RarityBadgeComponent'
import { ModalComponent } from './components/ModalComponent'
import { RectangleFrameComponent } from './components/RectangleFrameComponent'
import { RoundedFrameComponent } from './components/RoundedFrameComponent'
import { ProgressBarComponent } from './components/ProgressBarComponent'
import { ButtonsContainerComponent } from './components/ButtonsContainerComponent'
import { NftsAScrollableContainerComponent } from './components/NftsScrollableContainerComponent'
import { SenseiCardComponent } from './components/SenseiCardComponent'
import { EmptySlotComponent } from './components/EmptySlotComponent'

type Tab = {
  /**
   * Title of the tab
   */
  title: string
  /**
   * Content should be shown for the tab
   */
  content: ReactNode
}

const tabs: Tab[] = [
  { title: 'Buttons', content: <>
    <ButtonPrimaryComponent />
    <ButtonSecondaryComponent />
    <ButtonTertiaryComponent />
    <ButtonGhostComponent />
  </>, },
  { title: 'Material Amounts', content: <MaterialAmountComponent />, },
  { title: 'Toast', content: <ToastComponent />, },
  { title: 'Character Card', content: <>
    <MythicCharacterCardComponent />
    <LegendaryCharacterCardComponent />
    <EpicCharacterCardComponent />
    <RareCharacterCardComponent />
    <CommonCharacterCardComponent />
  </> },
  { title: 'Land Card', content: <>
    <LandCardGoldComponent />
    <LandCardUraniumComponent />
    <LandCardIronComponent />
    <LandCardCoalComponent />
  </> },
  { title: 'Land with character card', content: <LandWithCharacterCardComponent /> },
  { title: 'Location banner', content: <LocationBannerComponent /> },
  { title: 'Rarity badge', content: <RarityBadgeComponent /> },
  { title: 'Modal', content: <ModalComponent /> },
  { title: 'Rectangle frame', content: <RectangleFrameComponent /> },
  { title: 'Rounded frame', content: <RoundedFrameComponent /> },
  { title: 'Progress bar', content: <ProgressBarComponent /> },
  { title: 'Buttons container', content: <ButtonsContainerComponent /> },
  { title: 'NFTs scrollable container', content: <NftsAScrollableContainerComponent /> },
  { title: 'Sensei card', content: <SenseiCardComponent /> },
  { title: 'Empty slot', content: <EmptySlotComponent /> },
]

type ComponentWrapperProps = {
  /**
   * Title of the component
   */
  title: string
  /**
   * The component should be rendered
   */
  children: ReactNode
}

/**
 * The component is used to render a children with a title on the Components page.
 * This component shouldn't be used in the production code at all!
 */
export const ComponentWrapper: FC<ComponentWrapperProps> = props => {
  return <div className='components-view__component'>
    <h2 className='components-view__component-title'>{props.title}</h2>
    <div>{props.children}</div>
  </div>
}

/**
 * The page lists all components the project has with all states.
 * The page is visible only on the staging environment and also can be visible locally
 * by settings the `COMPONENTS_PAGE_ACTIVE` env variable to `true`.
 */
export const ComponentsView: FC = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].title)

  return (
    <div className='components-view'>
      <Container>
        <h1 className='components-view__title'>UI kit</h1>
        <div className='components-view__container'>
          <div className='components-view__tab-buttons'>
            <div className='components-view__subtitle'>Categories</div>
            {tabs.map(item => (
              <Button
                key={item.title} disabled={activeTab === item.title}
                onClick={() => setActiveTab(item.title)} size='small'
                dataTestId={item.title} className='components-view__tab-button'
              >
                {item.title}
              </Button>
            ))}
          </div>
          <div className='components-view__tab-content'>
            <div className='components-view__subtitle'>Components</div>
            {tabs.map(item => <Fragment key={item.title}>
              {item.title === activeTab && item.content}
            </Fragment>)}
          </div>
        </div>
      </Container>
    </div>
  )
}
