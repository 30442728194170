import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { App } from './App'
import { setupStore } from './store'
import { ToastGroup } from './components/Toast/Toast'

const container = document.getElementById('app')

if (!container) {
  throw new Error('Could not find root element')
}

const root = createRoot(container)
root.render(
  <Provider store={setupStore()}>
    <ToastGroup />
    <App />
  </Provider>
)
