import { useState } from 'react'
import { AtomicAsset } from '../../../../../api/atomic/types/asset'
import { useSelector } from 'react-redux'
import { closeMine, miningSelector } from '../../../../../store/slices/miningSlice'
import { useAppDispatch } from '../../../../../store/types'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'

/**
 * Manages business logic for closing a mine modal
 * @param user Registered user data
 */
export function useCloseMineModal(user: RegisteredUserData) {
  const dispatch = useAppDispatch()
  const mining = useSelector(miningSelector)

  const [landNftToCloseMineWith, setLandNftToCloseMineWith] = useState<AtomicAsset>()

  const minesWithoutCharacters = mining.mines.filter(item => !item.character)

  /**
   * Closes the selected mine if the mine is selected in {@link landNftToCloseMineWith}
   * @param afterClosingMine
   */
  const close = async (afterClosingMine: () => void) => {
    if (landNftToCloseMineWith) {
      await dispatch(closeMine({ land: landNftToCloseMineWith, user: user }))
      setLandNftToCloseMineWith(undefined)
      afterClosingMine()
    }
  }

  /**
   * Checks if the given land NFT to close the mine with is selected
   * @param landNft Land NFT should be checked if it's selected
   */
  const landSelected = (landNft: AtomicAsset): boolean => {
    return landNftToCloseMineWith?.asset_id === landNft.asset_id
  }

  /**
   * Selects the given land NFT to close the mine with
   * by adding the NFT into the {@link landNftToCloseMineWith} state.
   * If the NFT is already selected then clears the state by setting an `undefined` value in there
   * @param landNft Land NFT should be selected or unselected
   */
  const selectLandNftToCloseMineWith = (landNft: AtomicAsset) => {
    setLandNftToCloseMineWith(() => {
      return landSelected(landNft) ? undefined : landNft
    })
  }

  /**
   * Checks if the given land NFT should be disabled
   * @param landNft Land NFT should be checked if it's disabled
   */
  const landDisabled = (landNft: AtomicAsset): boolean => mining.closingMine[landNft.asset_id] === 'pending'

  const closeMineButtonDisabled = !landNftToCloseMineWith || mining.closingMine[landNftToCloseMineWith.asset_id] === 'pending'

  return {
    /**
     * Land NFT of a mine that should be closed. Could be an `undefined` value if no NFT selected
     */
    landNftToCloseMineWith,
    /**
     * Selects a land NFT of a mine that should be closed
     * by setting the NFT to the {@link landNftToCloseMineWith} state
     */
    setLandNftToCloseMineWith,
    /**
     * All mines without character NFTs inside. Character NFTs are filtered out.
     */
    minesWithoutCharacters,
    /**
     * Indicates in the close mine confirmation button should be disabled
     */
    closeMineButtonDisabled,
    close,
    landSelected,
    selectLandNftToCloseMineWith,
    landDisabled,
  }
}
