import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit'
import { RootState } from './types'
import { nftsReducer } from './slices/nftsSlice'
import { miningReducer } from './slices/miningSlice'
import { userReducer } from './slices/userSlice'
import { initialLoadingReducer } from './slices/initialLoadingSlice'
import { toastReducer } from './slices/toastSlice'
import { settingsReducer } from './slices/settingsSlice'
import { dojoReducer } from './slices/dojoSlice'

// Create the root reducer separately so we can extract the RootState type
export const rootReducer = combineReducers({
  nfts: nftsReducer,
  mining: miningReducer,
  user: userReducer,
  initialLoading: initialLoadingReducer,
  toast: toastReducer,
  settings: settingsReducer,
  dojo: dojoReducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer: rootReducer,
  preloadedState,
})
