import React, { FC, useEffect } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { routes } from './constants/routes'

import { MainView } from './views/MainView/MainView'
import { ComponentsView } from './views/ComponentsView/ComponentsView'
import { LoginView } from './views/LoginView/LoginView'
import { userSelector } from './store/slices/userSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from './store/types'
import { initialLoadingSelector, loadRequiredData } from './store/slices/initialLoadingSlice'
import { LoadingView } from './views/LoadingView/LoadingView'
import { envVars } from './services/envVariables'
import { RegisteredUserData } from './api/dungeon-worlds/user'

const componentsPageActive = envVars.COMPONENTS_PAGE_ACTIVE === 'true'

// Routes are used for not authenticated users
const publicRouter = createBrowserRouter([
  {
    path: routes.login,
    element: <LoginView />,
  },
  {
    path: routes.components,
    element: componentsPageActive
      ? <ComponentsView />
      : <Navigate to={routes.login} />,
  },
  {
    path: routes.notExistingRoute,
    element: <Navigate to={routes.login} />
  },
])

/**
 * Routes are used for authenticated users
 * @param user Registered user data
 */
const privateRouter = (user: RegisteredUserData) => createBrowserRouter([
  {
    path: routes.main,
    element: <MainView user={user} />,
  },
  {
    path: routes.components,
    element: componentsPageActive
      ? <ComponentsView />
      : <Navigate to={routes.main} />,
  },
  {
    path: routes.notExistingRoute,
    element: <Navigate to={routes.main} />
  },
])

export const AppRoutes: FC = () => {
  const dispatch = useAppDispatch()

  const initialLoading = useSelector(initialLoadingSelector)
  const user = useSelector(userSelector)

  useEffect(() => {
    dispatch(loadRequiredData())
  }, [])

  if (initialLoading.gettingRequiredData === 'pending') {
    return <LoadingView />
  }

  if (user.user) {
    return <RouterProvider router={privateRouter(user.user)} />
  }

  return <RouterProvider router={publicRouter} />
}
