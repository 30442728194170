import { FC, PropsWithChildren } from 'react'
import classnames from 'classnames'

import { AtomicAsset } from '../../api/atomic/types/asset'
import { composeAtomicAssetImgUrl } from '../../services/atomicAssets'

import { RoundedFrame } from '../RoundedFrame/RoundedFrame'
import { RectangleFrame } from '../RectangleFrame/RectangleFrame'

import GoldIcon from '../../assets/images/icons/materials/gold.png'
import UraniumIcon from '../../assets/images/icons/materials/uranium.png'
import IronIcon from '../../assets/images/icons/materials/iron.png'
import CoalIcon from '../../assets/images/icons/materials/coal.png'
import BuildingWithGold from '../../assets/images/mine-building-with-gold.png'
import BuildingWithUranium from '../../assets/images/mine-building-with-uranium.png'
import BuildingWithIron from '../../assets/images/mine-building-with-iron.png'
import BuildingWithCoal from '../../assets/images/mine-building-with-coal.png'
import SelectedIcon from '../../assets/images/icons/selected.svg'

import './LandCard.scss'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../store/slices/settingsSlice'

/**
 * Mapping for getting material image data based on the material
 */
export const materialImageDataMapping: Record<
  string, { imgSrc: string, altText: string } | undefined
> = {
  'Gold': { imgSrc: GoldIcon, altText: 'Icon for gold material' },
  'Uranium': { imgSrc: UraniumIcon, altText: 'Icon for uranium material' },
  'Iron': { imgSrc: IronIcon, altText: 'Icon for iron material' },
  'Coal': { imgSrc: CoalIcon, altText: 'Icon for coal material' },
}

/**
 * Mapping for getting building image data based on the material
 */
export const buildingImageDataMapping: Record<
  string, { imgSrc: string, altText: string } | undefined
> = {
  'Gold': { imgSrc: BuildingWithGold, altText: 'Mine building with gold' },
  'Uranium': { imgSrc: BuildingWithUranium, altText: 'Mine building with uranium' },
  'Iron': { imgSrc: BuildingWithIron, altText: 'Mine building with iron' },
  'Coal': { imgSrc: BuildingWithCoal, altText: 'Mine building with coal' },
}

type ConditionalRoundedFrameLandCardProps = PropsWithChildren<Pick<LandCardProps, 'roundedFrameHidden'>>

// Decides if the land card should render rounded frame component based
// on the roundedFrameHidden prop availability
export const ConditionalRoundedFrameLandCard: FC<ConditionalRoundedFrameLandCardProps> = ({
  children, roundedFrameHidden,
}) => {
  if (roundedFrameHidden) {
    return <>{children}</>
  }

  return <RoundedFrame className='land-card__rounded-frame' dataTestId='rounded-frame-land-card'>{children}</RoundedFrame>
}

type ConditionalButtonLandCardProps = PropsWithChildren<Omit<LandCardProps, 'landAsset' | 'roundedFrameHidden' | 'buildingImageShown'>>

// Decides if the button tag should render based on the onClick prop availability
export const ConditionalButtonLandCard: FC<ConditionalButtonLandCardProps> = ({
  children, onClick, disabled, selected,
  className = '', dataTestId = ''
}) => {
  const settings = useSelector(settingsSelector)

  const classNames = classnames('land-card', className, {
    'land-card--selected': selected,
    'land-card--portrait': settings.screenMode === 'PORTRAIT',
    'land-card--landscape': settings.screenMode === 'LANDSCAPE',
  })

  if (!onClick) {
    return (
      <div className={classNames} data-testid={dataTestId}>{children}</div>
    )
  }

  // Needed because a user can change 'disable' attribute by manually in devtools
  // and call the function from the `onClick` prop.
  // For greater security, we can programmatically check this.
  const onInternalClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return (
    <button
      disabled={disabled} onClick={onInternalClick} data-testid={dataTestId}
      className={classNames}
    >{children}</button>
  )
}

type LandCardProps = {
  /**
   * If the card is disabled and not clickable
   * @default undefined
   */
  disabled?: boolean
  /**
   * If the card is selected for an action
   * @default undefined
   */
  selected?: boolean
  /**
   * Called by click on the component.
   * If passed the component renders <button> tag, otherwise <div>
   * @default undefined
   */
  onClick?: () => void
  /**
   * Asset the card is used for
   */
  landAsset: AtomicAsset
  /**
   * If rounded frame in the component hidden
   * @default undefined
   */
  roundedFrameHidden?: boolean
  /**
   * Class name for the component
   * @default undefined
   */
  className?: string
  /**
   * Data test ID used by Jest
   * @default undefined
   */
  dataTestId?: string
  /**
   * If passed the card shows a building image based on the land material.
   * Otherwise, the image from the asset is shown.
   * @default undefined
   */
  buildingImageShown?: boolean
  /**
   * Coordinate and material info are shorter if passed.
   * How coordinate UI looks if the prop is passed: "(10,6)".
   * How coordinate UI looks if the prop is not passed: "Coordinate: 10,6".
   * How material UI looks if the prop is passed: "<img> Gold".
   * How material UI looks if the prop is not passed: "Material: <img> Gold".
   * @default undefined
   */
  landInfoShort?: boolean
}

export const LandCard: FC<LandCardProps> = props => {
  const coordinates = `${props.landAsset.data.x},${props.landAsset.data.y}`
  const material = props.landAsset.data.material
  const materialImageData = materialImageDataMapping[material]
  const buildingImageData = buildingImageDataMapping[material]

  return <ConditionalButtonLandCard
    onClick={props.onClick} className={props.className}
    disabled={props.disabled} selected={props.selected} dataTestId={props.dataTestId}
  >
    <ConditionalRoundedFrameLandCard roundedFrameHidden={props.roundedFrameHidden}>
      <RectangleFrame className='land-card__rectangle-frame'>
        {props.selected && <>
          <img src={SelectedIcon} className='land-card__selected-icon' alt='Check icon in a golden background' />
        </>}
        <div className='land-card__media'>
          {!props.buildingImageShown
            ? <img src={composeAtomicAssetImgUrl(props.landAsset.data.img)} alt='Land NFT' />
            : buildingImageData && (
              <img src={buildingImageData.imgSrc} alt={buildingImageData.altText} />
            )}
        </div>
        <div className='land-card__info'>
          <div className='land-card__info-block'>
            {!props.landInfoShort && <span className='land-card__info-label'>Coordinate:</span>}
            <span className='land-card__info-value'>
              {props.landInfoShort ? `(${coordinates})` : coordinates}
            </span>
          </div>
          <div className='land-card__info-block'>
            {!props.landInfoShort && <span className='land-card__info-label'>Material:</span>}
            <span className='land-card__info-value'>
              {materialImageData && (
                <img
                  src={materialImageData.imgSrc} className='land-card__info-value-icon'
                  data-testid='material-image' alt={materialImageData.altText}
                />
              )}
              {material}
            </span>
          </div>
        </div>
      </RectangleFrame>
    </ConditionalRoundedFrameLandCard>
  </ConditionalButtonLandCard>
}
