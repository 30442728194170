import React, { FC } from 'react'
import classnames from 'classnames'

import GoldIcon from '../../assets/images/icons/materials/gold.png'
import UraniumIcon from '../../assets/images/icons/materials/uranium.png'
import IronIcon from '../../assets/images/icons/materials/iron.png'
import CoalIcon from '../../assets/images/icons/materials/coal.png'

import './MaterialAmount.scss'

/**
 * Returns an icon and alt text for the icon based on the material type
 * @param materialType Material type the icon should be returned for
 */
export const getIcon = (materialType: MaterialType): {
  icon: string, altText: string,
} => {
  if (materialType === 'GOLD') {
    return {
      icon: GoldIcon,
      altText: 'Three pieces of gold',
    }
  }
  if (materialType === 'URANIUM') {
    return {
      icon: UraniumIcon,
      altText: 'Three pieces of green uranium',
    }
  }
  if (materialType === 'IRON') {
    return {
      icon: IronIcon,
      altText: 'Four pieces of iron',
    }
  }
  return {
    icon: CoalIcon,
    altText: 'One piece of coal',
  }
}

export type MaterialType = 'GOLD' | 'URANIUM' | 'IRON' | 'COAL'
export type MaterialAmountSize = 'large' | 'default' | 'small'

export type MaterialAmountProps = {
  /**
   * Material type
   */
  type: MaterialType
  /**
   * Material amount
   */
  amount: number
  /**
   * Size of the component
   */
  size?: MaterialAmountSize
}

export const MaterialAmount: FC<MaterialAmountProps> = ({
  type, amount, size = 'default',
}) => {
  const imgData = getIcon(type)
  return (
    <div className={classnames('material-amount', `material-amount--size-${size}`)} data-testid='material-amount'>
      <div className='material-amount__frame'>
        <img src={imgData.icon} className='material-amount__media' alt={imgData.altText} />
        <span>{amount}</span>
      </div>
    </div>
  )
}
