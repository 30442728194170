import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AsyncThunkLoading, RootState } from '../types'
import { getUser } from './userSlice'

type InitialLoadingState = {
  /**
   * Status of getting all required data for the app.
   * Responsible for showing the loading screen when the user opens the app.
   */
  gettingRequiredData: AsyncThunkLoading
  /**
   * Progress of loading all required data for the app in percentage
   */
  progress: number
}

/**
 * Initial state of the {@link initialLoadingSlice}
 */
export const initialLoadingInitialState: InitialLoadingState = {
  gettingRequiredData: 'idle',
  progress: 0,
}

/**
 * Loads all required data for the app at the first load
 */
export const loadRequiredData = createAsyncThunk<
  unknown
>('loading/loadRequiredData', async (
  _,
  { dispatch },
) => {
  dispatch(updateProgress(30))
  const userData = await dispatch(getUser())
  // TODO: try to find a better way to detect errors from thunks in another thunk.
  // Otherwise, the thunk does not throw any error even if
  // the request inside the thunk throws an error
  if ('error' in userData) {
    throw new Error('Getting user during app initialization')
  }
  dispatch(updateProgress(100))
})

export const initialLoadingSlice = createSlice({
  name: 'loading',
  initialState: initialLoadingInitialState,
  reducers: {
    /**
     * Updates the `progress` field
     * @param state State of the {@link initialLoadingSlice}
     * @param action New value for the `progress` field
     */
    updateProgress: (
      state, action: PayloadAction<number>
    ) => {
      state.progress = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadRequiredData.pending, state => {
        state.gettingRequiredData = 'pending'
      })
      .addCase(loadRequiredData.fulfilled, state => {
        state.gettingRequiredData = 'succeeded'
      })
      .addCase(loadRequiredData.rejected, state => {
        state.gettingRequiredData = 'failed'
      })
  }
})

export const { updateProgress } = initialLoadingSlice.actions

export const initialLoadingSelector = (state: RootState) => state.initialLoading

export const initialLoadingReducer = initialLoadingSlice.reducer
