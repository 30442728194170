import BerserkCommonImage from '../../assets/images/characters/character-berserk-common.png'
import BerserkRareImage from '../../assets/images/characters/character-berserk-rare.png'
import BerserkEpicImage from '../../assets/images/characters/character-berserk-epic.png'
import BerserkLegendaryImage from '../../assets/images/characters/character-berserk-legendary.png'
import BerserkMythicImage from '../../assets/images/characters/character-berserk-mythic.png'
import ChampionCommonImage from '../../assets/images/characters/character-champion-common.png'
import ChampionRareImage from '../../assets/images/characters/character-champion-rare.png'
import ChampionEpicImage from '../../assets/images/characters/character-champion-epic.png'
import ChampionLegendaryImage from '../../assets/images/characters/character-champion-legendary.png'
import ChampionMythicImage from '../../assets/images/characters/character-champion-mythic.png'
import GutsCommonImage from '../../assets/images/characters/character-guts-common.png'
import GutsRareImage from '../../assets/images/characters/character-guts-rare.png'
import GutsEpicImage from '../../assets/images/characters/character-guts-epic.png'
import GutsLegendaryImage from '../../assets/images/characters/character-guts-legendary.png'
import GutsMythicImage from '../../assets/images/characters/character-guts-mythic.png'
import JuggernautCommonImage from '../../assets/images/characters/character-juggernaut-common.png'
import JuggernautRareImage from '../../assets/images/characters/character-juggernaut-rare.png'
import JuggernautEpicImage from '../../assets/images/characters/character-juggernaut-epic.png'
import JuggernautLegendaryImage from '../../assets/images/characters/character-juggernaut-legendary.png'
import JuggernautMythicImage from '../../assets/images/characters/character-juggernaut-mythic.png'
import MasterMindCommonImage from '../../assets/images/characters/character-mastermind-common.png'
import MasterMindRareImage from '../../assets/images/characters/character-mastermind-rare.png'
import MasterMindEpicImage from '../../assets/images/characters/character-mastermind-epic.png'
import MasterMindLegendaryImage from '../../assets/images/characters/character-mastermind-legendary.png'
import MasterMindMythicImage from '../../assets/images/characters/character-mastermind-mythic.png'
import QuickHandCommonImage from '../../assets/images/characters/character-quickhand-common.png'
import QuickHandRareImage from '../../assets/images/characters/character-quickhand-rare.png'
import QuickHandEpicImage from '../../assets/images/characters/character-quickhand-epic.png'
import QuickHandLegendaryImage from '../../assets/images/characters/character-quickhand-legendary.png'
import QuickHandMythicImage from '../../assets/images/characters/character-quickhand-mythic.png'
import SamuraiCommonImage from '../../assets/images/characters/character-samurai-common.png'
import SamuraiRareImage from '../../assets/images/characters/character-samurai-rare.png'
import SamuraiEpicImage from '../../assets/images/characters/character-samurai-epic.png'
import SamuraiLegendaryImage from '../../assets/images/characters/character-samurai-legendary.png'
import SamuraiMythicImage from '../../assets/images/characters/character-samurai-mythic.png'
import SnapCommonImage from '../../assets/images/characters/character-snap-common.png'
import SnapRareImage from '../../assets/images/characters/character-snap-rare.png'
import SnapEpicImage from '../../assets/images/characters/character-snap-epic.png'
import SnapLegendaryImage from '../../assets/images/characters/character-snap-legendary.png'
import SnapMythicImage from '../../assets/images/characters/character-snap-mythic.png'
import TacticianCommonImage from '../../assets/images/characters/character-tactician-common.png'
import TacticianRareImage from '../../assets/images/characters/character-tactician-rare.png'
import TacticianEpicImage from '../../assets/images/characters/character-tactician-epic.png'
import TacticianLegendaryImage from '../../assets/images/characters/character-tactician-legendary.png'
import TacticianMythicImage from '../../assets/images/characters/character-tactician-mythic.png'
import TricksterCommonImage from '../../assets/images/characters/character-trickster-common.png'
import TricksterRareImage from '../../assets/images/characters/character-trickster-rare.png'
import TricksterEpicImage from '../../assets/images/characters/character-trickster-epic.png'
import TricksterLegendaryImage from '../../assets/images/characters/character-trickster-legendary.png'
import TricksterMythicImage from '../../assets/images/characters/character-trickster-mythic.png'

/**
 * Character asset template id to the image mapping.
 * Unfortunately, we need to use different images than we have in the templates.
 * The first string in the record is the template ID and the second is the path to the image.
 */
export const mappingCardImage: Record<string, string> = {
  // dungeontests collection
  668107: MasterMindCommonImage,
  668108: MasterMindRareImage,
  668109: MasterMindEpicImage,
  668110: MasterMindLegendaryImage,
  668111: MasterMindMythicImage,
  668112: TricksterCommonImage,
  668113: TricksterRareImage,
  668114: TricksterEpicImage,
  668115: TricksterLegendaryImage,
  668116: TricksterMythicImage,
  668117: ChampionCommonImage,
  668118: ChampionRareImage,
  668119: ChampionEpicImage,
  668120: ChampionLegendaryImage,
  668121: ChampionMythicImage,
  668122: TacticianCommonImage,
  668123: TacticianRareImage,
  668124: TacticianEpicImage,
  668125: TacticianLegendaryImage,
  668126: TacticianMythicImage,
  668127: SnapCommonImage,
  668128: SnapRareImage,
  668129: SnapEpicImage,
  668130: SnapLegendaryImage,
  668131: SnapMythicImage,
  668132: QuickHandCommonImage,
  668133: QuickHandRareImage,
  668134: QuickHandEpicImage,
  668135: QuickHandLegendaryImage,
  668136: QuickHandMythicImage,
  668137: SamuraiCommonImage,
  668138: SamuraiRareImage,
  668139: SamuraiEpicImage,
  668140: SamuraiLegendaryImage,
  668141: SamuraiMythicImage,
  668142: BerserkCommonImage,
  668143: BerserkRareImage,
  668144: BerserkEpicImage,
  668145: BerserkLegendaryImage,
  668146: BerserkMythicImage,
  668147: JuggernautCommonImage,
  668148: JuggernautRareImage,
  668149: JuggernautEpicImage,
  668150: JuggernautLegendaryImage,
  668151: JuggernautMythicImage,
  668152: GutsCommonImage,
  668153: GutsRareImage,
  668154: GutsEpicImage,
  668155: GutsLegendaryImage,
  668156: GutsMythicImage,
  // dungeonitems collection
  664956: MasterMindCommonImage,
  664957: MasterMindRareImage,
  664958: MasterMindEpicImage,
  664959: MasterMindLegendaryImage,
  664960: MasterMindMythicImage,
  664961: TricksterCommonImage,
  664962: TricksterRareImage,
  664963: TricksterEpicImage,
  664964: TricksterLegendaryImage,
  664965: TricksterMythicImage,
  664966: ChampionCommonImage,
  664967: ChampionRareImage,
  664968: ChampionEpicImage,
  664969: ChampionLegendaryImage,
  664970: ChampionMythicImage,
  664971: TacticianCommonImage,
  664972: TacticianRareImage,
  664973: TacticianEpicImage,
  664974: TacticianLegendaryImage,
  664975: TacticianMythicImage,
  664976: SnapCommonImage,
  664977: SnapRareImage,
  664978: SnapEpicImage,
  664979: SnapLegendaryImage,
  664980: SnapMythicImage,
  664981: QuickHandCommonImage,
  664982: QuickHandRareImage,
  664983: QuickHandEpicImage,
  664984: QuickHandLegendaryImage,
  664985: QuickHandMythicImage,
  664986: SamuraiCommonImage,
  664987: SamuraiRareImage,
  664988: SamuraiEpicImage,
  664989: SamuraiLegendaryImage,
  664990: SamuraiMythicImage,
  664991: BerserkCommonImage,
  664992: BerserkRareImage,
  664993: BerserkEpicImage,
  664994: BerserkLegendaryImage,
  664995: BerserkMythicImage,
  664996: JuggernautCommonImage,
  664997: JuggernautRareImage,
  664998: JuggernautEpicImage,
  664999: JuggernautLegendaryImage,
  665000: JuggernautMythicImage,
  665001: GutsCommonImage,
  665002: GutsRareImage,
  665003: GutsEpicImage,
  665004: GutsLegendaryImage,
  665005: GutsMythicImage,
}
