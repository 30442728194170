import { FC } from 'react'
import './LevelUpSuccessDialog.scss'
import { Dialog } from '../../../../../../components/Dialog/Dialog'
import { AtomicAsset } from '../../../../../../api/atomic/types/asset'
import { RectangleFrame } from '../../../../../../components/RectangleFrame/RectangleFrame'
import { CharacterCard } from '../../../../../../components/CharacterCard/CharacterCard'
import EnduranceIcon from '../../../../../../assets/images/icons/endurance.svg'
import IntelligenceIcon from '../../../../../../assets/images/icons/intelligence.svg'
import PowerIcon from '../../../../../../assets/images/icons/power.svg'
import SpeedIcon from '../../../../../../assets/images/icons/speed.svg'
import { Button } from '../../../../../../components/Button/Button'
import LevelUpAnimation from '../../../../../../assets/images/level-up-animation.png'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../../../../../store/slices/settingsSlice'

/**
 * Attributes that are granted after level up
 */
export type CharacterAssetAttributes = {
  /**
   * Intelligence attribute for the character asset
   */
  intelligence: number
  /**
   * Speed attribute for the character asset
   */
  speed: number
  /**
   * Power attribute for the character asset
   */
  power: number
  /**
   * Endurance attribute for the character asset
   */
  endurance: number
}

/**
 * Returns additional granted points based on the genetic.
 * `Smart` genetic grants 1 additional point to the `intelligence` attribute.
 * `Swift` genetic grants 1 additional point to the `speed` attribute.
 * `Strong` genetic grants 1 additional point to the `power` attribute.
 * `Tenacious` genetic grants 1 additional point to the `endurance` attribute.
 * @param genetic Based on the genetic the additional granted points returned
 */
export const getAdditionalGrantedPoints = (genetic: string): CharacterAssetAttributes => {
  let intelligence = 0
  let speed = 0
  let power = 0
  let endurance = 0

  if (genetic === 'Smart') {
    intelligence += 1
  } else if (genetic === 'Swift') {
    speed += 1
  } else if (genetic === 'Strong') {
    power += 1
  } else if (genetic === 'Tenacious') {
    endurance += 1
  }

  return { intelligence, speed, power, endurance }
}

/**
 * Gets granted points for the particular character.
 * By default, all attributes get 2 points, but
 * there are extra points based on the genetics of the character.
 * @param character Character the granted points should be gotten for
 */
export const getGrantedPoints = (character: AtomicAsset): CharacterAssetAttributes => {
  let intelligence = 2
  let speed = 2
  let power = 2
  let endurance = 2

  const additionalGrantedPointsForGenetic1 = getAdditionalGrantedPoints(character.data.genetic1)

  intelligence += additionalGrantedPointsForGenetic1.intelligence
  speed += additionalGrantedPointsForGenetic1.speed
  power += additionalGrantedPointsForGenetic1.power
  endurance += additionalGrantedPointsForGenetic1.endurance

  const additionalGrantedPointsForGenetic2 = getAdditionalGrantedPoints(character.data.genetic2)

  intelligence += additionalGrantedPointsForGenetic2.intelligence
  speed += additionalGrantedPointsForGenetic2.speed
  power += additionalGrantedPointsForGenetic2.power
  endurance += additionalGrantedPointsForGenetic2.endurance

  return { intelligence, speed, power, endurance }
}

type LevelUpSuccessDialogProps = {
  /**
   * Closes the dialog
   */
  closeDialog: () => void
  /**
   * Character asset that has been leveled up successfully
   */
  characterAsset: AtomicAsset
}

/**
 * Component renders the updated attributes of a character asset after leveling up
 * based on the current level.
 * Basically, shows the current attribute values + gained attribute values.
 */
export const LevelUpSuccessDialog: FC<LevelUpSuccessDialogProps> = props => {
  const settings = useSelector(settingsSelector)
  const grantedPoints = getGrantedPoints(props.characterAsset)

  return <Dialog onBackdropClick={props.closeDialog} className={classnames('level-up-character-success-dialog', {
    'level-up-character-success-dialog--portrait': settings.screenMode === 'PORTRAIT',
    'level-up-character-success-dialog--landscape-wide': settings.screenMode === 'LANDSCAPE_WIDE',
  })}>
    <RectangleFrame className='level-up-character-success-dialog__frame' animated>
      <h4 className='level-up-character-success-dialog__title'>Level up!</h4>
      <div className='level-up-character-success-dialog__wrapper'>
        <div className='level-up-character-success-dialog__asset'>
          <CharacterCard characterAsset={props.characterAsset} powerDataHidden />
          <img
            src={LevelUpAnimation} alt='Purple arrow goes from the bottom to the top'
            className='level-up-character-success-dialog__asset-animated-media'
          />
        </div>
        <div className='level-up-character-success-dialog__all-boosts'>
          <div className='level-up-character-success-dialog__boost'>
            <div className='level-up-character-success-dialog__boost-label'>
              <img src={EnduranceIcon} alt='Endurance icon' />
              Endurance
            </div>
            <div className='level-up-character-success-dialog__boost-value'>
              {props.characterAsset.data.endurance}
              <span
                className='level-up-character-success-dialog__boost-value-added'
                data-testid='level-up-character-success-dialog-granted-endurance-points'
              >
                +{grantedPoints.endurance}
              </span>
            </div>
          </div>
          <div className='level-up-character-success-dialog__boost'>
            <div className='level-up-character-success-dialog__boost-label'>
              <img src={IntelligenceIcon} alt='Intelligence icon' />
              Intelligence
            </div>
            <div className='level-up-character-success-dialog__boost-value'>
              {props.characterAsset.data.intelligence}
              <span
                className='level-up-character-success-dialog__boost-value-added'
                data-testid='level-up-character-success-dialog-granted-intelligence-points'
              >
                +{grantedPoints.intelligence}
              </span>
            </div>
          </div>
          <div className='level-up-character-success-dialog__boost'>
            <div className='level-up-character-success-dialog__boost-label'>
              <img src={PowerIcon} alt='Power icon' />
              Power
            </div>
            <div className='level-up-character-success-dialog__boost-value'>
              {props.characterAsset.data.power}
              <span
                className='level-up-character-success-dialog__boost-value-added'
                data-testid='level-up-character-success-dialog-granted-power-points'
              >
                +{grantedPoints.power}
              </span>
            </div>
          </div>
          <div className='level-up-character-success-dialog__boost'>
            <div className='level-up-character-success-dialog__boost-label'>
              <img src={SpeedIcon} alt='Speed icon' />
              Speed
            </div>
            <div className='level-up-character-success-dialog__boost-value'>
              {props.characterAsset.data.speed}
              <span
                className='level-up-character-success-dialog__boost-value-added'
                data-testid='level-up-character-success-dialog-granted-speed-points'
              >
                +{grantedPoints.speed}
              </span>
            </div>
          </div>
        </div>
      </div>
    </RectangleFrame>
    <Button
      onClick={props.closeDialog} className='level-up-character-success-dialog__button'
      dataTestId='level-up-character-success-dialog-continue-button'
    >Continue</Button>
  </Dialog>
}
