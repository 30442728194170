import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { RectangleFrame } from '../../../components/RectangleFrame/RectangleFrame'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link RectangleFrame} component
 * for developers
 */
export const RectangleFrameComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Rectangle frame purple'>
      <RectangleFrame frameColor='purple'>
        This component renders children elements with a rectangle frame
      </RectangleFrame>
    </ComponentWrapper>
    <ComponentWrapper title='Rectangle frame brown'>
      <RectangleFrame frameColor='brown'>
        This component renders children elements with a rectangle frame
      </RectangleFrame>
    </ComponentWrapper>
    <ComponentWrapper title='Rectangle dashed frame purple'>
      <RectangleFrame frameColor='purple' dashedBorder>
        This component renders children elements with a rectangle frame
      </RectangleFrame>
    </ComponentWrapper>
    <ComponentWrapper title='Rectangle dashed frame brown'>
      <RectangleFrame frameColor='brown' dashedBorder>
        This component renders children elements with a rectangle frame
      </RectangleFrame>
    </ComponentWrapper>
  </div>
}
