import { useEffect } from 'react'
import { useAppDispatch } from '../../store/types'
import { RegisteredUserData } from '../../api/dungeon-worlds/user'
import { getCharacterNfts, getLandNfts, getSenseiNfts } from '../../store/slices/nftsSlice'
import { getMines } from '../../store/slices/miningSlice'

/**
 * Hook for managing functionality for the main view
 * @param user Registered user data
 */
export function useMainView (user: RegisteredUserData) {
  const dispatch = useAppDispatch()

  // Calls all requests that necessary to get data from after MainView is rendered
  useEffect(() => {
    dispatch(getLandNfts({ waxAccountName: user.waxAccount }))
    dispatch(getCharacterNfts({ waxAccountName: user.waxAccount }))
    dispatch(getSenseiNfts({ waxAccountName: user.waxAccount }))
    dispatch(getMines({ waxAccountName: user.waxAccount }))
  }, [])
}
