import { FC, useState } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { Button } from '../../../components/Button/Button'
import { Modal } from '../../../components/Modal/Modal'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link Modal} component for developers
 */
export const ModalComponent: FC = () => {
  const [modalWithoutBackButtonShown, setModalWithoutBackButtonShown] = useState(false)
  const [modalWithBackButtonShown, setModalWithBackButtonShown] = useState(false)

  return <div>
    <ComponentWrapper title='Modal without back button'>
      <Button onClick={() => setModalWithoutBackButtonShown(true)}>
        Click to see modal without back button!
      </Button>
      {modalWithoutBackButtonShown && (
        <Modal title='Modal title!' onClickBackdropAndCloseButtons={() => setModalWithoutBackButtonShown(false)}>Modal content</Modal>
      )}
    </ComponentWrapper>
    <ComponentWrapper title='Modal with back button'>
      <Button onClick={() => setModalWithBackButtonShown(true)}>
        Click to see modal with back button!
      </Button>
      {modalWithBackButtonShown && (
        <Modal
          title='Modal title!' onBackButtonClick={() => alert('Back button click!')}
          onClickBackdropAndCloseButtons={() => setModalWithBackButtonShown(false)}
        >Modal content</Modal>
      )}
    </ComponentWrapper>
  </div>
}
