import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { EmptySlot } from '../../../components/EmptySlot/EmptySlot'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link EmptySlot} component for developers
 */
export const EmptySlotComponent: FC = () => {
  return <div>
    <ComponentWrapper title='With children'>
      <EmptySlot>Empty slot component</EmptySlot>
    </ComponentWrapper>
    <ComponentWrapper title='Lock icon shown'>
      <EmptySlot lockIconShown>Empty slot component</EmptySlot>
    </ComponentWrapper>
    <ComponentWrapper title='With warning text'>
      <EmptySlot warningLabel='Some warning text'>Empty slot component</EmptySlot>
    </ComponentWrapper>
  </div>
}
