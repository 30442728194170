import { Modal } from '../../../../../components/Modal/Modal'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { AtomicAsset } from '../../../../../api/atomic/types/asset'
import { LandCard } from '../../../../../components/LandCard/LandCard'
import { nftsSelector } from '../../../../../store/slices/nftsSlice'
import { CharacterCard } from '../../../../../components/CharacterCard/CharacterCard'
import { Button } from '../../../../../components/Button/Button'
import { MineBuildingModalSubtitle } from '../MineBuilding'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'
import { NftsScrollableContainer } from '../../../../../components/NftsScrollableContainer/NftsScrollableContainer'
import { ButtonsContainer } from '../../../../../components/ButtonsContainer/ButtonsContainer'
import { useSendCharacterToMineModal, UseSendCharacterToMineModalHook } from './useSendCharacterToMineModal'

/**
 * Changes browser's URL to the atomic hub market URL with character NFTs
 */
const redirectToMarket = () => window.location.assign('https://wax.atomichub.io/market?collection_name=dungeonitems&order=desc&schema_name=characters&sort=created&state=1&symbol=WAX')

type SelectMineModalProps = {
  /**
   * Closes modal
   */
  closeModal: () => void
  /**
   * Manages business logic for sending a character to a mine
   */
  sendCharacterToMineModal: UseSendCharacterToMineModalHook
}

/**
 * Renders all mines that have no character and can be used to stake a character in
 */
export const SelectMineModal: FC<SelectMineModalProps> = props => {
  return <Modal title='Send character to mine' onClickBackdropAndCloseButtons={props.closeModal}>
    {props.sendCharacterToMineModal.minesWithoutCharacters.length > 0
      ? <>
        <MineBuildingModalSubtitle>
          Select the mine you want to send a character to
        </MineBuildingModalSubtitle>
        <NftsScrollableContainer>
          {props.sendCharacterToMineModal.minesWithoutCharacters.map(mine => (
            <LandCard
              key={mine.land.asset_id} landAsset={mine.land} buildingImageShown
              onClick={() => props.sendCharacterToMineModal.setSelectedLandNft(mine.land)}
              selected={
                mine.land.asset_id === props.sendCharacterToMineModal.selectedLandNft?.asset_id
              }
              dataTestId='send-character-to-mine-land-card'
            />
          ))}
        </NftsScrollableContainer>
      </>
      : <MineBuildingModalSubtitle>You have no opened mines</MineBuildingModalSubtitle>}
  </Modal>
}

type SelectCharacterModalProps = {
  /**
   * Closes modal
   */
  closeModal: () => void
  /**
   * Selected land NFT to which a character will be sent
   */
  selectedLandNft: AtomicAsset
  /**
   * Manages business logic for sending a character to a mine
   */
  sendCharacterToMineModal: UseSendCharacterToMineModalHook
}

/**
 * Renders all characters that can be staked to a mine
 */
export const SelectCharacterModal: FC<SelectCharacterModalProps> = props => {
  const nfts = useSelector(nftsSelector)

  return <Modal
    title='Send character to mine' onClickBackdropAndCloseButtons={props.closeModal}
    onBackButtonClick={props.sendCharacterToMineModal.resetSelectLandNft}
  >
    {nfts.characterNfts.length > 0
      ? <>
        <MineBuildingModalSubtitle>
          Select the character you want to send to the mine
        </MineBuildingModalSubtitle>
        <NftsScrollableContainer>
          {nfts.characterNfts.map(character => {
            return (
              <CharacterCard
                key={character.asset_id} characterAsset={character}
                selected={props.sendCharacterToMineModal.ifCharacterSelectedToBeSent(character)}
                onClick={() => props.sendCharacterToMineModal.selectCharacterNft(character)}
                disabled={props.sendCharacterToMineModal.oneCharacterIsBeingSentToMine}
                dataTestId='select-character-modal-character-card'
              />
            )
          })}
        </NftsScrollableContainer>
      </>
      : <MineBuildingModalSubtitle>You have no character NFTs</MineBuildingModalSubtitle>}
    <ButtonsContainer>
      <Button onClick={redirectToMarket} appearance='secondary'>Buy more characters</Button>
      <Button
        onClick={() => props.sendCharacterToMineModal.sendCharacter(props.closeModal)}
        dataTestId='send-character-button' disabled={props.sendCharacterToMineModal.sendCharacterButtonDisabled}
      >Send character</Button>
    </ButtonsContainer>
  </Modal>
}

type SendCharacterToMineModalProps = {
  /**
   * Closes modal
   */
  closeModal: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

/**
 * Renders a modal with logic for staking a character asset to a mine
 */
export const SendCharacterToMineModal: FC<SendCharacterToMineModalProps> = props => {
  const sendCharacterToMineModal = useSendCharacterToMineModal(props.user)

  if (!sendCharacterToMineModal.selectedLandNft) {
    return <SelectMineModal
      closeModal={props.closeModal} sendCharacterToMineModal={sendCharacterToMineModal}
    />
  }

  return <SelectCharacterModal
    closeModal={props.closeModal} selectedLandNft={sendCharacterToMineModal.selectedLandNft}
    sendCharacterToMineModal={sendCharacterToMineModal}
  />
}
