import { useState } from 'react'
import { Mine, miningSelector, recallCharacterFromMine } from '../../../../../store/slices/miningSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../store/types'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'

/**
 * Manages business logic for recalling a character from a mine modal
 * @param user Registered user data
 */
export function useRecallCharacterModal(user: RegisteredUserData) {
  const dispatch = useAppDispatch()

  const mining = useSelector(miningSelector)

  const [mineToRecall, setMineToRecall] = useState<Mine>()

  /**
   * Recalls a character from the {@link mineToRecall} mine if the
   * mine is selected and has a character inside
   * @param afterRecalling Called after recalling is done
   */
  async function recallCharacter(afterRecalling: () => void) {
    if (mineToRecall && mineToRecall.character) {
      await dispatch(recallCharacterFromMine({
        land: mineToRecall.land, character: mineToRecall.character, user: user,
      }))
      afterRecalling()
    }
  }

  /**
   * Checks if the given mine is selected to be recalled
   * @param mine Mine should be checked if it is selected
   */
  const mineSelectedToRecall = (mine: Mine) => mineToRecall?.land.asset_id === mine.land.asset_id

  /**
   * Checks if the given mine card should be disabled
   * @param mine Mine should be checked if it is disabled
   */
  const mineDisabled = (mine: Mine) => mining.recallingCharacterFromMine[mine.land.asset_id] === 'pending'

  const recallCharacterButtonDisabled = !mineToRecall || mining.recallingCharacterFromMine[mineToRecall.land.asset_id] === 'pending'

  /**
   * Selects a mine by updating the {@link selectMineToRecall} state,
   * the character will be recalled from. If given mine is already selected
   * then unselects this mine from the states
   * @param mine Mine should be selected or unselected from the state
   */
  function selectMineToRecall(mine: Mine) {
    setMineToRecall(() => {
      return mineSelectedToRecall(mine) ? undefined : mine
    })
  }

  const minesWithCharacters = mining.mines.filter(item => item.character)

  return {
    /**
     * From this mine the character will be recalled
     */
    mineToRecall,
    /**
     * Indicates if the button for recalling a character from a mine is disabled
     */
    recallCharacterButtonDisabled,
    /**
     * Mines with characters only. Mines without characters inside are filtered out
     */
    minesWithCharacters,
    recallCharacter,
    mineSelectedToRecall,
    mineDisabled,
    selectMineToRecall,
  }
}
