import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { SenseiCard } from '../../../components/SenseiCard/SenseiCard'
import { getMockAsset } from '../../../../test/mocks/asset'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link SenseiCard} component for developers
 */
export const SenseiCardComponent: FC = () => {
  const mockAsset = getMockAsset()
  mockAsset.data.img = 'QmRED3qS1c6M9yeZhnqRJ82WWH63k4uvJT94q6McJvE236'

  return <div>
    <ComponentWrapper title='Div'>
      <SenseiCard asset={mockAsset} />
    </ComponentWrapper>
    <ComponentWrapper title='Button'>
      <SenseiCard asset={mockAsset} onClick={() => alert('Hello :)')} />
    </ComponentWrapper>
    <ComponentWrapper title='Button disabled'>
      <SenseiCard asset={mockAsset} onClick={() => alert('Hello :)')} disabled />
    </ComponentWrapper>
    <ComponentWrapper title='Selected'>
      <SenseiCard asset={mockAsset} selected />
    </ComponentWrapper>
  </div>
}
