import { useMediaQuery } from './useMediaQuery'
import { useEffect } from 'react'
import { updateScreenMode } from '../store/slices/settingsSlice'
import { useDispatch } from 'react-redux'

/**
 * Subscribes to the screen width changes and updates the `screenMode` field
 * in the redux store based on the changes.
 */
export function useScreenMode() {
  const dispatch = useDispatch()

  const portraitModeActive = useMediaQuery('(max-width: 480px)')
  const landscapeModeActive = useMediaQuery('(min-width: 481px) and (max-width: 1024px)')
  const landscapeWideModeActive = useMediaQuery('(min-width: 1025px)')

  // Checks screen width changes and based on the screen width updates redux store.
  // Updated redux store affects adaptive of the app.
  useEffect(() => {
    if (portraitModeActive) {
      dispatch(updateScreenMode('PORTRAIT'))
    } else if (landscapeModeActive) {
      dispatch(updateScreenMode('LANDSCAPE'))
    } else if (landscapeWideModeActive) {
      dispatch(updateScreenMode('LANDSCAPE_WIDE'))
    }
  }, [portraitModeActive, landscapeModeActive, landscapeWideModeActive])
}
