import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { Button } from '../../../components/Button/Button'
import { ButtonsContainer } from '../../../components/ButtonsContainer/ButtonsContainer'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link ButtonsContainerComponent} component
 * for developers
 */
export const ButtonsContainerComponent: FC = () => {
  return <div>
    <ComponentWrapper title='The component is not really visible, but change the viewport to see how container behaviours. You can see the difference at 480px and less'>
      <ButtonsContainer>
        <Button onClick={() => {}}>First button</Button>
        <Button onClick={() => {}}>Second button</Button>
      </ButtonsContainer>
    </ComponentWrapper>
  </div>
}
