import { AnyAction, createAsyncThunk, createSlice, ThunkDispatch } from '@reduxjs/toolkit'
import { RpcInterfaces, ApiInterfaces } from 'eosjs'

import { AsyncThunkLoading, RootState } from '../types'
import {
  getCurrencyBalance,
  getScatterAccount,
  getWaxCloudWalletAccount,
  scatterTransact, WaxAccountData,
  waxCloudWalletTransact,
} from '../../wax'
import {
  AuthenticationData,
  AuthenticationType, getUserData,
  loginScatter,
  loginWaxCloudWallet, RegisteredUserData,
} from '../../api/dungeon-worlds/user'
import { envVars } from '../../services/envVariables'

const dungeonMasterRegistrationView = `${envVars.DUNGEON_MASTER_URL}/register`
const wombatTokensSmartContractName = envVars.WOMBAT_TOKENS_SMARTCONTRACT_NAME
const wombatTokenSymbol = envVars.WOMBAT_TOKEN_SYMBOL

/**
 * Converts a {@link Uint8Array} to a hexadecimal string
 */
export function uint8ArrayToHex (data: Uint8Array): string {
  return data.reduce((acc, next) => acc + next.toString(16).padStart(2, '0'), '')
}

/**
 * Returns the login transaction based on the WAX account
 * @param waxAccount WAX account should be used in the transaction
 */
export function getLoginTransaction (waxAccount: string) {
  return {
    expiration: '1970-01-01T00:00:01.000',
    ref_block_num: 1,
    ref_block_prefix: 1,
    actions: [{
      account: 'wombatmaster',
      name: 'login',
      authorization: [{
        actor: waxAccount,
        permission: 'active'
      }],
      data: {
        account: waxAccount,
        timestamp: Date.now()
      }
    }]
  }
}

/**
 * Returns authentication data for the user based on the chosen wallet
 * @param account User data
 * @param transactor Transactor the transaction executes with
 */
export async function getAuthenticationData(
  account: WaxAccountData,
  transactor: (tx: ApiInterfaces.Transaction, options?: ApiInterfaces.TransactConfig) => Promise<
    ApiInterfaces.TransactResult |
    RpcInterfaces.ReadOnlyTransactResult |
    RpcInterfaces.PushTransactionArgs
  >,
): Promise<AuthenticationData> {
  const transactionData = await transactor(
    getLoginTransaction(account.accountName), { broadcast: false }
  ) as RpcInterfaces.PushTransactionArgs

  return {
    serializedTransaction: uint8ArrayToHex(transactionData.serializedTransaction),
    signatures: transactionData.signatures,
    publicKeys: account.publicKeys,
    accountName: account.accountName,
  }
}

/**
 * Handles login result based on the input
 * @param resultCode Result code from the login request
 * @param dispatch Dispatches redux thunks
 */
export function handleLoginResult (
  resultCode: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) {
  if (resultCode === 'SUCCESS') {
    dispatch(getUser())
  } else if (resultCode === 'DM_ACCOUNT_NOT_FOUND') {
    window.location.replace(dungeonMasterRegistrationView)
  }
}

/**
 * Logs the user in with Scatter
 * @param dispatch Dispatches redux thunks
 */
export async function loginWithScatter (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
  const account = await getScatterAccount()
  const data = await getAuthenticationData(account, scatterTransact)
  return loginScatter(data)
    .then(res => handleLoginResult(res.code, dispatch))
    .catch(e => handleLoginResult(e.responseBody.code, dispatch))
}

/**
 * Logs the user in with Wax Cloud Wallet
 * @param dispatch Dispatches redux thunks
 */
async function loginWithWaxCloudWallet (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
  const account = await getWaxCloudWalletAccount()
  const data = await getAuthenticationData(account, waxCloudWalletTransact)
  return loginWaxCloudWallet(data)
    .then(res => handleLoginResult(res.code, dispatch))
    .catch(e => handleLoginResult(e.responseBody.code, dispatch))
}

type InitialUserState = {
  /**
   * Status of the logging in thunk
   */
  loggingIn: AsyncThunkLoading
  /**
   * Status of the getting user thunk
   */
  gettingUser: AsyncThunkLoading
  /**
   * User data. Can be undefined if the user does not exist
   */
  user?: RegisteredUserData
  /**
   * Wombat balance of the user.
   * The format is a number, but the type is a string because later it is used by Decimal.js and
   * in general all values for crypto calculations better to keep as strings.
   */
  wombatBalance: string
}

/**
 * Initial state of the {@link userSlice}
 */
export const userInitialState: InitialUserState = {
  loggingIn: 'idle',
  gettingUser: 'idle',
  user: undefined,
  wombatBalance: '0',
}

/**
 * Thunk for logging in based on the wallet
 */
export const login = createAsyncThunk<
  unknown,
  { wallet: AuthenticationType }
>('user/login', async (
  { wallet },
  { dispatch },
) => {
  if (wallet === 'SCATTER') {
    await loginWithScatter(dispatch)
  } else {
    await loginWithWaxCloudWallet(dispatch)
  }
})

type GetUserResult = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Wombat tokens balance of the user.
   * The format just a number, but the type is string because later it is used by Decimal.js.
   */
  wombatBalance: string
}

/**
 * Thunk for getting user data
 */
export const getUser = createAsyncThunk<
  GetUserResult | undefined
>('user/getUser', async () => {
  const userData = await getUserData()

  // Inits wallet instances based on the authentication type
  if (userData?.authenticationType === 'SCATTER') {
    await getScatterAccount()
  } else if (userData?.authenticationType === 'WAX_CLOUD_WALLET') {
    await getWaxCloudWalletAccount()
  }

  if (userData) {
    const wombatBalance = await getCurrencyBalance(
      wombatTokensSmartContractName, userData.waxAccount, wombatTokenSymbol
    )
    // Checks if the user has wombat tokens in their wallet
    if (wombatBalance[0]) {
      // The result looks like "1.23456767 WOMBAT", split at the space to remove the symbol
      const [wombatAmount] = wombatBalance[0].split(' ')
      return { user: userData, wombatBalance: wombatAmount }
    }

    // At this point we are sure that the user does not have any wombat tokens and we can set a zero
    return { user: userData, wombatBalance: '0' }
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.loggingIn = 'pending'
      })
      .addCase(login.fulfilled, state => {
        state.loggingIn = 'succeeded'
      })
      .addCase(login.rejected, (state, action) => {
        state.loggingIn = 'failed'
        console.error('Error logging', action.error)
      })
      .addCase(getUser.pending, state => {
        state.gettingUser = 'pending'
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.gettingUser = 'succeeded'
        if (action.payload) {
          state.user = action.payload.user
          state.wombatBalance = action.payload.wombatBalance
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        state.gettingUser = 'failed'
        console.error('Error getting user', action.error)
      })
  }
})

export const userSelector = (state: RootState) => state.user

export const userReducer = userSlice.reducer
