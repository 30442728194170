import { useAppDispatch } from '../../../../../../store/types'
import { useSelector } from 'react-redux'
import { claimMaterials, miningSelector } from '../../../../../../store/slices/miningSlice'
import { useEffect, useState } from 'react'
import { AtomicAsset } from '../../../../../../api/atomic/types/asset'

// After claiming materials the button is hidden for some time
// to avoid a lot of calls to the backend
export const TIME_IN_MS_FOR_HIDING_BUTTON_AFTER_CLAIMING_MATERIALS = 15_000

/**
 * Manages business logic for a button to claim a material
 * @param landAsset Land asset of a mine the material should be claimed for
 */
export function useClaimMaterialButton(landAsset: AtomicAsset) {
  const dispatch = useAppDispatch()
  const mining = useSelector(miningSelector)

  const [buttonHidden, setButtonHidden] = useState(false)

  const buttonDisabled = mining.claimingMaterials[landAsset.asset_id] === 'pending'

  const claimMaterial = async () => {
    if (!buttonDisabled) {
      await dispatch(claimMaterials({ landAssetId: landAsset.asset_id }))
      setButtonHidden(true)
    }
  }

  useEffect(() => {
    if (buttonHidden) {
      const showButton = setTimeout(() => {
        setButtonHidden(false)
      }, TIME_IN_MS_FOR_HIDING_BUTTON_AFTER_CLAIMING_MATERIALS)

      return () => clearInterval(showButton)
    }
  }, [buttonHidden])

  return {
    /**
     * Indicates if the button for claiming a material is disabled
     */
    buttonDisabled,
    /**
     * Indicates if the button for claiming a material is hidden
     */
    buttonHidden,
    /**
     * Claims a material for the {@link landAsset} asset the mine is opened with
     */
    claimMaterial,
  }
}
