import { FC, PropsWithChildren, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { RoundedFrame } from '../RoundedFrame/RoundedFrame'
import { settingsSelector } from '../../store/slices/settingsSlice'

import CloseIcon from '../../assets/images/icons/close.svg'
import BackIcon from '../../assets/images/icons/back-arrow.svg'

import './Modal.scss'

type ModalProps = PropsWithChildren<{
  /**
   * Title of the modal
   */
  title: string
  /**
   * Called on the backdrop button click and on the close button click.
   * Close button means a button with the cross icon and which located in the top-right corner.
   */
  onClickBackdropAndCloseButtons: () => void
  /**
   * Class name for the component
   * @default undefined which means no custom class name is applied by default.
   */
  className?: string
  /**
   * Called on back button click.
   * @default undefined which means no back button is shown by default.
   */
  onBackButtonClick?: () => void
}>

const ModalComponent: FC<ModalProps> = props => {
  const settings = useSelector(settingsSelector)

  useEffect(() => {
    // Closes modal by clicking on the "Escape" button
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.onClickBackdropAndCloseButtons()
      }
    }

    document.body.addEventListener('keydown', onKeyDown)

    return () => document.body.removeEventListener('keydown', onKeyDown)
  }, [])

  return (
    <div className={classnames('modal', props.className, {
      'modal--landscape-wide': settings.screenMode === 'LANDSCAPE_WIDE',
    })} data-testid='modal'>
      <button onClick={props.onClickBackdropAndCloseButtons} data-testid='modal-backdrop' className='modal__backdrop' />
      <RoundedFrame className='modal__frame' hideAdditionalBorders>
        <button onClick={props.onClickBackdropAndCloseButtons} data-testid='modal-close-button' className='modal__close-button'>
          <img src={CloseIcon} alt='Close icon with a cross inside' />
        </button>
        <div className='modal__content'>
          <div className={classnames('modal__content-header', {
            // If the back button shown the header should have greater padding
            'modal__content-header--greater-padding': props.onBackButtonClick,
          })} data-testid='modal-header'>
            {props.onBackButtonClick && (
              <button
                onClick={props.onBackButtonClick} data-testid='modal-back-button'
                className='modal__content-header-back-btn'
              >
                <img
                  src={BackIcon} className='modal__content-header-back-btn-icon'
                  alt='Black arrow icon with gold background'
                />
                Back
              </button>
            )}
            <div>{props.title}</div>
          </div>
          <div className='modal__content-main'>{props.children}</div>
        </div>
      </RoundedFrame>
    </div>
  )
}

export const Modal: FC<ModalProps> = props => {
  const container = document.body

  return ReactDOM.createPortal(<ModalComponent {...props} />, container)
}
