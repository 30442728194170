import { FC, PropsWithChildren } from 'react'
import classnames from 'classnames'

import './Container.scss'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../store/slices/settingsSlice'

type ContainerProps = PropsWithChildren<{
  /**
   * Class name
   */
  className?: string
  /**
   * Data test id. Used by jest
   */
  dataTestId?: string
}>

/**
 * The component should be used as a main wrapper for every page
 * to center elements with same padding
 */
export const Container: FC<ContainerProps> = ({ children, className = '', dataTestId = '' }) => {
  const settings = useSelector(settingsSelector)
  return <div
    className={classnames('container', className, {
      'container--big': settings.screenMode === 'LANDSCAPE_WIDE'
    })}
    data-testid={dataTestId}>{children}</div>
}
