/**
 * Checks if the input image is an HTTP URL or an IPFS file hash.
 */
const isImgLink = (img?: string): boolean => (img?.startsWith('https://') || img?.startsWith('http://')) ?? false

/**
 * Composes a valid HTTP URL for an AtomicAsset image.
 *
 * If the given `img` is an IPFS file hash, it will create a URL string using it
 * and the AtomicHub IPFS gateway as a base. In all other cases, The `img` is
 * assumed to be already a valid HTTP URL and will be returned as-is.
 */
export const composeAtomicAssetImgUrl = (img?: string): string | undefined => {
  // TODO: Return placeholder image if img is undefined
  if (!img) {
    return undefined
  }

  if (isImgLink(img)) {
    return img
  }

  const baseIpfsUrl = 'https://womplay.io/img/nfts/resized'

  // Trim IPFS ids. Our own Wombatium NFTs sadly have "misprint"
  // where some image IDs have a space at the end which will not work here.
  return `${baseIpfsUrl}/${img.trim()}_small.webp`
}
