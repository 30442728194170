import { FC, ReactNode } from 'react'
import classnames from 'classnames'

import './Button.scss'

/**
 * PRIMARY - green, SECONDARY - yellow, TERTIARY - purple, GHOST - white
 */
export type ButtonAppearance = 'primary' | 'secondary' | 'tertiary' | 'ghost'
export type ButtonType = 'submit' | 'reset' | 'button'
export type ButtonSize = 'default' | 'small'

/**
 * Represents all possible props for the {@link Button} component
 */
export type ButtonProps = {
  /**
   * Children element of the button
   */
  children: ReactNode
  /**
   * Called on button click
   */
  onClick: () => void
  /**
   * Button appearance
   */
  appearance?: ButtonAppearance
  /**
   * Button type
   */
  type?: ButtonType
  /**
   * If the button is disabled
   */
  disabled?: boolean
  /**
   * Button's icon. If nothing passed the button does not have an icon.
   * Should be passed the <img> tag with needed image.
   */
  icon?: ReactNode
  /**
   * Button size
   */
  size?: ButtonSize
  /**
   * Test ID is assigned to the button.
   * Uses by jest
   */
  dataTestId?: string
  /**
   * Class name for the component
   */
  className?: string
}

export const Button: FC<ButtonProps> = ({
  children, onClick, appearance = 'primary', type = 'button',
  disabled = false, icon, size = 'default', dataTestId,
  className = '',
}) => {
  // Needed because a user can change 'disable' attribute by manually in devtools
  // and call the function from the `onClick` prop.
  // For greater security, we can programmatically check this.
  const onInternalClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return (
    <button
      type={type} disabled={disabled} onClick={onInternalClick}
      className={classnames('button', `button--${appearance}`, `button--size-${size}`, className, {
        'button--with-icon': icon,
      })} data-testid={dataTestId}>
      <span className='button__reflection button__reflection--upper' />
      <span className='button__reflection button__reflection--lower' />
      <span className='button__outer-frame'>
        <span className='button__text-container'>
          {icon && <span className='button__icon' data-testid='button-icon'>
            {icon}
          </span>}
          {children}
        </span>
      </span>
    </button>
  )
}
