import React, { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { Toast } from '../../../components/Toast/Toast'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link Toast} component for developers
 */
export const ToastComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Toast info default'>
      <Toast>Toast</Toast>
    </ComponentWrapper>
    <ComponentWrapper title='Toast warning default'>
      <Toast type='warning'>Toast</Toast>
    </ComponentWrapper>
    <ComponentWrapper title='Toast info large'>
      <Toast size='large'>Toast</Toast>
    </ComponentWrapper>
    <ComponentWrapper title='Toast warning large'>
      <Toast size='large' type='warning'>Toast</Toast>
    </ComponentWrapper>
  </div>
}
