import { FC, PropsWithChildren } from 'react'

import './LocationBanner.scss'
import classnames from 'classnames'

export type LocationBannerSize = 'default' | 'large'

type LocationBannerProps = PropsWithChildren<{
  /**
   * Size of the component
   */
  size?: LocationBannerSize
  /**
   * Disabled state of the component
   */
  disabled?: boolean
  /**
   * Class name for the component
   */
  className?: string
}>

export const LocationBanner: FC<LocationBannerProps> = ({
  children, size = 'large', disabled, className = ''
}) => {
  return <div className={classnames('location-banner', `location-banner--${size}`, className, {
    'location-banner--disabled': disabled
  })} data-testid='location-banner'>
    <span className='location-banner__background location-banner__background--lighter' />
    <span className='location-banner__background location-banner__background--lightest' />
    <span className='location-banner__glowing' />
    <span className='location-banner__reflection location-banner__reflection--top' />
    <span className='location-banner__reflection location-banner__reflection--bottom' />
    <div className='location-banner__children'>{children}</div>
  </div>
}
