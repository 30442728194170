import React, { FC, useState } from 'react'
import { DojoBuildingModalSubtitle } from '../../DojoBuilding'
import { SenseiSlotWithAsset } from '../../../../../../store/slices/dojoSlice'
import { SenseiCard } from '../../../../../../components/SenseiCard/SenseiCard'
import { Button } from '../../../../../../components/Button/Button'
import './StakedSensei.scss'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'
import { RecallSenseiDialog } from '../RecallSenseiDialog/RecallSenseiDialog'

type StakedSenseiProps = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Sensei slot with staked asset in there
   */
  slotWithAsset: SenseiSlotWithAsset
  /**
   * Function is called after recalling a sensei
   * @default undefined
   */
  afterRecallSensei?: () => void
}

export const StakedSensei: FC<StakedSenseiProps> = props => {
  const [confirmRecallDialogOpen, setConfirmRecallDialogOpen] = useState(false)

  const openConfirmRecallDialog = () => setConfirmRecallDialogOpen(true)
  const closeConfirmRecallDialog = () => setConfirmRecallDialogOpen(false)

  return <>
    <div className='staked-sensei'>
      <DojoBuildingModalSubtitle>
        Sensei is empowering your character’s training.
      </DojoBuildingModalSubtitle>
      <SenseiCard asset={props.slotWithAsset.senseiAsset} className='staked-sensei__card' />
      <Button
        className='staked-sensei__button'
        onClick={openConfirmRecallDialog} appearance='secondary'
      >Retrieve</Button>
    </div>
    {confirmRecallDialogOpen && <RecallSenseiDialog
      closeDialog={closeConfirmRecallDialog} user={props.user} slotWithAsset={props.slotWithAsset}
      afterRecallSensei={props.afterRecallSensei}
    />}
  </>
}
