import { FC, PropsWithChildren, useState } from 'react'
import { LocationBanner } from '../../../../components/LocationBanner/LocationBanner'
import MineBuildingConstructedImage from '../../../../assets/images/mine-building-constructed.png'
import MineBuildingDestroyedImage from '../../../../assets/images/mine-building-destroyed.png'
import BuildMine from '../../../../assets/images/icons/build-mine.svg'
import RecallCharacterFromMine from '../../../../assets/images/icons/recall-character-from-mine.svg'
import SendCharacterToMine from '../../../../assets/images/icons/send-character-to-mine.svg'
import MineInfo from '../../../../assets/images/icons/mine-info.svg'
import { BuildingAction } from '../BuildingAction/BuildingAction'
import './MineBuilding.scss'
import { OpenMineModal } from './OpenMineModal/OpenMineModal'
import { SendCharacterToMineModal } from './SendCharacterToMineModal/SendCharacterToMineModal'
import { RecallCharacterModal } from './RecallCharacterModal/RecallCharacterModal'
import { CloseMineModal } from './CloseMineModal/CloseMineModal'
import { ClaimableMaterials } from './ClaimableMaterials/ClaimableMaterials'
import { RegisteredUserData } from '../../../../api/dungeon-worlds/user'
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner'
import CraftingWithPickaxes from 'url:./../../../../assets/videos/crafting-with-pickaxes.webm'
import CraftingWithPickaxesFallback from 'url:./../../../../assets/videos/crafting-with-pickaxes.mp4'
import { MineBuildingHook, useMineBuilding } from './useMineBuilding'
import { ActionsContainer } from '../ActionsContainer/ActionsContainer'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'

export const MineBuildingModalSubtitle: FC<PropsWithChildren> = props => {
  return <h3 className='mine-building__modal-subtitle'>{props.children}</h3>
}

type ActionForMining = 'BUILD_MINE' | 'SEND_CHARACTER' | 'RECALL_CHARACTER' | 'CLOSE_MINE'

type ActionsProps = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Manages mine building business logic
   */
  mineBuilding: MineBuildingHook
}

export const Actions: FC<ActionsProps> = props => {
  const [openedModal, setOpenedModal] = useState<ActionForMining>()

  const closeModal = () => setOpenedModal(undefined)

  return (
    <ActionsContainer>
      <BuildingAction
        text='Build a mine'
        icon={<img src={BuildMine} alt='Golden spatula and bricks' />}
        onClick={() => setOpenedModal('BUILD_MINE')}
        dataTestId='building-action-open-mine'
      />
      <BuildingAction
        text='Send character'
        icon={<img src={SendCharacterToMine} alt='Golden wombat with a purple arrow to the right side' />}
        onClick={() => setOpenedModal('SEND_CHARACTER')}
        dataTestId='building-action-send-character'
      />
      <BuildingAction
        text='Recall character'
        icon={<img src={RecallCharacterFromMine} alt='Golden wombat with a pink arrow to the left side' />}
        onClick={() => setOpenedModal('RECALL_CHARACTER')}
        dataTestId='building-action-recall-character'
      />
      <BuildingAction
        text='Close mine'
        icon={<img src={MineInfo} alt='Entrance of a dungeon with an information icon' />}
        onClick={() => setOpenedModal('CLOSE_MINE')}
        dataTestId='building-action-close-mine'
      />
      {openedModal === 'BUILD_MINE' && <OpenMineModal closeModal={closeModal} user={props.user} mineBuilding={props.mineBuilding} />}
      {openedModal === 'SEND_CHARACTER' && <SendCharacterToMineModal closeModal={closeModal} user={props.user} />}
      {openedModal === 'RECALL_CHARACTER' && <RecallCharacterModal closeModal={closeModal} user={props.user} />}
      {openedModal === 'CLOSE_MINE' && <CloseMineModal closeModal={closeModal} user={props.user} />}
    </ActionsContainer>
  )
}

type MineBuildingProps = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

export const MineBuilding: FC<MineBuildingProps> = props => {
  const mineBuilding = useMineBuilding()
  const mineBuildingRef = useOutsideClick(mineBuilding.closeActions)

  return (
    <div className='mine-building__wrapper'>
      <div ref={mineBuildingRef}>
        {mineBuilding.actionsShown && <Actions user={props.user} mineBuilding={mineBuilding} />}
        <button
          className='mine-building'
          onClick={mineBuilding.toggleActions} data-testid='mine-building-button'
        >
          {mineBuilding.gettingMines
            ? <LoadingSpinner />
            : <>
              <LocationBanner className='mine-building__location-banner'>Mine</LocationBanner>
              {mineBuilding.constructedMineImageShown
                ? <img src={MineBuildingConstructedImage} alt='Constructed building is under the dungeon' />
                : <img src={MineBuildingDestroyedImage} alt='Destroyed entrance at the dungeon' />}
              {mineBuilding.buildingAnimationShown && <video
                src={CraftingWithPickaxes} className='mine-building__video'
                controls={false} autoPlay muted playsInline loop data-testid='mine-building-video'
              >
                <source src={CraftingWithPickaxes} type='video/webm' />
                <source src={CraftingWithPickaxesFallback} type='video/mp4' />
              </video>}
            </>}
        </button>
      </div>
      <ClaimableMaterials />
    </div>
  )
}
