import React, { FC } from 'react'
import { DojoBuildingModalSubtitle } from '../../DojoBuilding'
import { NftsScrollableContainer } from '../../../../../../components/NftsScrollableContainer/NftsScrollableContainer'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'
import { SenseiCard } from '../../../../../../components/SenseiCard/SenseiCard'
import { Button } from '../../../../../../components/Button/Button'
import { ButtonsContainer } from '../../../../../../components/ButtonsContainer/ButtonsContainer'
import { useStakeableSenseis } from './useStakeableSenseis'

/**
 * Redirects the user to the market for buying sensei assets
 */
const redirectToMarket = () => window.location.replace('https://wax.atomichub.io/market?collection_name=wombatchamps&order=desc&schema_name=wombality&sort=created&state=1&symbol=WAX')

type StakeableSenseisProps = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

export const StakeableSenseis: FC<StakeableSenseisProps> = ({ user }) => {
  const stakeableSenseis = useStakeableSenseis(user)

  return <>
    <DojoBuildingModalSubtitle>
      Invite a Sensei to elevate your character's training in Dojo.
    </DojoBuildingModalSubtitle>
    <NftsScrollableContainer>
      {stakeableSenseis.senseiNfts.map(asset => {
        const senseiSelected = stakeableSenseis.selectedSenseiNft?.asset_id === asset.asset_id
        const selectSenseiNft = () => stakeableSenseis.setSelectedSenseiNft(() => {
          return senseiSelected ? undefined : asset
        })
        return <SenseiCard
          key={asset.asset_id} asset={asset} onClick={selectSenseiNft}
          selected={senseiSelected}
        />
      })}
    </NftsScrollableContainer>
    <ButtonsContainer>
      <Button
        onClick={redirectToMarket} appearance='secondary'
      >Buy more sensei</Button>
      <Button
        onClick={stakeableSenseis.stake} disabled={stakeableSenseis.submitButtonDisabled}
        dataTestId='stakeable-senseis-stake-button'
      >Invite sensei</Button>
    </ButtonsContainer>
  </>
}
