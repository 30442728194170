import { FC } from 'react'
import './ClaimableMaterials.scss'
import classnames from 'classnames'
import { useClaimableMaterials } from './useClaimableMaterials'
import { ClaimMaterialButton } from './ClaimMaterialButton/ClaimMaterialButton'
import GoldPot from '../../../../../assets/images/icons/materials/pot-with-gold.png'
import UraniumPot from '../../../../../assets/images/icons/materials/pot-with-uranium.png'
import IronPot from '../../../../../assets/images/icons/materials/pot-with-iron.png'
import CoalPot from '../../../../../assets/images/icons/materials/pot-with-coal.png'

export const CONTAINER_WIDTH = 180
export const CONTAINER_HEIGHT = 180

export type ImageData = {
  /**
   * URL to the material image
   */
  imageUrl: string
  /**
   * Alt text for the material image
   */
  altText: string
}

/**
 * Mapping with image data for every material
 */
export const imageDataMapping: Record<string, ImageData> = {
  Gold: { imageUrl: GoldPot, altText: 'Pot with some gold' },
  Uranium: { imageUrl: UraniumPot, altText: 'Pot with some uranium' },
  Iron: { imageUrl: IronPot, altText: 'Pot with some iron' },
  Coal: { imageUrl: CoalPot, altText: 'Pot with some coal' },
}

type ClaimableMaterialsProps = {
  /**
   * Class name for the component
   */
  className?: string
}

/**
 * Renders all pots with claimable materials the user has
 */
export const ClaimableMaterials: FC<ClaimableMaterialsProps> = ({ className = '' }) => {
  const claimableMaterials = useClaimableMaterials()

  return <div
    className={classnames('claimable-materials', className)}
    style={{ width: `${CONTAINER_WIDTH}px`, height: `${CONTAINER_HEIGHT}px` }}
    data-testid='claimable-materials'
  >
    {claimableMaterials.minesWithCharacters.map(mine => {
      const imageData = imageDataMapping[mine.land.data.material]
      const buttonOffset = claimableMaterials.buttonPositions[mine.land.asset_id]
      return imageData
        ? <ClaimMaterialButton
          key={mine.land.asset_id} imageData={imageData}
          buttonPosition={buttonOffset} landAsset={mine.land}
        />
        : null
    })}
  </div>
}
