import { FC, PropsWithChildren } from 'react'
import { AtomicAsset } from '../../api/atomic/types/asset'
import { RoundedFrame } from '../RoundedFrame/RoundedFrame'
import { RectangleFrame } from '../RectangleFrame/RectangleFrame'
import { composeAtomicAssetImgUrl } from '../../services/atomicAssets'
import SelectedIcon from '../../assets/images/icons/selected.svg'
import classnames from 'classnames'
import './SenseiCard.scss'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../store/slices/settingsSlice'
import Decimal from 'decimal.js'

/**
 * Calculates XP boost for the staked sensei
 * @param senseiAsset Sensei NFT is used for calculating the boost
 */
export const calculateXpSenseiBoost = (
  senseiAsset: AtomicAsset,
): Decimal => {
  let boost = new Decimal('0')

  // The formula for the power attribute is: (power * power) / 200
  if (senseiAsset.data.power) {
    const power = new Decimal(senseiAsset.data.power)
    const powerBoost = power.mul(power).div(200)

    boost = boost.plus(powerBoost)
  }

  return boost
}

type ConditionalButtonSenseiCardProps = PropsWithChildren<
  Omit<SenseiCardProps, 'asset'>
>

/**
 * Decides if the button tag should render based on the onClick prop availability
 */
export const ConditionalButtonSenseiCard: FC<ConditionalButtonSenseiCardProps> = ({
  onClick, disabled, selected, children, className,
}) => {
  const { screenMode } = useSelector(settingsSelector)

  const classNames = classnames('sensei-card', className, {
    'sensei-card--selected': selected,
    'sensei-card--landscape': screenMode === 'LANDSCAPE',
    'sensei-card--landscape-wide': screenMode === 'LANDSCAPE_WIDE',
  })

  if (!onClick) {
    return <div className={classNames} data-testid='conditional-button-sensei-card'>{children}</div>
  }

  // Needed because a user can change 'disable' attribute by manually in devtools
  // and call the function from the `onClick` prop.
  // For greater security, we can programmatically check this.
  const onInternalClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return <button
    disabled={disabled} onClick={onInternalClick}
    className={classNames} type='button'
    data-testid='conditional-button-sensei-card'
  >
    {children}
  </button>
}

type SenseiCardProps = {
  /**
   * Sensei asset that should be rendered
   */
  asset: AtomicAsset
  /**
   * If the card is disabled and not clickable
   * @default undefined
   */
  disabled?: boolean
  /**
   * If the card is selected for an action.
   * If selected then an checkbox icon is rendering as well.
   * @default undefined
   */
  selected?: boolean
  /**
   * Called by click on the component.
   * If passed the component renders <button> tag, otherwise <div>.
   * Also, if passed then the card has the rectangle proportions,
   * otherwise it has the square proportions without the additional rounded border.
   * @default undefined
   */
  onClick?: () => void
  /**
   * Class name for the component
   * @default Empty string
   */
  className?: string
}

export const SenseiCard: FC<SenseiCardProps> = props => {
  const xpBoost = calculateXpSenseiBoost(props.asset)

  return (
    <ConditionalButtonSenseiCard
      onClick={props.onClick} disabled={props.disabled} selected={props.selected}
      className={props.className}
    >
      <RoundedFrame className='sensei-card__rounded-frame'>
        <RectangleFrame className='sensei-card__rectangle-frame'>
          <img
            src={composeAtomicAssetImgUrl(props.asset.data.img)}
            className='sensei-card__media'
            alt='Wombat champ sensei image'
          />
          {props.selected && <>
            <img src={SelectedIcon} className='sensei-card__selected-icon' alt='Check icon in a golden background' />
          </>}
          <RectangleFrame className='sensei-card__xp-boost'>
            XP boost
            <span className='sensei-card__xp-boost-value'>+{xpBoost.toString()}%</span>
          </RectangleFrame>
        </RectangleFrame>
      </RoundedFrame>
    </ConditionalButtonSenseiCard>
  )
}
