import { useAppDispatch } from '../../../../../../store/types'
import { useSelector } from 'react-redux'
import {
  dojoSelector,
  removeLastScoredXpForSlot, SenseiSlot,
  TrainingSlotWithAsset,
} from '../../../../../../store/slices/dojoSlice'
import { settingsSelector } from '../../../../../../store/slices/settingsSlice'
import Decimal from 'decimal.js'
import { useEffect } from 'react'
import { getProgressDataForCharacterLevel } from './StakedCharacterInDojo'

/**
 * Hook contains business logic for the staked character in dojo
 * @param trainingSlot The training slot staked in Dojo with a character asset in there
 */
export function useStakedCharacterInDojo(trainingSlot: TrainingSlotWithAsset) {
  const dispatch = useAppDispatch()

  const dojo = useSelector(dojoSelector)
  const settings = useSelector(settingsSelector)

  const levelConfig = dojo.levelsConfig
    .find(config => new Decimal(config.level).eq(trainingSlot.characterAsset.data.level))

  const progressDataForLevel = levelConfig
    ? getProgressDataForCharacterLevel(trainingSlot.characterAsset, levelConfig)
    : undefined

  const scoredXp = dojo.lastScoredXpForTrainingSlots[trainingSlot.slot]

  // So far, we support only 1 slot for senseis
  const senseiSlot: SenseiSlot | undefined = dojo.senseiSlots[0]

  useEffect(() => {
    if (scoredXp) {
      // Shows the last scored XP for this slot for 3 seconds
      // and after that removes it from the store
      const timeoutId = setTimeout(() => {
        dispatch(removeLastScoredXpForSlot(trainingSlot.slot))
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
        dispatch(removeLastScoredXpForSlot(trainingSlot.slot))
      }
    }
  }, [scoredXp])

  return {
    /**
     * Current screen mode
     */
    screenMode: settings.screenMode,
    /**
     * Last scored XP amount for the training slot
     */
    scoredXp,
    /**
     * Current progress data for the character's level is staked in the slot
     */
    progressDataForLevel,
    /**
     * Level config for the current level of the character asset in the slot
     */
    levelConfig,
    /**
     * Sensei slot with the possible staked sensei asset in there
     */
    senseiSlot,
  }
}
