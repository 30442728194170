import { RpcInterfaces } from 'eosjs'
import { getTableRows } from '../wax'
import { envVars } from './envVariables'

type SlotAndCharactersFromSmartContract = {
  /**
   * Slot ID where the character asset staked at
   */
  slot: number
  /**
   * Staked character asset ID. Can be `0` that means the slot is empty
   */
  characterAssetId: string | 0
  /**
   * Last time when the user claimed XP for this particular asset.
   * The format is unix ms timestamp
   */
  lastXpClaimAt: number
}

type SenseiSlotFromSmartContract = {
  /**
   * Slot ID where the sensei asset staked at
   */
  slot: number
  /**
   * Staked sensei asset ID. Can be `0` that means the slot is empty
   */
  senseiAssetId: string | 0
}

/**
 * Gets the character asset IDs (if exist) with slots that are in dojo from the smart contract
 * @param userWaxAccount The wax account the character asset IDs with slots should be gotten for
 * @return List of character asset IDs (if exists) with slots.
 * If no character asset staked to the slot the row returns `0` as a character ID
 */
export const getSlotAndCharactersFromSmartContract = async (
  userWaxAccount: string
): Promise<SlotAndCharactersFromSmartContract[]> => {
  const rows = []
  let more = true
  let lowerBound: string | undefined = undefined
  // The loop is used here since the user can have as many slots as they want and could be
  // that all slots do not fit in one request
  do {
    const result: RpcInterfaces.GetTableRowsResult = await getTableRows(
      envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME, 'dojo', userWaxAccount, 100, lowerBound,
    )
    rows.push(...result.rows)
    more = result.more
    lowerBound = result.next_key
  } while (more)
  return rows.map(item => ({
    slot: item.slot,
    characterAssetId: item.character,
    // If you add the `Z` symbol in the end of the date
    // it converts UTC string correctly and gets the unix ms.
    lastXpClaimAt: new Date(`${item.last_xp_claim}Z`).getTime(),
  }))
}

/**
 * Gets the sensei slots with possible sensei NFTs in there from smartcontract
 * @param userWaxAccount The wax account the sensei slots should be gotten for
 * @return List of sensei slots with possible NFTs in there.
 * If no sensei asset staked to the slot the row returns `0` as a sensei asset ID
 */
export const getSenseiSlotsFromSmartContract = async (
  userWaxAccount: string
): Promise<SenseiSlotFromSmartContract[]> => {
  const result = await getTableRows(envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME, 'senseis', userWaxAccount, 25)
  return result.rows.map(item => ({
    slot: item.slot,
    senseiAssetId: item.sensei,
  }))
}
