import { FC } from 'react'
import { useSelector } from 'react-redux'
import { initialLoadingSelector } from '../../store/slices/initialLoadingSlice'
import { Container } from '../../components/Container/Container'

import DungeonWorldsLogo from '../../assets/images/dungeon-worlds-logo.png'

import './LoadingView.scss'
import { ProgressBar } from '../../components/ProgressBar/ProgressBar'

export const LoadingView: FC = () => {
  const { progress } = useSelector(initialLoadingSelector)

  return (
    <Container className='loading-view__container'>
      <img
        src={DungeonWorldsLogo} className='loading-view__logo'
        alt='Wombat in a cart with a pickaxe inside a beautiful frame'
      />
      <ProgressBar progress={progress} className='loading-view__progress-bar' />
      <div className='loading-view__loading-value'>{progress}%</div>
    </Container>
  )
}
