import { AtomicAsset } from '../../src/api/atomic/types/asset'

/**
 * Returns a mock asset.
 * Should be used for tests only.
 * It is better to have it in a function, instead of a variable because
 * the function returns always a new object, and you can modify it however you want
 * without changing the asset in other places.
 */
export const getMockAsset = (): AtomicAsset => {
  return {
    contract: 'atomicassets',
    asset_id: '123',
    owner: 'user',
    is_transferable: true,
    is_burnable: true,
    collection: {
      collection_name: 'mockcollection',
      name: 'Dungeon Items',
      img: 'QmWrtAbdoQHVfp9vo3fwarN7mDGiM49FWeaVcSE6w2vtqw',
      author: 'dungeontests',
      allow_notify: true,
      authorized_accounts: ['dungeontests'],
      notify_accounts: ['wombatnotify', 'dungeontests'],
      market_fee: 0.06,
      created_at_block: '165549923',
      created_at_time: '1644231206500',
    },
    schema: {
      schema_name: 'testschema',
      format: [
        {
          name: 'name',
          type: 'string'
        },
        {
          name: 'about',
          type: 'string'
        },
        {
          name: 'video',
          type: 'ipfs'
        },
        {
          name: 'img',
          type: 'image'
        },
        {
          name: 'back_img',
          type: 'image'
        },
        {
          name: 'dapp_url',
          type: 'string'
        }
      ],
      created_at_block: '',
      created_at_time: ''
    },
    template: {
      template_id: '75',
      max_supply: '0',
      is_transferable: true,
      is_burnable: true,
      issued_supply: '10',
      immutable_data: {
        img: '',
        name: 'test name',
        about: '321',
        video: '111',
        back_img: '222',
        dapp_url: 'https://dungeon.wombat.app',
      },
      created_at_time: '1660739842000',
      created_at_block: '198560992'
    },
    mutable_data: {},
    immutable_data: {},
    template_mint: '10',
    backed_tokens: [],
    burned_by_account: null,
    burned_at_block: null,
    burned_at_time: null,
    updated_at_block: '',
    updated_at_time: '',
    minted_at_block: '',
    minted_at_time: '',
    data: {
      name: 'test name'
    },
    name: 'test name'
  }
}
