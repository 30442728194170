import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link ProgressBar} component
 * for developers
 */
export const ProgressBarComponent: FC = () => {
  return <div>
    <ComponentWrapper title='10%'>
      <ProgressBar progress={10} />
    </ComponentWrapper>
    <ComponentWrapper title='50%'>
      <ProgressBar progress={50} />
    </ComponentWrapper>
    <ComponentWrapper title='100%'>
      <ProgressBar progress={100} />
    </ComponentWrapper>
  </div>
}
