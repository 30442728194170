import { useSelector } from 'react-redux'
import { miningSelector } from '../../../../../store/slices/miningSlice'
import { useMemo } from 'react'
import { CONTAINER_HEIGHT, CONTAINER_WIDTH } from './ClaimableMaterials'

export const BUTTON_WIDTH = 50
export const BUTTON_HEIGHT = 50

/**
 * Returns a random position of a button for claiming materials
 */
export const getRandomPositionForButton = (): ButtonPosition => {
  const maxOffsetX = CONTAINER_WIDTH - BUTTON_WIDTH
  const maxOffsetY = CONTAINER_HEIGHT - BUTTON_HEIGHT

  return {
    offsetX: Math.random() * maxOffsetX,
    offsetY: Math.random() * maxOffsetY,
  }
}

type ButtonPosition = {
  /**
   * Offset by X for the button position
   */
  offsetX: number
  /**
   * Offset by Y for the button position
   */
  offsetY: number
}

/**
 * Manages business logic for claimable materials in pots
 */
export function useClaimableMaterials() {
  const { mines } = useSelector(miningSelector)

  const minesWithCharacters = useMemo(() => mines.filter(mine => mine.character), [mines])

  const buttonPositions = useMemo(() => {
    // String in the Record is the land asset id
    const buttonPositions: Record<string, ButtonPosition> = {}
    minesWithCharacters.forEach(mine => {
      buttonPositions[mine.land.asset_id] = getRandomPositionForButton()
    })
    return buttonPositions
  }, [minesWithCharacters])

  return {
    /**
     * Contains all mines with characters inside. Mines without characters are filtered out.
     */
    minesWithCharacters,
    /**
     * Position of buttons for claiming materials.
     * String in the Record is the land asset ID of a mine
     */
    buttonPositions,
  }
}
