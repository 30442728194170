import { envVars } from '../../services/envVariables'

const dungeonWorldsApiUrl = envVars.DUNGEON_WORLDS_API_SERVER

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'

/**
 * Error class for API requests resulting in the 401 status code
 */
export class UnauthorizedError extends Error {
  constructor (url: string, status: number, public responseBody: Record<string, any>) {
    super(`Unauthorized request to endpoint ${url}, status ${status}, code ${responseBody.code}`)
  }
}

/**
 * Error class for API requests resulting in a 5xx status code
 */
export class ApiError extends Error {
  constructor (url: string, status: number, public responseBody: string) {
    super(`Erroneous request to endpoint ${url}, status ${status}, response body ${responseBody}`)
  }
}

/**
 * Error class for API requests resulting in a 4xx status code
 */
export class ClientError extends Error {
  constructor (url: string, status: number, public responseBody: Record<string, any>) {
    super(`Erroneous request to endpoint ${url}, status ${status}, code ${responseBody.code}`)
  }
}

const errorConverter = async (res: Response) => {
  const isResponseJson = res.headers.get('content-type')?.includes('application/json')
  if (res.status === 401 || res.status === 403) {
    const responseBody = isResponseJson ? await res.json() : { code: 'UNKNOWN' }
    throw new UnauthorizedError(res.url, res.status, responseBody)
  } else if (res.status >= 400 && res.status < 500) {
    const responseBody = isResponseJson ? await res.json() : { code: 'UNKNOWN' }
    throw new ClientError(res.url, res.status, responseBody)
  } else if (res.status >= 500) {
    throw new ApiError(res.url, res.status, await res.text())
  } else {
    return res
  }
}

/**
 * Sends a request to the Dungeon Worlds API and returns a response
 * @param endpoint API endpoint the request should be sent to
 * @param method Method the request should be sent with
 * @param data Data should be added in the request
 */
export const sendRequestToDungeonWorldsApi = async (
  endpoint: string, method: Method = 'GET', data: object = {}
): Promise<Response> => {
  const url = dungeonWorldsApiUrl + endpoint

  // Request with method GET can't contain
  // data in body.
  if (method === 'GET') {
    return fetch(url, {
      credentials: 'include'
    }).then(errorConverter)
  }

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const res = await fetch(url, {
    method: method,
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(data)
  })

  return errorConverter(res)
}
