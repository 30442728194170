import React, { FC } from 'react'
import './RecallCharacterModal.scss'
import { Modal } from '../../../../../components/Modal/Modal'
import { useSelector } from 'react-redux'
import { Button } from '../../../../../components/Button/Button'
import { MineBuildingModalSubtitle } from '../MineBuilding'
import { LandWithCharacterCard } from '../../../../../components/LandWithCharacterCard/LandWithCharacterCard'
import { settingsSelector } from '../../../../../store/slices/settingsSlice'
import classnames from 'classnames'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'
import { NftsScrollableContainer } from '../../../../../components/NftsScrollableContainer/NftsScrollableContainer'
import { useRecallCharacterModal } from './useRecallCharacterModal'

type RecallCharacterModalProps = {
  /**
   * Closes the modal
   */
  closeModal: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

/**
 * Renders all mines with characters the characters can be recalled froms
 */
export const RecallCharacterModal: FC<RecallCharacterModalProps> = props => {
  const settings = useSelector(settingsSelector)

  const recallCharacterModal = useRecallCharacterModal(props.user)

  return <Modal title='Recall character from mine' onClickBackdropAndCloseButtons={props.closeModal}>
    {recallCharacterModal.minesWithCharacters.length > 0
      ? <>
        <MineBuildingModalSubtitle>
          Select the character you wish to recall.
          Once recalled, it will be available for other activities.
        </MineBuildingModalSubtitle>
        <NftsScrollableContainer>
          {recallCharacterModal.minesWithCharacters.map(mine => {
            return mine.character
              ? <LandWithCharacterCard
                key={mine.land.asset_id} landAsset={mine.land}
                characterAsset={mine.character}
                onClick={() => recallCharacterModal.selectMineToRecall(mine)}
                selected={recallCharacterModal.mineSelectedToRecall(mine)}
                disabled={recallCharacterModal.mineDisabled(mine)}
                dataTestId='recall-character-modal-land-with-character-card'
              />
              : null
          })}
        </NftsScrollableContainer>
        <Button
          onClick={() => recallCharacterModal.recallCharacter(props.closeModal)}
          disabled={recallCharacterModal.recallCharacterButtonDisabled}
          className={classnames('recall-character-modal__button', {
            'recall-character-modal__button--portrait': settings.screenMode === 'PORTRAIT',
          })} dataTestId='recall-character-modal-recall-button'
        >Recall character</Button>
      </>
      : <MineBuildingModalSubtitle>You have no character NFTs to recall</MineBuildingModalSubtitle>}
  </Modal>
}
