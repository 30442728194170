import { Modal } from '../../../../../components/Modal/Modal'
import { FC } from 'react'
import { LandCard } from '../../../../../components/LandCard/LandCard'
import { MineBuildingModalSubtitle } from '../MineBuilding'
import { Button } from '../../../../../components/Button/Button'
import './CloseMineModal.scss'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'
import { NftsScrollableContainer } from '../../../../../components/NftsScrollableContainer/NftsScrollableContainer'
import { useCloseMineModal } from './useCloseMineModal'

type CloseMineModalProps = {
  /**
   * Closes modal
   */
  closeModal: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

export const CloseMineModal: FC<CloseMineModalProps> = props => {
  const closeMineModal = useCloseMineModal(props.user)

  return <Modal title='Close mine' onClickBackdropAndCloseButtons={props.closeModal}>
    {closeMineModal.minesWithoutCharacters.length > 0
      ? <>
        <NftsScrollableContainer>
          {closeMineModal.minesWithoutCharacters.map(item => {
            const landDisabled = closeMineModal.landDisabled(item.land)
            const landSelected = closeMineModal.landSelected(item.land)
            return (
              <LandCard
                onClick={() => closeMineModal.selectLandNftToCloseMineWith(item.land)}
                landAsset={item.land} disabled={landDisabled} dataTestId='close-mine-modal-land-card'
                selected={landSelected} key={item.land.asset_id}
              />
            )
          })}
        </NftsScrollableContainer>
        <Button
          onClick={() => closeMineModal.close(props.closeModal)}
          disabled={closeMineModal.closeMineButtonDisabled}
          className='close-mine-modal__button' dataTestId='close-mine-modal-close-mine-button'
        >Close mine</Button>
      </>
      : <MineBuildingModalSubtitle>You have no opened mines</MineBuildingModalSubtitle>}
  </Modal>
}
