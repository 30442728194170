import { FC } from 'react'
import { Modal } from '../../../../../components/Modal/Modal'
import { useSelector } from 'react-redux'
import { nftsSelector } from '../../../../../store/slices/nftsSlice'
import { LandCard } from '../../../../../components/LandCard/LandCard'
import { Button } from '../../../../../components/Button/Button'
import { MineBuildingModalSubtitle } from '../MineBuilding'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'
import { NftsScrollableContainer } from '../../../../../components/NftsScrollableContainer/NftsScrollableContainer'
import { ButtonsContainer } from '../../../../../components/ButtonsContainer/ButtonsContainer'
import { MineBuildingHook } from '../useMineBuilding'
import { useOpenMineModal } from './useOpenMineModal'
import { miningSelector } from '../../../../../store/slices/miningSlice'

const redirectToMarket = () => window.location.assign('https://wax.atomichub.io/market?collection_name=dungeonitems&order=desc&schema_name=lands&sort=created&state=1&symbol=WAX')

type BuildMineModalProps = {
  /**
   * Closes modal
   */
  closeModal: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Manages mine building business logic
   */
  mineBuilding: MineBuildingHook
}

export const OpenMineModal: FC<BuildMineModalProps> = props => {
  const nfts = useSelector(nftsSelector)
  const mining = useSelector(miningSelector)
  const openMineModal = useOpenMineModal(props.user)

  return (
    <Modal title='Build a mine' onClickBackdropAndCloseButtons={props.closeModal}>
      {nfts.landNfts.length > 0
        ? <>
          <MineBuildingModalSubtitle>
            Choose the land on which you wish to build your mine and extract valuable resources.
          </MineBuildingModalSubtitle>
          <NftsScrollableContainer>
            {nfts.landNfts.map(land => {
              const isSelected =
                openMineModal.selectedLandToOpenMineWith?.asset_id === land.asset_id
              return <LandCard
                key={land.asset_id} selected={isSelected}
                landAsset={land} onClick={() => openMineModal.selectLandToOpenMineWith(land)}
                dataTestId='open-mine-modal-land-card'
              />
            })}
          </NftsScrollableContainer>
          <ButtonsContainer>
            <Button
              onClick={redirectToMarket} appearance='secondary'
              dataTestId='open-mine-modal-buy-more-land-button'
            >Buy more land</Button>
            <Button
              onClick={() => {
                openMineModal.openNewMine(() => {
                  props.closeModal()
                  // If the user opens the FIRST mine then we show an animated video.
                  // Based on the field is updated the video is shown.
                  if (mining.mines.length === 0) {
                    props.mineBuilding.showBuildingAnimation()
                  }
                })
              }}
              disabled={openMineModal.openNewMineButtonDisabled} dataTestId='open-mine-modal-open-mine-button'
            >Build mine</Button>
          </ButtonsContainer>
        </>
        : <>
          <MineBuildingModalSubtitle>
            You have no land NFTs
          </MineBuildingModalSubtitle>
          <ButtonsContainer>
            <Button onClick={redirectToMarket} appearance='secondary'>Buy more land</Button>
          </ButtonsContainer>
        </>}
    </Modal>
  )
}
