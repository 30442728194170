import { sendRequestToDungeonWorldsApi } from './index'

/**
 * Data required to authenticate with the backend.
 * This is basically a signed transaction containing the action `wombatmaster:login`.
 */
export type AuthenticationData = {
  /**
   * The signed dummy transaction in hex encoded format
   */
  serializedTransaction: string
  /**
   * The signatures provided by the wallet
   */
  signatures: string[]
  /**
   * The public keys provided by the wallet
   */
  publicKeys: string[]
  /**
   * The account name on-chain that is being authenticated
   */
  accountName: string
}

/**
 * Authentication result from the backend
 */
export type AuthenticationResult = {
  code: 'SUCCESS' | 'DM_ACCOUNT_NOT_FOUND' | 'NOT_AUTHORIZED'
}

/**
 * Logins via backend with Scatter
 * @param data Data is used for authentication
 */
export const loginScatter = (data: AuthenticationData): Promise<AuthenticationResult> => {
  return sendRequestToDungeonWorldsApi('/public/login/scatter', 'POST', data)
    .then(res => res.json())
}

/**
 * Logins via backend with Wax Cloud Wallet
 * @param data Data is used for authentication
 */
export const loginWaxCloudWallet = (data: AuthenticationData): Promise<AuthenticationResult> => {
  return sendRequestToDungeonWorldsApi('/public/login/wax-cloud', 'POST', data)
    .then(res => res.json())
}

/**
 * Two supportable ways of authorization.
 * We allow only 1 chain (WAX) and 2 wallets: Scatter and Wax Cloud Wallet
 */
export type AuthenticationType = 'SCATTER' | 'WAX_CLOUD_WALLET'

/**
 * Represents data for a logged-in and fully registered user.
 */
export type RegisteredUserData = {
  /**
   * UUID of the user
   */
  uuid: string
  /**
   * When the account was created
   */
  createdAt: string
  /**
   * WAX account is connected to the Dungeon Worlds account
   */
  waxAccount: string
  /**
   * Authentication type was used for registration
   */
  authenticationType: AuthenticationType
}

/**
 * Returns user data if an account exists, otherwise returns nothing
 */
export const getUserData = (): Promise<RegisteredUserData | undefined> => {
  return sendRequestToDungeonWorldsApi('/account')
    .then(res => res.json())
    .then(res => {
      return {
        authenticationType: res.authenticationType,
        uuid: res.uuid,
        createdAt: res.createdAt,
        waxAccount: res.waxAccount,
      }
    })
}
