import { ComponentWrapper } from '../ComponentsView'
import { FC } from 'react'
import { LocationBanner } from '../../../components/LocationBanner/LocationBanner'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link LocationBannerComponent} component
 * for developers
 */
export const LocationBannerComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Location banner default'>
      <LocationBanner size='default'>Hello!</LocationBanner>
    </ComponentWrapper>
    <ComponentWrapper title='Location banner default disabled'>
      <LocationBanner size='default' disabled>Hello!</LocationBanner>
    </ComponentWrapper>
    <ComponentWrapper title='Location banner large'>
      <LocationBanner size='large'>Hello!</LocationBanner>
    </ComponentWrapper>
    <ComponentWrapper title='Location banner large disabled'>
      <LocationBanner size='large' disabled>Hello!</LocationBanner>
    </ComponentWrapper>
  </div>
}
