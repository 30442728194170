import { AtomicAsset } from '../../../../../../api/atomic/types/asset'
import { FC } from 'react'
import { ImageData } from '../ClaimableMaterials'
import { BUTTON_HEIGHT, BUTTON_WIDTH } from '../useClaimableMaterials'
import { useClaimMaterialButton } from './useClaimMaterialButton'
import './ClaimMaterialButton.scss'

type ButtonPosition = {
  /**
   * Offset by X for the button position
   */
  offsetX: number
  /**
   * Offset by Y for the button position
   */
  offsetY: number
}

type ClaimMaterialButtonProps = {
  /**
   * Land asset the material should be claimed for
   */
  landAsset: AtomicAsset
  /**
   * Image data for the material image
   */
  imageData: ImageData
  /**
   * Position of the button
   */
  buttonPosition: ButtonPosition
}

/**
 * Renders a claim material button with a pot inside based on the land asset material.
 */
export const ClaimMaterialButton: FC<ClaimMaterialButtonProps> = props => {
  const claimMaterialButton = useClaimMaterialButton(props.landAsset)

  return <button
    disabled={claimMaterialButton.buttonDisabled} className='claimable-materials__button'
    style={{
      position: 'absolute', top: `${props.buttonPosition.offsetX}px`,
      left: `${props.buttonPosition.offsetY}px`, width: `${BUTTON_WIDTH}px`,
      height: `${BUTTON_HEIGHT}px`, display: claimMaterialButton.buttonHidden ? 'none' : 'block'
    }} onClick={claimMaterialButton.claimMaterial} data-testid='claimable-material-button'
  >
    <img src={props.imageData.imageUrl} alt={props.imageData.altText} />
  </button>
}
