// The purpose of this file is to check if an ENV variable exists and can be used.
// If the variable does not exist then an error thrown and the application cannot be built.
// Because of that reason all ENV variables should be exported from here, only in this way
// you can be sure that the variable exists.

const WAX_NODE_URLS = process.env.WAX_NODE_URLS
const WAX_ATOMICHUB_API = process.env.WAX_ATOMICHUB_API
const DUNGEON_ITEMS_SMARTCONTRACT_NAME = process.env.DUNGEON_ITEMS_SMARTCONTRACT_NAME
const DUNGEON_WORLDS_SMARTCONTRACT_NAME = process.env.DUNGEON_WORLDS_SMARTCONTRACT_NAME
const COMPONENTS_PAGE_ACTIVE = process.env.COMPONENTS_PAGE_ACTIVE
const DUNGEON_WORLDS_API_SERVER = process.env.DUNGEON_WORLDS_API_SERVER
const DUNGEON_MASTER_URL = process.env.DUNGEON_MASTER_URL
const WOMBAT_TOKENS_SMARTCONTRACT_NAME = process.env.WOMBAT_TOKENS_SMARTCONTRACT_NAME
const WOMBAT_TOKEN_SYMBOL = process.env.WOMBAT_TOKEN_SYMBOL
const WOMBAT_CHAMPS_COLLECTION_NAME = process.env.WOMBAT_CHAMPS_COLLECTION_NAME
const WOMBAT_CHAMPS_SCHEMA = process.env.WOMBAT_CHAMPS_SCHEMA

/**
 * Returns an ENV variable if it exists, otherwise throws an error.
 * @param name Name of the ENV variable should be checked
 * @param value Value of the ENV variable should be checked
 */
export const getEnvVar = (name: string, value?: string) => {
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`)
  }
  return value
}

export const envVars = {
  WAX_NODE_URLS: getEnvVar('WAX_NODE_URLS', WAX_NODE_URLS),
  WAX_ATOMICHUB_API: getEnvVar('WAX_ATOMICHUB_API', WAX_ATOMICHUB_API),
  DUNGEON_ITEMS_SMARTCONTRACT_NAME: getEnvVar('DUNGEON_ITEMS_SMARTCONTRACT_NAME', DUNGEON_ITEMS_SMARTCONTRACT_NAME),
  DUNGEON_WORLDS_SMARTCONTRACT_NAME: getEnvVar('DUNGEON_WORLDS_SMARTCONTRACT_NAME', DUNGEON_WORLDS_SMARTCONTRACT_NAME),
  COMPONENTS_PAGE_ACTIVE: getEnvVar('COMPONENTS_PAGE_ACTIVE', COMPONENTS_PAGE_ACTIVE),
  DUNGEON_WORLDS_API_SERVER: getEnvVar('DUNGEON_WORLDS_API_SERVER', DUNGEON_WORLDS_API_SERVER),
  DUNGEON_MASTER_URL: getEnvVar('DUNGEON_MASTER_URL', DUNGEON_MASTER_URL),
  WOMBAT_TOKENS_SMARTCONTRACT_NAME: getEnvVar('WOMBAT_TOKENS_SMARTCONTRACT_NAME', WOMBAT_TOKENS_SMARTCONTRACT_NAME),
  WOMBAT_TOKEN_SYMBOL: getEnvVar('WOMBAT_TOKEN_SYMBOL', WOMBAT_TOKEN_SYMBOL),
  WOMBAT_CHAMPS_COLLECTION_NAME: getEnvVar('WOMBAT_CHAMPS_COLLECTION_NAME', WOMBAT_CHAMPS_COLLECTION_NAME),
  WOMBAT_CHAMPS_SCHEMA: getEnvVar('WOMBAT_CHAMPS_SCHEMA', WOMBAT_CHAMPS_SCHEMA),
}
