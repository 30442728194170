import { FC } from 'react'
import './ProgressBar.scss'
import classnames from 'classnames'

type ProgressBarProps = {
  /**
   * Progress value in percentage of anything.
   * For example: you have 40 in a range from 0 to 200. In this case should be passed 20.
   * 40 / 200 * 100 = 20.
   */
  progress: number
  /**
   * Class name for the component.
   * @default Empty string
   */
  className?: string
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress, className = '' }) => {
  return <div className={classnames('progress-bar', className)} data-testid='progress-bar'>
    <div
      className='progress-bar__current-position'
      style={{ width: `${progress}%` }} data-testid='progress-bar-current-position'
    />
  </div>
}
