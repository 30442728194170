import { FC, PropsWithChildren, useState } from 'react'
import './DojoBuilding.scss'
import DojoBuildingImageStatic from '../../../../assets/images/dojo-building.png'
import DojoBuildingImageAnimation from '../../../../assets/images/dojo-building-animation.png'
import { LocationBanner } from '../../../../components/LocationBanner/LocationBanner'
import { DojoModal } from './DojoModal/DojoModal'
import { RegisteredUserData } from '../../../../api/dungeon-worlds/user'
import classnames from 'classnames'
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner'
import { ActionsContainer } from '../ActionsContainer/ActionsContainer'
import { BuildingAction } from '../BuildingAction/BuildingAction'
import BenchPress from '../../../../assets/images/icons/bench-press.svg'
import Sensei from '../../../../assets/images/icons/sensei.svg'
import { DojoBuildingHook, useDojoBuilding } from './useDojoBuilding'
import { dojoSelector } from '../../../../store/slices/dojoSlice'
import { SenseiModal } from './SenseiModal/SenseiModal'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../../../store/slices/settingsSlice'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'

/**
 * The subtitle for modals in dojo that renders a text
 */
export const DojoBuildingModalSubtitle: FC<PropsWithChildren> = ({ children }) => {
  const { screenMode } = useSelector(settingsSelector)

  return <h3 className={classnames('dojo-building__modal-subtitle', {
    'dojo-building__modal-subtitle--landscape-wide': screenMode === 'LANDSCAPE_WIDE'
  })} data-testid='dojo-building-modal-subtitle'>{children}</h3>
}

/**
 * Features are in the dojo
 */
type DojoFeatures = 'TRAINING' | 'SENSEI'

type DojoBuildingActionsProps = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Hook for managing dojo functionality
   */
  dojoBuilding: DojoBuildingHook
}

/**
 * Listed buttons that are shown after click on the dojo building
 */
export const DojoBuildingActions: FC<DojoBuildingActionsProps> = props => {
  const dojo = useSelector(dojoSelector)

  const [openedModal, setOpenedModal] = useState<DojoFeatures>()

  const closeModal = () => setOpenedModal(undefined)

  const trainingButtonText = dojo.trainingSlots[0]?.characterAsset ? 'Character training' : 'Train character'
  const senseiButtonText = dojo.senseiSlots[0]?.senseiAsset ? 'Sensei inside' : 'Invite sensei'

  return (
    <ActionsContainer>
      <BuildingAction
        text={trainingButtonText}
        icon={<img src={BenchPress} alt='Golden bench press' />}
        onClick={() => setOpenedModal('TRAINING')}
        dataTestId='building-action-training'
      />
      <BuildingAction
        text={senseiButtonText}
        icon={<img src={Sensei} alt='Golden sensei' />}
        onClick={() => setOpenedModal('SENSEI')}
        dataTestId='building-action-sensei'
      />
      {openedModal === 'TRAINING' && <DojoModal
        closeModal={closeModal} user={props.user}
        dojoBuilding={props.dojoBuilding}
      />}
      {openedModal === 'SENSEI' && <SenseiModal
        closeModal={closeModal} user={props.user}
        senseiSlot={props.dojoBuilding.senseiSlot} afterRecallSensei={closeModal}
      />}
    </ActionsContainer>
  )
}

type DojoBuildingImageProps = {
  /**
   * Manages dojo building business logic
   */
  dojoBuilding: DojoBuildingHook
  /**
   * Class name of the component
   */
  className?: string
}

/**
 * The component decides which image of the dojo building should be rendered.
 * Can be a static one and an animated one.
 */
export const DojoBuildingImage: FC<DojoBuildingImageProps> = props => {
  if (props.dojoBuilding.animatedDojoBuildingShown) {
    return <img src={DojoBuildingImageAnimation} data-testid='dojo-building-image-animated'
      alt='Animated dojo building with beautiful transitions where you can train your character'
      className={classnames(props.className)}
    />
  }

  return <img src={DojoBuildingImageStatic} data-testid='dojo-building-image-static'
    alt='Dojo building where you can train your character' className={classnames(props.className)}
  />
}

type DojoBuildingProps = {
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Class name used in the component
   * @default empty string
   */
  className?: string
}

/**
 * Renders dojo building with all interaction elements
 */
export const DojoBuilding: FC<DojoBuildingProps> = ({ user, className = '' }) => {
  const dojoBuilding = useDojoBuilding(user)
  const dojoBuildingRef = useOutsideClick(dojoBuilding.closeActions)

  return (
    <div
      className={classnames('dojo-building__wrapper', className)}
      data-testid='dojo-building-wrapper' ref={dojoBuildingRef}
    >
      {dojoBuilding.actionsShown && (
        <DojoBuildingActions user={user} dojoBuilding={dojoBuilding} />
      )}
      <button
        className='dojo-building'
        onClick={dojoBuilding.toggleActions} data-testid='dojo-building-button'
      >
        {(dojoBuilding.gettingTrainingSlots || dojoBuilding.gettingSenseiSlots)
          ? <LoadingSpinner />
          : <>
            <LocationBanner className='dojo-building__location-banner'>Dojo</LocationBanner>
            <DojoBuildingImage dojoBuilding={dojoBuilding} />
          </>}
      </button>
    </div>
  )
}
