import { sendRequestToDungeonWorldsApi } from './index'

type Material = 'GOLD' | 'URANIUM' | 'IRON' | 'COAL'

/**
 * Data for claimed material
 */
export type ClaimedMaterialData = {
  /**
   * Type of the claimed material
   */
  material: Material
  /**
   * Amount of claimed material
   */
  materialAmount: number
}

/**
 * Claims mined materials for the user
 * @param landAssetId Land asset id the materials should be claimed for
 */
export const claimMinedMaterials = (landAssetId: string): Promise<ClaimedMaterialData> => {
  return sendRequestToDungeonWorldsApi('/mining/materials/claims', 'POST', { landAssetId })
    .then(res => res.json())
}
