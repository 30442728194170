import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { miningSelector } from '../../../../store/slices/miningSlice'

/**
 * Represents returned type of the {@link useMineBuilding} hook
 */
export type MineBuildingHook = ReturnType<typeof useMineBuilding>

/**
 * Hook contains the mining logic that interacts with the UI.
 */
export function useMineBuilding () {
  const mining = useSelector(miningSelector)

  // By actions means buttons that open modals for interaction with mining:
  // open a mine, send a character to a mine, recall a character from a mine, close a mine.
  // By default, those buttons are hidden and
  // for showing them the user has to click on the mining buildings
  const [actionsShown, setActionsShown] = useState(false)
  const [buildingAnimationShown, setBuildingAnimationShown] = useState(false)

  const toggleActions = () => setActionsShown(prev => !prev)
  const closeActions = () => setActionsShown(false)

  const gettingMines = mining.gettingMines === 'pending'

  // If the user has an opened mine then we show the constructed mine image.
  // Otherwise, we show "destroyed" mine building image.
  const constructedMineImageShown = mining.mines.length > 0

  /**
   * Shows the building animation by settings {@link buildingAnimationShown} to {true}
   */
  const showBuildingAnimation = () => setBuildingAnimationShown(true)

  /**
   * Subscribes to the change of the {@link buildingAnimationShown} field
   * and sets the field to `false` after some time of showing the animation.
   * This way allows us to show the building animation for some time and then hide it.
   */
  useEffect(() => {
    if (buildingAnimationShown) {
      const timeout = setTimeout(() => {
        setBuildingAnimationShown(false)
      }, 2000)

      return () => {
        clearTimeout(timeout)
        setBuildingAnimationShown(false)
      }
    }
  }, [buildingAnimationShown])

  return {
    /**
     * If the action buttons shown
     */
    actionsShown,
    /**
     * Toggles the state of the {@link actionsShown} values.
     * If it was `false` then switches it to `true` and the way around
     */
    toggleActions,
    /**
     * Closes the actions by setting the {@link actionsShown} state to `false`.
     */
    closeActions,
    /**
     * If getting mines request is being pended
     */
    gettingMines,
    /**
     * If the constructed mine image shown.
     * If the user has at least 1 opened mine then we show the constructed image,
     * otherwise we show an image of the "destroyed" dungeon
     */
    constructedMineImageShown,
    /**
     * If the building animation with pickaxes is shown.
     * It is a simple video which shown after the FIRST opened mine
     */
    buildingAnimationShown,
    showBuildingAnimation,
  }
}
