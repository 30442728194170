import { useEffect } from 'react'
import { updateNow } from '../store/slices/settingsSlice'
import { useDispatch } from 'react-redux'

/**
 * Updates the `now` field every second in redux that has the current time in there.
 * Also, sets the initial value to the field.
 */
export function useNowTime() {
  const dispatch = useDispatch()

  // Changes the current time in redux store every second
  useEffect(() => {
    // Updates the current time in the store every second
    const interval = setInterval(() => {
      dispatch(updateNow(Date.now()))
    }, 1000)
    return () => clearInterval(interval)
  }, [])
}
