import { useEffect, useState } from 'react'

/**
 * Checks if the current screen size suits for the input media query.
 * If it suits then it returns true, otherwise false.
 * @param query The query of the screen is checked by. Example of a query: `(min-width: 768px)` or
 * `(min-width: 768px) and (max-width: 1024px)` if you want to use two breakpoints
 */
export function useMediaQuery(query: string): boolean {
  function getMatches (query: string) {
    return window.matchMedia(query).matches
  }

  const [matches, setMatches] = useState(getMatches(query))

  function handleChange() {
    setMatches(getMatches(query))
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    // Listen matchMedia
    matchMedia.addEventListener('change', handleChange)

    return () => {
      matchMedia.removeEventListener('change', handleChange)
    }
  }, [query])

  // If the input query matches to the current screen size
  return matches
}
