import { FC } from 'react'
import BoilingPot from '../../assets/images/boiling-pot-animation.png'
import classnames from 'classnames'
import './LoadingSpinner.scss'

type LoadingSpinnerProps = {
  /**
   * Class name for the component
   * @default undefined
   */
  className?: string
}

/**
 * Renders an animated boiling pot.
 * Should be used during a request pending for showing that something is being loaded.
 */
export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className }) => {
  return <img src={BoilingPot} alt='Animated boiling pot' data-testid='loading-spinner'
    className={classnames('loading-spinner', className)} />
}
