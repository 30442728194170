import { Api, ApiInterfaces, JsonRpc } from 'eosjs'
import { WaxJS } from '@waxio/waxjs/dist'
import { GetTableRowsResult } from 'eosjs/dist/eosjs-rpc-interfaces'
import { RegisteredUserData } from './api/dungeon-worlds/user'
import { envVars } from './services/envVariables'

const WAX_CHAIN_ID = '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4'
const WAX_NODE_URLS = envVars.WAX_NODE_URLS.split(',')
const FIRST_WAX_NODE_URL = WAX_NODE_URLS[0]

export type WaxAccountData = {
  /**
   * Wax account name
   */
  accountName: string
  /**
   * Public keys of an account
   */
  publicKeys: string[]
}

/**
 * Returns account data is taken from Scatter
 */
export function getScatterAccount(): Promise<WaxAccountData> {
  if (!window.scatter) {
    throw new Error('Scatter not available')
  }
  return window.scatter.getIdentity({ accounts: [ { chainId: WAX_CHAIN_ID } ] })
    .then(identity => ({
      accountName: identity.name,
      publicKeys: [identity.publicKey]
    }))
}

/**
 * Executes a transaction via Scatter
 * @param tx Transaction should be executed
 * @param options Transaction options
 */
export function scatterTransact(
  tx: ApiInterfaces.Transaction, options: ApiInterfaces.TransactConfig = {},
) {
  if (!window.scatter) {
    throw new Error('Scatter not available')
  }

  const executor = window.scatter.eos(
    { chainId: WAX_CHAIN_ID }, Api, { rpc: new JsonRpc(FIRST_WAX_NODE_URL) }
  )
  return executor.transact(tx, { blocksBehind: 3, expireSeconds: 30, ...options })
}

const waxJs = new WaxJS({
  rpcEndpoint: FIRST_WAX_NODE_URL,
  freeBandwidth: true,
  tryAutoLogin: false
})

/**
 * Returns account data is taken from Wax Cloud Wallet
 */
export async function getWaxCloudWalletAccount(): Promise<WaxAccountData> {
  await waxJs.login()
  return {
    accountName: waxJs.userAccount,
    publicKeys: waxJs.pubKeys
  }
}

/**
 * Executes a transaction via Wax Cloud Wallet
 * @param tx Transaction should be executed
 * @param options Transaction options
 */
export function waxCloudWalletTransact(
  tx: ApiInterfaces.Transaction, options: ApiInterfaces.TransactConfig = {},
) {
  return waxJs.api.transact(tx, { blocksBehind: 3, expireSeconds: 30, ...options })
}

/**
 * Executes a transaction based on the user's authentication type.
 * Can be executed via scatter or wax cloud wallet
 * @param tx Transaction should be executed
 * @param user User data. Based on that the wallet for transaction execution is chosen.
 * @param options Transaction options
 */
export function transact(
  tx: ApiInterfaces.Transaction, user: RegisteredUserData,
  options: ApiInterfaces.TransactConfig = {},
) {
  if (user.authenticationType === 'SCATTER') {
    return scatterTransact(tx, options)
  }

  return waxCloudWalletTransact(tx, options)
}

/**
 * Calls an RPC request and retries it if the node is used for the RPC is not responding
 * @param fn RPC request should be executed
 */
const callRpcRequestWithRetrying = async <T>(fn: (rpc: JsonRpc) => Promise<T>): Promise<T> => {
  const nodesCount = WAX_NODE_URLS.length
  const maxAttempts = nodesCount * 2 // 2 retries for each configured node
  let currentAttempt = 0
  // By default, takes the first node in the nodes list
  let currentRpc = new JsonRpc(WAX_NODE_URLS[0])

  while (currentAttempt < maxAttempts) {
    const currentNode = WAX_NODE_URLS[currentAttempt % nodesCount]
    currentRpc = new JsonRpc(currentNode)
    try {
      return await fn(currentRpc)
    } catch (error) {
      currentAttempt += 1
      await new Promise(resolve => setTimeout(resolve, 20))
    }
  }

  return await fn(currentRpc)
}

/**
 * Get tables rows from a smart contract with retry functionality which means
 * if one node fails another one is used.
 * @param code The name of the smart contract to read data from
 * @param table The name of the table to read data from
 * @param scope Scope on the table to read data from
 * @param limit Limit of entries to read
 * @param lowerBound Lower bound for the key
 * @param upperBound Upper bound for the key
 */
export const getTableRows = async (
  code: string, table: string, scope: string,
  limit: number, lowerBound?: string, upperBound?: string,
): Promise<GetTableRowsResult> => {
  return callRpcRequestWithRetrying((rpc: JsonRpc) => {
    return rpc.get_table_rows({
      code: code,
      table: table,
      // Due to a limitation in the EOS RPC if the scope contains only digits it will be
      // interpreted as the unit64 representation of a valid EOS name. Adding `space`  at the
      // beginning is the `quick` solution because it forces the value to be interpreted as a
      // `string`. see more  https://github.com/EOSIO/eos/issues/5616
      scope: ` ${scope}`,
      lower_bound: lowerBound,
      upper_bound: upperBound,
      json: true,
      limit: limit,
    })
  })
}

/**
 * Loads the balance of a token on some smart contract (following the `eosio.token` standard)
 * @param code The smart contract of the token
 * @param account The account to load the balance for
 * @param symbol The symbol of the token to load the balance for
 * @return If the user has no balance then empty array is returned,
 * otherwise an array with value is returned
 */
export const getCurrencyBalance = (
  code: string, account: string, symbol: string,
): Promise<string[]> => {
  return callRpcRequestWithRetrying((rpc: JsonRpc) => (
    rpc.get_currency_balance(code, account, symbol)
  ))
}
