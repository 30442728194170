import { FC } from 'react'
import { Modal } from '../../../../../components/Modal/Modal'
import { SenseiSlot } from '../../../../../store/slices/dojoSlice'
import { StakeableSenseis } from './StakeableSenseis/StakeableSenseis'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'
import { StakedSensei } from './StakedSensei/StakedSensei'

type SenseiModalProps = {
  /**
   * Closes the modal
   */
  closeModal: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Function is called after recalling a sensei
   * @default undefined
   */
  afterRecallSensei?: () => void
  /**
   * Sensei slot in Dojo
   * @default undefined
   */
  senseiSlot?: SenseiSlot
}

export const SenseiModal: FC<SenseiModalProps> = props => {
  return (
    <Modal onClickBackdropAndCloseButtons={props.closeModal} title='Dojo'>
      {props.senseiSlot && props.senseiSlot.senseiAsset
        ? <StakedSensei user={props.user} slotWithAsset={{
          slot: props.senseiSlot.slot, senseiAsset: props.senseiSlot.senseiAsset
        }} afterRecallSensei={props.afterRecallSensei} />
        : <StakeableSenseis user={props.user} />}
    </Modal>
  )
}
