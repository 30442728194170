import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../types'

/**
 * All possible screen mods. Changes based on the current screen width.
 * PORTRAIT is a screen with width less or equal to 480px.
 * LANDSCAPE is a screen with width greater than 480px and less or equal to 1024px.
 * LANDSCAPE_WIDE is a screen with width greater than 1024px.
 */
type ScreenMode = 'PORTRAIT' | 'LANDSCAPE' | 'LANDSCAPE_WIDE'

type InitialSettingsState = {
  /**
   * The current time in unix ms timestamp. Updates every second
   */
  now: number
  /**
   * Current screen mode. Calculates based on the current screen width
   */
  screenMode?: ScreenMode
}

/**
 * Initial state of the {@link settingsSlice}
 */
export const settingsInitialState: InitialSettingsState = {
  now: Date.now(),
  screenMode: undefined
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsInitialState,
  reducers: {
    /**
     * Updates screen mode
     * @param state State of the {@link settingsSlice}
     * @param action New screen mode
     */
    updateScreenMode: (
      state, action: PayloadAction<ScreenMode>
    ) => {
      state.screenMode = action.payload
    },
    /**
     * Updates the now field
     * @param state State of the {@link settingsSlice}
     * @param action New timestamp in ms
     */
    updateNow: (state, action: PayloadAction<number>) => {
      state.now = action.payload
    },
  },
})

export const {
  updateScreenMode, updateNow,
} = settingsSlice.actions

export const settingsSelector = (state: RootState) => state.settings

export const settingsReducer = settingsSlice.reducer
