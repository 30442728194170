import { useAppDispatch } from '../../../../../store/types'
import { useSelector } from 'react-redux'
import { miningSelector, sendCharacterToMine } from '../../../../../store/slices/miningSlice'
import { useState } from 'react'
import { AtomicAsset } from '../../../../../api/atomic/types/asset'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'

/**
 * Represents returned type of the {@link useSendCharacterToMineModal} hook
 */
export type UseSendCharacterToMineModalHook = ReturnType<typeof useSendCharacterToMineModal>

/**
 * Manages business logic for sending a character to a mine
 * @param user Registered user data
 */
export function useSendCharacterToMineModal(user: RegisteredUserData) {
  const dispatch = useAppDispatch()
  const mining = useSelector(miningSelector)

  const [selectedLandNft, setSelectedLandNft] = useState<AtomicAsset>()
  const [selectedCharacterNft, setSelectedCharacterNft] = useState<AtomicAsset>()

  /**
   * Resets selected land NFT state by setting {@link selectedCharacterNft} to undefined
   */
  const resetSelectLandNft = () => setSelectedLandNft(undefined)

  /**
   * Checks if a given character NFT is already selected to be sent to a mine
   * @param character Character NFT should be checked if it is selected
   */
  function ifCharacterSelectedToBeSent(character: AtomicAsset) {
    return selectedCharacterNft?.asset_id === character.asset_id
  }

  /**
   * Saves selected character NFT to the state. If an NFT is already selected then
   * overwrites this NFT. If the same NFT is already selected then removes this
   * NFT from the state.
   * @param character Character NFT should be selected to be sent to a mine
   */
  function selectCharacterNft(character: AtomicAsset) {
    setSelectedCharacterNft(() => {
      return ifCharacterSelectedToBeSent(character) ? undefined : character
    })
  }

  /**
   * Sends a character NFT to a mine if {@link selectedCharacterNft} is selected
   * @param afterSending Callback is called after sending a character NFT
   */
  async function sendCharacter (afterSending: () => void) {
    if (selectedCharacterNft && selectedLandNft) {
      await dispatch(sendCharacterToMine({
        character: selectedCharacterNft, land: selectedLandNft, user: user,
      }))
      afterSending()
    }
  }

  const oneCharacterIsBeingSentToMine = Object.values(mining.sendingCharacterToMine).some(item => item === 'pending')

  const sendCharacterButtonDisabled = !selectedCharacterNft || oneCharacterIsBeingSentToMine

  const minesWithoutCharacters = mining.mines.filter(mine => !mine.character)

  return {
    /**
     * Selected land NFT of a mine a character NFT should be sent to.
     * If no land NFT is selected then the value is `undefined`
     */
    selectedLandNft,
    /**
     * Selects a land NFT by updating the {@link selectedLandNft} state
     */
    setSelectedLandNft,
    /**
     * Selected character NFT to be sent to a mine.
     * If no character NFT is selected then the value is `undefined`
     */
    selectedCharacterNft,
    /**
     * Indicates if the button for sending a character to a mine is disabled
     */
    sendCharacterButtonDisabled,
    /**
     * Indicates if at least one character is being sent to a mine
     */
    oneCharacterIsBeingSentToMine,
    /**
     * Mines without characters only. Mines with characters are filtered out
     */
    minesWithoutCharacters,
    resetSelectLandNft,
    ifCharacterSelectedToBeSent,
    selectCharacterNft,
    sendCharacter,
  }
}
