import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { NftsScrollableContainer } from '../../../components/NftsScrollableContainer/NftsScrollableContainer'
import { SenseiCard } from '../../../components/SenseiCard/SenseiCard'
import { getMockAsset } from '../../../../test/mocks/asset'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link NftsScrollableContainer} component
 * for developers
 */
export const NftsAScrollableContainerComponent: FC = () => {
  const mockAsset = getMockAsset()
  mockAsset.data.img = 'QmRED3qS1c6M9yeZhnqRJ82WWH63k4uvJT94q6McJvE236'

  return <div>
    <ComponentWrapper title='The container adds gap between cards and adds the possibility the scroll the container by dragging'>
      <div style={{ maxWidth: '500px' }}>
        <NftsScrollableContainer>
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
          <SenseiCard asset={mockAsset} />
        </NftsScrollableContainer>
      </div>
    </ComponentWrapper>
  </div>
}
