import { FC } from 'react'
import './LandWithCharacterCard.scss'
import { AtomicAsset } from '../../api/atomic/types/asset'
import { RoundedFrame } from '../RoundedFrame/RoundedFrame'
import classnames from 'classnames'
import { LandCard } from '../LandCard/LandCard'
import { CharacterCard } from '../CharacterCard/CharacterCard'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../store/slices/settingsSlice'

type LandWithCharacterCardProps = {
  /**
   * Land asset shown in the card
   */
  landAsset: AtomicAsset
  /**
   * Character asset shown in the card
   */
  characterAsset: AtomicAsset
  /**
   * If the card is selected
   */
  selected?: boolean
  /**
   * If the card is disabled
   */
  disabled?: boolean
  /**
   * Function is called by click on the card
   */
  onClick?: () => void
  /**
   * Class name for the component
   */
  className?: string
  /**
   * Data test ID used by Jest
   */
  dataTestId?: string
}

export const LandWithCharacterCard: FC<LandWithCharacterCardProps> = ({
  landAsset, characterAsset, selected, disabled,
  onClick, className = '', dataTestId = '',
}) => {
  const settings = useSelector(settingsSelector)

  // Needed because a user can change 'disable' attribute by manually in devtools
  // and call the function from the `onClick` prop.
  // For greater security, we can programmatically check this.
  const onInternalClick = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  return <button
    className={classnames('land-with-character-card', className, {
      'land-with-character-card--selected': selected,
      'land-with-character-card--landscape-wide-mode': settings.screenMode === 'LANDSCAPE_WIDE'
    })}
    onClick={onInternalClick} disabled={disabled} data-testid={dataTestId}
  >
    <RoundedFrame className='land-with-character-card__rounded-frame'>
      <LandCard
        landAsset={landAsset} roundedFrameHidden dataTestId='land-card-from-land-with-character-card'
        className='land-with-character-card__land-card' buildingImageShown landInfoShort
      />
      {/* If the land with character card selected we show it only in the character card */}
      <CharacterCard
        characterAsset={characterAsset} selected={selected} roundedFrameHidden
        dataTestId='character-card-from-land-with-character-card'
        className='land-with-character-card__character-card'
      />
    </RoundedFrame>
  </button>
}
