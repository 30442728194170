import { FC, PropsWithChildren } from 'react'
import './ActionsContainer.scss'

/**
 * A wrapper for building actions that renders them in a row and
 * positions them at the top of the building
 */
export const ActionsContainer: FC<PropsWithChildren> = props => {
  return <div className='building-actions__container' data-testid='building-actions-container'>
    {props.children}
  </div>
}
