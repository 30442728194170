import { FC, MutableRefObject, PropsWithChildren, useRef } from 'react'
import { useDraggable } from 'react-use-draggable-scroll'
import './NftsScrollableContainer.scss'

/**
 * Renders NFTs in a row with scroll when it is needed
 * @param children NFTs should be rendered
 */
export const NftsScrollableContainer: FC<PropsWithChildren> = ({ children }) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  // This hook should get the first argument as the `MutableRefObject` type
  const { events } = useDraggable(scrollableContainerRef as MutableRefObject<HTMLDivElement>)

  return (
    <div className='nfts-scrollable-container' ref={scrollableContainerRef} {...events}>
      <div className='nfts-scrollable-container__list'>{children}</div>
    </div>
  )
}
