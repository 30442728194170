import { FC } from 'react'
import classnames from 'classnames'
import { ProgressBar } from '../../../../../../components/ProgressBar/ProgressBar'
import './StakedCharacterInDojoProgressXp.scss'

type StakedCharacterInDojoProgressXpProps = {
  /**
   * Title of the progress XP. Can be progress like `0/10` or
   * can be a label witch indicates that XP enough for leveling up
   */
  title: string
  /**
   * Current XP progress in percent from 0 to 100
   */
  progressValue: number
  /**
   * Class name for the component
   */
  className?: string
}

/**
 * Renders the current XP progress of the staked character asset in dojo
 */
export const StakedCharacterInDojoProgressXp: FC<StakedCharacterInDojoProgressXpProps> = ({
  title, progressValue, className
}) => {
  return <div className={classnames('staked-character-in-dojo__progress-xp', className)}
    data-testid='staked-character-in-dojo-progress-xp'
  >
    <div className='staked-character-in-dojo__progress-xp-title'>{title}</div>
    <ProgressBar progress={progressValue} />
  </div>
}
