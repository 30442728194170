import { FC } from 'react'
import { useAppDispatch } from '../../store/types'
import { login, userSelector } from '../../store/slices/userSlice'
import { Container } from '../../components/Container/Container'
import DungeonWorldsLogo from '../../assets/images/dungeon-worlds-logo.png'
import WombatToken from '../../assets/images/icons/wombat-token.svg'
import WaxCloudWallet from '../../assets/images/icons/wax-cloud-wallet.png'
import { Button } from '../../components/Button/Button'
import { useSelector } from 'react-redux'

import './LoginView.scss'
import { RoundedFrame } from '../../components/RoundedFrame/RoundedFrame'
import { RectangleFrame } from '../../components/RectangleFrame/RectangleFrame'

export const LoginView: FC = () => {
  const dispatch = useAppDispatch()

  const user = useSelector(userSelector)

  const loggingIn = user.loggingIn === 'pending'

  return <div className='login-view'>
    <RoundedFrame className='login-view__rounded-frame' hideAdditionalBorders>
      <RectangleFrame className='login-view__rectangle-frame'>
        <Container className='login-view__container'>
          <img
            src={DungeonWorldsLogo} className='login-view__logo'
            alt='Wombat in a cart with a pickaxe inside a beautiful frame'
          />
          <h2 className='login-view__title'>Choose a wallet to continue</h2>
          <div className='login-view__buttons'>
            <Button
              disabled={loggingIn} appearance='tertiary' dataTestId='scatter-wallet-button'
              icon={<img src={WombatToken} alt='Orange wombat in an orange circle' />}
              onClick={() => dispatch(login({ wallet: 'SCATTER' }))}
            >WOMBAT WALLET</Button>
            <Button
              disabled={loggingIn} appearance='tertiary' dataTestId='wax-cloud-wallet-button'
              icon={<img src={WaxCloudWallet} alt='Wax Cloud Wallet logo' />}
              onClick={() => dispatch(login({ wallet: 'WAX_CLOUD_WALLET' }))}
            >WAX CLOUD WALLET</Button>
          </div>
        </Container>
      </RectangleFrame>
    </RoundedFrame>
  </div>
}
