import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../types'
import { ToastSize, ToastType } from '../../components/Toast/Toast'

export type ToastStructure = {
  /**
   * Type of the toast
   */
  type?: ToastType
  /**
   * Size of the toast
   */
  size?: ToastSize
  /**
   * Text of the toast
   */
  text: string
  /**
   * Toast ID
   */
  id: number
}

type InitialToastState = {
  /**
   * List of all toast are shown at the moment.
   * A component reacts to changes in this array and when you add
   * a new item in the array the component renders a new toast
   */
  toasts: ToastStructure[]
}

/**
 * Initial state of the {@link toastSlice}
 */
const toastInitialState: InitialToastState = {
  toasts: []
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState: toastInitialState,
  reducers: {
    /**
     * Adds a new toast into the toasts list
     * @param state State of the {@link toastSlice}
     * @param action New toast
     */
    addToast: (
      state, action: PayloadAction<Omit<ToastStructure, 'id'>>
    ) => {
      const newToast: ToastStructure = {
        ...action.payload,
        id: Date.now()
      }
      const updatedToasts = [...state.toasts]
      updatedToasts.push(newToast)
      state.toasts = updatedToasts
    },
    /**
     * Removes toast from the toasts list
     * @param state State of the {@link toastSlice}
     * @param action Toast ID
     */
    removeToast: (
      state, action: PayloadAction<number>
    ) => {
      const updatedToasts = [...state.toasts]
      state.toasts = updatedToasts.filter(toast => toast.id !== action.payload)
    },
  },
})

export const { addToast, removeToast } = toastSlice.actions

export const toastSelector = (state: RootState) => state.toast

export const toastReducer = toastSlice.reducer
