import { Dialog } from '../../../../../../components/Dialog/Dialog'
import { FC, useState } from 'react'
import './LevelUpConfirmationDialog.scss'
import { RectangleFrame } from '../../../../../../components/RectangleFrame/RectangleFrame'
import { CharacterCard } from '../../../../../../components/CharacterCard/CharacterCard'
import { Button } from '../../../../../../components/Button/Button'
import { LevelUpSuccessDialog } from '../LevelUpSuccessDialog/LevelUpSuccessDialog'
import { useSelector } from 'react-redux'
import { dojoSelector, levelUpCharacterAsset, TrainingSlotWithAsset } from '../../../../../../store/slices/dojoSlice'
import { useAppDispatch } from '../../../../../../store/types'
import WombatToken from '../../../../../../assets/images/icons/wombat-token.svg'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'
import { settingsSelector } from '../../../../../../store/slices/settingsSlice'
import classnames from 'classnames'
import { StakedCharacterInDojoProgressXp } from '../StakedCharacterInDojoProgressXp/StakedCharacterInDojoProgressXp'

type LevelUpCharacterConfirmationDialogProps = {
  /**
   * Closes dialog
   */
  closeDialog: () => void
  /**
   * The training slot staked in Dojo with a character asset in there
   */
  trainingSlot: TrainingSlotWithAsset
  /**
   * Function is called on closing the success leveling up dialog
   */
  onCloseSuccessDialog: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Amount of tokens for leveling up.
   * If passed the price is shown in the component
   * @default undefined
   */
  amountOfTokensForLevelingUp?: number
}

/**
 * Component for confirming leveling a character asset up.
 * After successful leveling up a success dialog shown.
 */
export const LevelUpCharacterConfirmationDialog: FC<
  LevelUpCharacterConfirmationDialogProps
> = props => {
  const dispatch = useAppDispatch()
  const dojo = useSelector(dojoSelector)
  const settings = useSelector(settingsSelector)

  const [successDialogShown, setSuccessDialogShown] = useState(false)

  const levelUp = async () => {
    await dispatch(levelUpCharacterAsset({ character: props.trainingSlot, user: props.user }))
    setSuccessDialogShown(true)
  }
  const levelUpButtonDisabled = dojo.levelingUpCharacterAssets[props.trainingSlot.characterAsset.asset_id] === 'pending'

  const closeSuccessDialog = () => {
    props.onCloseSuccessDialog()
    setSuccessDialogShown(false)
  }

  return <>
    <Dialog onBackdropClick={props.closeDialog} className={classnames('level-up-character-confirmation-dialog', {
      'level-up-character-confirmation-dialog--landscape-wide': settings.screenMode === 'LANDSCAPE_WIDE',
    })}>
      <RectangleFrame className='level-up-character-confirmation-dialog__frame'>
        <h4 className='level-up-character-confirmation-dialog__title'>Level this character up?</h4>
        <CharacterCard
          characterAsset={props.trainingSlot.characterAsset} powerDataHidden
          className='level-up-character-confirmation-dialog__card'
        />
        <StakedCharacterInDojoProgressXp title='Ready for level up!' progressValue={100} />
        {props.amountOfTokensForLevelingUp && <div className='level-up-character-confirmation-dialog__wombat-price'>
          <img src={WombatToken} className='level-up-character-confirmation-dialog__wombat-price-media' alt='Wombat token'/>
          {props.amountOfTokensForLevelingUp}
        </div>}
      </RectangleFrame>
      <div className='level-up-character-confirmation-dialog__buttons'>
        <Button
          onClick={props.closeDialog} appearance='secondary'
          dataTestId='level-up-character-confirmation-dialog-cancel-button'
        >NO</Button>
        <Button
          onClick={levelUp} disabled={levelUpButtonDisabled}
          dataTestId='level-up-character-confirmation-dialog-confirm-button'
        >YES</Button>
      </div>
    </Dialog>
    {successDialogShown && (
      <LevelUpSuccessDialog
        closeDialog={closeSuccessDialog}
        characterAsset={props.trainingSlot.characterAsset}
      />
    )}
  </>
}
