import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AsyncThunkLoading, RootState } from '../types'
import { AtomicAsset } from '../../api/atomic/types/asset'
import { getTableRows, transact } from '../../wax'
import { addCharacterNft, addLandNft, removeCharacterNft, removeLandNft } from './nftsSlice'
import { getAssets } from '../../api/atomic'
import { RegisteredUserData } from '../../api/dungeon-worlds/user'
import { claimMinedMaterials } from '../../api/dungeon-worlds/mining'
import { addToast } from './toastSlice'
import { envVars } from '../../services/envVariables'

const DUNGEON_WORLDS_SMARTCONTRACT_NAME = envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME

export type Mine = {
  /**
   * Land asset in the mine
   */
  land: AtomicAsset
  /**
   * Character asset in the mine. Also, can be `null` if no character asset assigned to mine
   */
  character: AtomicAsset | null
}

type InitialMiningState = {
  /**
   * List of all mines the user has
   */
  mines: Mine[]
  /**
   * Status of getting mines thunk
   */
  gettingMines: AsyncThunkLoading
  /**
   * Status of opening a mine thunk.
   * String in the record means `asset_id` of the asset used for opening a mine
   */
  openingMine: Record<string, AsyncThunkLoading>
  /**
   * Status of sending a character to a mine.
   * String in the record means `asset_id` of a land NFT to which a character NFT is being sent
   */
  sendingCharacterToMine: Record<string, AsyncThunkLoading>
  /**
   * Status of recalling a character asset from a mine.
   * String in the record means `asset_id` of a land NFT to which a character NFT is being recalled
   */
  recallingCharacterFromMine: Record<string, AsyncThunkLoading>
  /**
   * Status of closing a mine.
   * String in the record means `asset_id` of a land NFT to which the mine is being closed
   */
  closingMine: Record<string, AsyncThunkLoading>
  /**
   * Status of claiming materials.
   * String in the record means `asset_id` of a land NFT to which materials are being claimed
   */
  claimingMaterials: Record<string, AsyncThunkLoading>
}

/**
 * Initial state of the {@link miningSlice}
 */
export const miningInitialState: InitialMiningState = {
  mines: [],
  gettingMines: 'idle',
  openingMine: {},
  sendingCharacterToMine: {},
  recallingCharacterFromMine: {},
  closingMine: {},
  claimingMaterials: {},
}

type MineFromSmartContract = {
  /**
   * Asset ID of a land NFT
   */
  land: string
  /**
   * Asset ID of a character NFT.
   * Can be `0` which means a character NFT should be still added to the mine
   */
  character: string | 0
}

const getMinesFromSmartContract = async (
  userWaxAccount: string
): Promise<MineFromSmartContract[]> => {
  const result = await getTableRows(DUNGEON_WORLDS_SMARTCONTRACT_NAME, 'mines', userWaxAccount, 25)
  return result.rows
}

/**
 * Gets all mines the user has
 */
export const getMines = createAsyncThunk<
  Mine[],
  { waxAccountName: string }
>('mining/getMines', async ({ waxAccountName }) => {
  const minesFromSmartContract = await getMinesFromSmartContract(waxAccountName)

  // Checks if the user has at least one mine and
  // only in this case requests assets from atomic API
  if (minesFromSmartContract.length > 0) {
    const landAndCharacterIds: string[] = []
    minesFromSmartContract.forEach(mine => {
      landAndCharacterIds.push(mine.land)
      if (mine.character !== 0) {
        landAndCharacterIds.push(mine.character)
      }
    })

    const landAndCharacterAssets = await getAssets({ ids: landAndCharacterIds })

    const mines: Mine[] = []
    minesFromSmartContract.forEach(item => {
      const landAsset = landAndCharacterAssets.data.find(asset => asset.asset_id === item.land)
      if (landAsset) {
        const mine: Mine = { land: landAsset, character: null }

        const characterAsset = landAndCharacterAssets.data
          .find(character => character.asset_id === item.character)

        if (characterAsset) {
          mine.character = characterAsset
        }

        mines.push(mine)
      }
    })

    return mines
  }

  return []
})

/**
 * Opens a mine with a land NFT.
 * Means creating a new mine by transferring a land NFT.
 * Although, for starting mine a character NFT should be still connected to the mine.
 */
export const openMine = createAsyncThunk<
  unknown,
  { land: AtomicAsset, user: RegisteredUserData }
>('mining/openMine', async (
  { land, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: 'atomicassets',
      name: 'transfer',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        from: user.waxAccount,
        to: DUNGEON_WORLDS_SMARTCONTRACT_NAME,
        asset_ids: [land.asset_id],
        memo: 'openmine'
      },
    }]
  }
  await transact(transaction, user)
  dispatch(removeLandNft(land.asset_id))
  dispatch(addToast({ text: 'You have successfully built a mine!' }))
})

/**
 * Sends a character asset to a mine
 */
export const sendCharacterToMine = createAsyncThunk<
  unknown,
  { land: AtomicAsset, character: AtomicAsset, user: RegisteredUserData }
>('mining/sendCharacterToMine', async (
  { land, character, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: 'atomicassets',
      name: 'transfer',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        from: user.waxAccount,
        to: DUNGEON_WORLDS_SMARTCONTRACT_NAME,
        asset_ids: [character.asset_id],
        memo: `sendtomine:${land.asset_id}`
      },
    }]
  }
  await transact(transaction, user)
  dispatch(removeCharacterNft(character.asset_id))
  dispatch(addToast({ text: 'Your character has been sent to mine!' }))
})

/**
 * Recalls a character asset from a mine
 */
export const recallCharacterFromMine = createAsyncThunk<
  unknown,
  { land: AtomicAsset, character: AtomicAsset, user: RegisteredUserData }
>('mining/recallCharacterFromMine', async (
  { land, character, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: DUNGEON_WORLDS_SMARTCONTRACT_NAME,
      name: 'minerecall',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        owner: user.waxAccount,
        mine_id: land.asset_id,
      },
    }]
  }
  await transact(transaction, user)
  dispatch(addCharacterNft(character))
  dispatch(addToast({ text: 'Your character has been recalled!' }))
})

/**
 * Closes a mine
 */
export const closeMine = createAsyncThunk<
  unknown,
  { land: AtomicAsset, user: RegisteredUserData }
>('mining/closeMine', async (
  { land, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: DUNGEON_WORLDS_SMARTCONTRACT_NAME,
      name: 'mineclose',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        owner: user.waxAccount,
        mine_id: land.asset_id,
      },
    }]
  }
  // Claim materials before closing a mine
  await dispatch(claimMaterials({ landAssetId: land.asset_id }))
  await transact(transaction, user)
  dispatch(addLandNft(land))
  dispatch(addToast({ text: 'Your mine has been closed!' }))
})

/**
 * Claims mined materials
 */
export const claimMaterials = createAsyncThunk<
  unknown,
  { landAssetId: string }
>('mining/claimMaterials', async (
  { landAssetId },
  { dispatch }) => {
  const result = await claimMinedMaterials(landAssetId)
  const toastText = `You got ${result.materialAmount} of ${result.material.toLowerCase()}`
  dispatch(addToast({ text: toastText }))
})

export const miningSlice = createSlice({
  name: 'mining',
  initialState: miningInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMines.pending, state => {
        state.gettingMines = 'pending'
      })
      .addCase(getMines.fulfilled, (state, action) => {
        state.gettingMines = 'succeeded'
        state.mines = action.payload
      })
      .addCase(getMines.rejected, (state, action) => {
        state.gettingMines = 'failed'
        console.error('Error getting mines', action.error)
      })
      .addCase(openMine.pending, (state, action) => {
        state.openingMine[action.meta.arg.land.asset_id] = 'pending'
      })
      .addCase(openMine.fulfilled, (state, action) => {
        const land = action.meta.arg.land
        const updatedOpeningMine = { ...state.openingMine }
        delete updatedOpeningMine[land.asset_id]
        const updatedMines = [...state.mines]
        updatedMines.push({ land: land, character: null })
        state.mines = updatedMines
        state.openingMine = updatedOpeningMine
      })
      .addCase(openMine.rejected, (state, action) => {
        state.openingMine[action.meta.arg.land.asset_id] = 'failed'
        console.error('Error opening mine', action.error)
      })
      .addCase(sendCharacterToMine.pending, (state, action) => {
        state.sendingCharacterToMine[action.meta.arg.land.asset_id] = 'pending'
      })
      .addCase(sendCharacterToMine.fulfilled, (state, action) => {
        const landNft = action.meta.arg.land
        const characterNft = action.meta.arg.character
        const updatedSendingCharacterToMine = { ...state.sendingCharacterToMine }
        delete updatedSendingCharacterToMine[landNft.asset_id]
        const updatedMine = state.mines.find(item => item.land.asset_id === landNft.asset_id)
        if (updatedMine) {
          updatedMine.character = characterNft
        }
        state.sendingCharacterToMine = updatedSendingCharacterToMine
      })
      .addCase(sendCharacterToMine.rejected, (state, action) => {
        state.sendingCharacterToMine[action.meta.arg.land.asset_id] = 'failed'
        console.error('Error sending character to a mine', action.error)
      })
      .addCase(recallCharacterFromMine.pending, (state, action) => {
        state.recallingCharacterFromMine[action.meta.arg.land.asset_id] = 'pending'
      })
      .addCase(recallCharacterFromMine.fulfilled, (state, action) => {
        const landNft = action.meta.arg.land
        const updatedRecallingCharacterFromMine = { ...state.sendingCharacterToMine }
        delete updatedRecallingCharacterFromMine[landNft.asset_id]
        const updatedMine = state.mines.find(item => item.land.asset_id === landNft.asset_id)
        if (updatedMine) {
          updatedMine.character = null
        }
        state.recallingCharacterFromMine = updatedRecallingCharacterFromMine
      })
      .addCase(recallCharacterFromMine.rejected, (state, action) => {
        state.recallingCharacterFromMine[action.meta.arg.land.asset_id] = 'failed'
        console.error('Error recalling character from a mine', action.error)
      })
      .addCase(closeMine.pending, (state, action) => {
        state.closingMine[action.meta.arg.land.asset_id] = 'pending'
      })
      .addCase(closeMine.fulfilled, (state, action) => {
        const landNft = action.meta.arg.land
        const updatedClosingMine = { ...state.closingMine }
        delete updatedClosingMine[landNft.asset_id]
        state.mines = state.mines.filter(item => item.land.asset_id !== landNft.asset_id)
        state.closingMine = updatedClosingMine
      })
      .addCase(closeMine.rejected, (state, action) => {
        state.closingMine[action.meta.arg.land.asset_id] = 'failed'
        console.error('Error closing a mine', action.error)
      })
      .addCase(claimMaterials.pending, (state, action) => {
        state.claimingMaterials[action.meta.arg.landAssetId] = 'pending'
      })
      .addCase(claimMaterials.fulfilled, (state, action) => {
        const updatedLoadingState = { ...state.claimingMaterials }
        delete updatedLoadingState[action.meta.arg.landAssetId]
        state.claimingMaterials = updatedLoadingState
      })
      .addCase(claimMaterials.rejected, (state, action) => {
        state.claimingMaterials[action.meta.arg.landAssetId] = 'failed'
        console.error('Error claiming materials')
      })
  }
})

export const miningSelector = (state: RootState) => state.mining

export const miningReducer = miningSlice.reducer
