import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AsyncThunkLoading, RootState } from '../types'
import { AtomicAsset } from '../../api/atomic/types/asset'
import { getAssets } from '../../api/atomic'
import { envVars } from '../../services/envVariables'

const DUNGEON_ITEMS_SMARTCONTRACT_NAME = envVars.DUNGEON_ITEMS_SMARTCONTRACT_NAME
const WOMBAT_CHAMPS_COLLECTION_NAME = envVars.WOMBAT_CHAMPS_COLLECTION_NAME
const WOMBAT_CHAMPS_SCHEMA = envVars.WOMBAT_CHAMPS_SCHEMA

type InitialNftsState = {
  /**
   * List of all character NFTs a user owns
   */
  characterNfts: AtomicAsset[]
  /**
   * Status of the getting character NFTs thunk
   */
  gettingCharacterNfts: AsyncThunkLoading
  /**
   * List of all land NFTs a user owns
   */
  landNfts: AtomicAsset[]
  /**
   * Status of the getting land NFTs thunk
   */
  gettingLandNfts: AsyncThunkLoading
  /**
   * List of all stakeable sensei NFTs a user owns
   */
  senseiNfts: AtomicAsset[]
  /**
   * Status of the getting all stakeable sensei NFTs a user owns thunk
   */
  gettingSenseiNfts: AsyncThunkLoading
}

/**
 * Initial state of the {@link nftsSlice}
 */
export const nftsInitialState: InitialNftsState = {
  characterNfts: [],
  gettingCharacterNfts: 'idle',
  landNfts: [],
  gettingLandNfts: 'idle',
  senseiNfts: [],
  gettingSenseiNfts: 'idle',
}

/**
 * Thunk gets all character NFTs the user owns
 */
export const getCharacterNfts = createAsyncThunk<
  AtomicAsset[],
  { waxAccountName: string }
>('nfts/getCharacterNfts', async ({
  waxAccountName,
}) => {
  const response = await getAssets({
    owner: waxAccountName, allowedCollections: [DUNGEON_ITEMS_SMARTCONTRACT_NAME], schemaName: 'characters',
  })
  return response.data
})

/**
 * Thunk gets all lands NFTs the user owns
 */
export const getLandNfts = createAsyncThunk<
  AtomicAsset[],
  { waxAccountName: string }
>('nfts/getLandNfts', async ({
  waxAccountName,
}) => {
  const response = await getAssets({
    owner: waxAccountName, allowedCollections: [DUNGEON_ITEMS_SMARTCONTRACT_NAME], schemaName: 'lands',
  })
  return response.data
})

/**
 * Thunk gets all sensei NFTs the user owns
 */
export const getSenseiNfts = createAsyncThunk<
  AtomicAsset[],
  { waxAccountName: string }
>('nfts/getSenseiNfts', async ({
  waxAccountName,
}) => {
  const response = await getAssets({
    owner: waxAccountName,
    allowedCollections: [WOMBAT_CHAMPS_COLLECTION_NAME],
    schemaName: WOMBAT_CHAMPS_SCHEMA,
  })
  return response.data
})

export const nftsSlice = createSlice({
  name: 'nfts',
  initialState: nftsInitialState,
  reducers: {
    /**
     * Removes a land NFT from the `landNfts` field
     * @param state State of the `nftsSlice`
     * @param action Asset ID of the NFT should be removed from the `landNfts` field
     */
    removeLandNft: (
      state, action: PayloadAction<string>
    ) => {
      state.landNfts = state.landNfts.filter(item => item.asset_id !== action.payload)
    },
    /**
     * Removes a character NFT from the `characterNfts` field
     * @param state State of the `nftsSlice`
     * @param action Asset ID of the NFT should be removed from the `characterNfts` field
     */
    removeCharacterNft: (
      state, action: PayloadAction<string>
    ) => {
      state.characterNfts = state.characterNfts.filter(item => item.asset_id !== action.payload)
    },
    /**
     * Removes a sensei NFT from the {@link senseiNfts} field
     * @param state State of the {@link nftsSlice}
     * @param action Asset ID of the NFT should be removed from the {@link senseiNfts} field
     */
    removeSenseiNft: (
      state, action: PayloadAction<string>
    ) => {
      state.senseiNfts = state.senseiNfts.filter(asset => asset.asset_id !== action.payload)
    },
    /**
     * Adds a land NFT to the `landNfts` field
     * @param state State of the `nftsSlice`
     * @param action Land asset should be added to the `landNfts` field
     */
    addLandNft: (
      state, action: PayloadAction<AtomicAsset>
    ) => {
      const updatedLandNfts = [...state.landNfts]
      updatedLandNfts.push(action.payload)
      state.landNfts = updatedLandNfts
    },
    /**
     * Adds a character NFT to the `characterNfts` field
     * @param state State of the `nftsSlice`
     * @param action Character asset should be added to the `characterNfts` field
     */
    addCharacterNft: (
      state, action: PayloadAction<AtomicAsset>
    ) => {
      state.characterNfts = state.characterNfts.concat([action.payload])
    },
    /**
     * Adds a sensei NFT to the {@link senseiNfts} field
     * @param state State of the {@link nftsSlice}
     * @param action Sensei asset should be added to the {@link senseiNfts} field
     */
    addSenseiNft: (
      state, action: PayloadAction<AtomicAsset>
    ) => {
      state.senseiNfts = state.senseiNfts.concat([action.payload])
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCharacterNfts.pending, state => {
        state.gettingCharacterNfts = 'pending'
      })
      .addCase(getCharacterNfts.fulfilled, (state, action) => {
        state.gettingCharacterNfts = 'succeeded'
        state.characterNfts = action.payload
      })
      .addCase(getCharacterNfts.rejected, (state, action) => {
        state.gettingCharacterNfts = 'failed'
        console.error('Error loading character NFTs', action.error)
      })
      .addCase(getLandNfts.pending, state => {
        state.gettingLandNfts = 'pending'
      })
      .addCase(getLandNfts.fulfilled, (state, action) => {
        state.gettingLandNfts = 'succeeded'
        state.landNfts = action.payload
      })
      .addCase(getLandNfts.rejected, (state, action) => {
        state.gettingLandNfts = 'failed'
        console.error('Error loading land NFTs', action.error)
      })
      .addCase(getSenseiNfts.pending, state => {
        state.gettingSenseiNfts = 'pending'
      })
      .addCase(getSenseiNfts.fulfilled, (state, action) => {
        state.gettingSenseiNfts = 'succeeded'
        state.senseiNfts = action.payload
      })
      .addCase(getSenseiNfts.rejected, (state, action) => {
        state.gettingSenseiNfts = 'failed'
        console.error('Error loading sensei NFTs', action.error)
      })
  }
})

export const {
  removeLandNft, removeCharacterNft, removeSenseiNft,
  addCharacterNft, addLandNft, addSenseiNft,
} = nftsSlice.actions

export const nftsSelector = (state: RootState) => state.nfts

export const nftsReducer = nftsSlice.reducer
