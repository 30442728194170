import { FC, PropsWithChildren } from 'react'
import classnames from 'classnames'

import './RectangleFrame.scss'

export type RectangleFrameColor = 'purple' | 'brown'

type RectangleFrameProps = PropsWithChildren<{
  /**
   * Frame color
   * @default brown
   */
  frameColor?: RectangleFrameColor
  /**
   * Class name for the component
   * @default empty string
   */
  className?: string
  /**
   * If passed the border is dashed, otherwise the border is solid
   * @default undefined
   */
  dashedBorder?: boolean
  /**
   * If passed the component is animated with a flying light block
   * from the top left corner to the right bottom corner.
   * The animation has the white transparent block, and it is visible only
   * on a dark background, if you use it with a light background then
   * probably it will not be visible.
   * @default undefined
   */
  animated?: boolean
}>

/**
 * Returns an element with a rectangle frame around it
 */
export const RectangleFrame: FC<RectangleFrameProps> = ({
  children, frameColor = 'brown', className = '', dashedBorder,
  animated,
}) => {
  return (
    <div className={classnames('rectangle-frame', `rectangle-frame--${frameColor}`, className, {
      'rectangle-frame--dashed': dashedBorder
    })} data-testid='rectangle-frame'>
      <span className='rectangle-frame__corner rectangle-frame__corner--top-left' />
      <span className='rectangle-frame__corner rectangle-frame__corner--top-right' />
      <span className='rectangle-frame__corner rectangle-frame__corner--bottom-left' />
      <span className='rectangle-frame__corner rectangle-frame__corner--bottom-right' />
      {children}
      {animated && <span className='rectangle-frame__light-animation' data-testid='rectangle-frame-animation' />}
    </div>
  )
}
