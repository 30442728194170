import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { RoundedFrame } from '../../../components/RoundedFrame/RoundedFrame'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link RoundedFrame} component
 * for developers
 */
export const RoundedFrameComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Rounded frame with additional borders'>
      <RoundedFrame>
        This component renders children elements with a rounded frame
      </RoundedFrame>
    </ComponentWrapper>
    <ComponentWrapper title='Rounded frame without additional borders'>
      <RoundedFrame hideAdditionalBorders>
        This component renders children elements with a rounded frame
      </RoundedFrame>
    </ComponentWrapper>
  </div>
}
