import { FC } from 'react'
import './RarityBadge.scss'
import classnames from 'classnames'

export type Rarity = 'common' | 'rare' | 'epic' | 'legendary' | 'mythic'

export const rarityLabelMap: Record<Rarity, string> = {
  common: 'Common',
  rare: 'Rare',
  epic: 'Epic',
  legendary: 'Legendary',
  mythic: 'Mythic',
}

type RarityBadgeProps = {
  /**
   * Rarity of the badge
   */
  rarity: Rarity
  /**
   * Class name for the component
   */
  className?: string
  /**
   * Data test id used by Jest
   */
  dataTestId?: string
}

export const RarityBadge: FC<RarityBadgeProps> = ({ rarity, className = '', dataTestId = '' }) => {
  return (
    <div className={classnames('rarity-badge', `rarity-badge--${rarity}`, className)} data-testid={dataTestId}>
      {rarityLabelMap[rarity]}
    </div>
  )
}
