import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AtomicAsset } from '../../../../../api/atomic/types/asset'
import { useAppDispatch } from '../../../../../store/types'
import { dojoSelector, resetUnlockingSlot, stakeCharacterAssetToDojo } from '../../../../../store/slices/dojoSlice'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'

/**
 * Represents returned type of the {@link useDojoModal} hook
 */
export type DojoModalHook = ReturnType<typeof useDojoModal>

/**
 * Represents business logic that is used around the DojoModal component
 * @param user Registered user data
 */
export function useDojoModal(user: RegisteredUserData) {
  const dispatch = useAppDispatch()
  const dojo = useSelector(dojoSelector)

  const [selectedAssetToTrain, setSelectedAssetToTrain] = useState<AtomicAsset>()
  const [selectedSlotToTrain, setSelectedSlotToTrain] = useState<number>()
  const [newSlotToastShown, setNewSlotToastShown] = useState(false)

  /**
   * Add an asset to {@link selectedAssetToTrain}.
   * If the user selects already selected asset then the value sets to `undefined`.
   * @param asset Asset should be added to the {@link selectedAssetToTrain} state
   */
  function addAssetToTrain(asset: AtomicAsset) {
    setSelectedAssetToTrain(prev => {
      // If the asset is already selected it should be unselected
      if (prev?.asset_id === asset.asset_id) {
        return undefined
      }
      return asset
    })
  }

  const oneCharacterIsBeingStaked = Object.values(dojo.stakingAssetsInTrainingSlots).some(item => item === 'pending')

  /**
   * Stakes a selected character asset to dojo in an asset selected
   * @param slot Slot ID the selected character asset should be staked to
   */
  function stakeCharacter(slot: number) {
    if (selectedAssetToTrain && !oneCharacterIsBeingStaked) {
      setSelectedSlotToTrain(slot)
      dispatch(stakeCharacterAssetToDojo({
        trainingSlot: {
          characterAsset: selectedAssetToTrain,
          slot: slot,
          // Can be a small inaccuracy, but it is not critical
          // since the inaccuracy is minimal (less than a second)
          lastXpClaimAt: Date.now(),
        },
        user: user,
      }))
        .unwrap()
        .then(() => {
          // Clears the state when the stake was successfully done
          setSelectedAssetToTrain(undefined)
          setSelectedSlotToTrain(undefined)
        })
        .catch(() => {
          // Clears the slot state when there is an error during stake
          setSelectedSlotToTrain(undefined)
        })
    }
  }

  // Subscribes to the unlocking slot request result and if the request was called successfully
  // changes a state for some time. Based on the state a toast is shown that indicates that
  // the slot was unlocked successfully.
  useEffect(() => {
    if (dojo.unlockingSlot === 'succeeded') {
      setNewSlotToastShown(true)

      const id = setTimeout(() => {
        setNewSlotToastShown(false)
        // Resets the field the hook is subscribed to, to avoid
        // executing this logic when the hook is initialized again
        dispatch(resetUnlockingSlot())
      }, 3000)

      return () => {
        clearTimeout(id)
        // Resets the field the hook is subscribed to, to avoid
        // executing this logic when the hook is initialized again
        dispatch(resetUnlockingSlot())
      }
    }
  }, [dojo.unlockingSlot])

  return {
    /**
     * Selected asset to train. Can be an `undefined` value if no asset selected
     */
    selectedAssetToTrain,
    /**
     * Selected slot to train.
     * Basically, if there is some value then a character asset is being staked to this slot1.
     */
    selectedSlotToTrain,
    /**
     * Indicates if at least one character is being staked right now
     */
    oneCharacterIsBeingStaked,
    /**
     * Indicates if the toast for successfully unlocked slot must be shown
     */
    newSlotToastShown,
    addAssetToTrain,
    stakeCharacter,
  }
}
