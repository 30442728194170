import { useSelector } from 'react-redux'
import { dojoSelector, LevelConfig, TrainingSlotWithAsset } from '../../../../../../store/slices/dojoSlice'
import { characterHasMaxLevel, isEnoughXpForLevelingUp } from './StakedCharacterInDojo'
import { useState } from 'react'
import { settingsSelector } from '../../../../../../store/slices/settingsSlice'
import { AtomicAsset } from '../../../../../../api/atomic/types/asset'

/**
 * Amount of milliseconds per one hour
 */
const msPerHour = 3600000
/**
 * Amount of milliseconds per one minute
 */
const msPerMinute = 60000
/**
 * Amount of milliseconds per one second
 */
const msPerSecond = 1000

/**
 * Returns time in milliseconds that is necessary to wait for claiming
 * at least 1 XP for the character asset. Based on genetics time can be reduced
 * @param characterAsset Character asset the waiting period should be gotten for
 * @return How much time in ms takes getting 1 XP for the character
 */
export const getWaitingPeriodForClaimingOneXp = (characterAsset: AtomicAsset) => {
  // For every `Smart` genetic the character asset gets 10% XP boost.
  // It means with every `Smart` genetic the character gets 1 XP faster.
  if (characterAsset.data.genetic1 === 'Smart' && characterAsset.data.genetic2 === 'Smart') {
    return 180000
  } else if (characterAsset.data.genetic1 === 'Smart' || characterAsset.data.genetic2 === 'Smart') {
    // Here is not exactly 10% less time, the value is rounded up to have calculations easier
    return 197000
  }
  return 216000
}

/**
 * Returns a label with information how much time left from now until the given end time.
 * If returns `undefined` means end time is already passed.
 * @param now Current time in unix ms timestamp
 * @param endTime End time in unix ms timestamp the label should be gotten for
 * @return Label with info how much time left from now until the given date.
 * The format is `57m36s`. Can be also `undefined` if given `endTime` already passed
 */
export const getLeftTimeUntilDateLabel = (now: number, endTime: number) => {
  const timeDiffMilliseconds = endTime - now

  if (timeDiffMilliseconds > 0) {
    let result = ''

    const minutesAmount = Math.floor(timeDiffMilliseconds % msPerHour / msPerMinute)
    if (minutesAmount > 0) {
      // Adds the space in the end to have the space between minutes and seconds
      result += `${minutesAmount}m `
    }

    const secondsAmount = Math.floor(timeDiffMilliseconds % msPerMinute / msPerSecond)
    if (secondsAmount > 0) {
      result += `${secondsAmount}s`
    }

    return result
  }

  return undefined
}

/**
 * Hook for managing buttons logic for the staked character asset in Dojo component.
 * @param stakedCharacter Slot from dojo with staked character asset inside
 * @param levelConfig Level config for the character asset level
 */
export function useStakedCharacterInDojoButtons(
  stakedCharacter: TrainingSlotWithAsset, levelConfig?: LevelConfig
) {
  const dojo = useSelector(dojoSelector)
  const settings = useSelector(settingsSelector)

  const [confirmLevelUpDialogShown, setConfirmLevelUpDialogShown] = useState(false)
  const [recallAssetDialogShown, setRecallAssetDialogShown] = useState(false)

  const openConfirmLevelUpDialog = () => setConfirmLevelUpDialogShown(true)
  const closeConfirmLevelUpDialog = () => setConfirmLevelUpDialogShown(false)

  const openUnstakeAssetDialog = () => setRecallAssetDialogShown(true)
  const closeUnstakeAssetDialog = () => setRecallAssetDialogShown(false)

  const waitingPeriodForClaimingOneXp =
    getWaitingPeriodForClaimingOneXp(stakedCharacter.characterAsset)
  const whenOneXpAvailableToClaim =
    stakedCharacter.lastXpClaimAt + waitingPeriodForClaimingOneXp
  // Label with timer when the user is able to claim at least 1 XP
  const timerLabel = getLeftTimeUntilDateLabel(settings.now, whenOneXpAvailableToClaim)

  const claimXpButtonChildren = timerLabel || 'Claim xp'

  const levelUpAndScoreXpButtonHidden = characterHasMaxLevel(stakedCharacter.characterAsset)

  const readyForLevelUp = levelConfig
    ? isEnoughXpForLevelingUp(stakedCharacter.characterAsset, levelConfig)
    : false

  const claimXpButtonDisabled = dojo.claimingXpFromTrainingSlots[stakedCharacter.slot] === 'pending' || !!timerLabel
  const amountOfTokensForLevelingUp = levelConfig?.wombatPriceForLevelingUp

  return {
    /**
     * If the level up and score buttons are hidden.
     * Can be hidden when the character asset has max level.
     */
    levelUpAndScoreXpButtonHidden,
    /**
     * If the character asset is ready for being leveled up
     */
    readyForLevelUp,
    /**
     * If the button for claiming XP disabled
     */
    claimXpButtonDisabled,
    /**
     * How many amount of tokens user has to pay for leveling up.
     * Can be `undefined` if leveling up is for free.
     */
    amountOfTokensForLevelingUp,
    /**
     * If the confirmation for character asset leveling up dialog opened
     */
    confirmLevelUpDialogShown,
    /**
     * If the recalling character asset dialog opened
     */
    recallAssetDialogShown,
    /**
     * Opens the confirmation for character asset leveling up dialog
     */
    openConfirmLevelUpDialog,
    /**
     * Closes the confirmation for character asset leveling up dialog
     */
    closeConfirmLevelUpDialog,
    /**
     * Opens the unstaking character asset dialog
     */
    openUnstakeAssetDialog,
    /**
     * Closes the unstaking character asset dialog
     */
    closeUnstakeAssetDialog,
    /**
     * Children element of the claim XP button.
     * Can be the `Claim xp` label or
     * a label with info how much time left until getting 1 XP claimable.
     */
    claimXpButtonChildren,
  }
}
