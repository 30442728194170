import React, { FC } from 'react'
import { Dialog } from '../../../../../../components/Dialog/Dialog'
import './RecallSenseiDialog.scss'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'
import { dojoSelector, recallSenseiFromSlot, SenseiSlotWithAsset } from '../../../../../../store/slices/dojoSlice'
import { DojoBuildingModalSubtitle } from '../../DojoBuilding'
import { RectangleFrame } from '../../../../../../components/RectangleFrame/RectangleFrame'
import { SenseiCard } from '../../../../../../components/SenseiCard/SenseiCard'
import { ButtonsContainer } from '../../../../../../components/ButtonsContainer/ButtonsContainer'
import { Button } from '../../../../../../components/Button/Button'
import { useAppDispatch } from '../../../../../../store/types'
import { useSelector } from 'react-redux'

type RecallSenseiDialogProps = {
  /**
   * Closes the dialog
   */
  closeDialog: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Sensei slot with staked asset in there
   */
  slotWithAsset: SenseiSlotWithAsset
  /**
   * Function is called after recalling a sensei
   * @default undefined
   */
  afterRecallSensei?: () => void
}

export const RecallSenseiDialog: FC<RecallSenseiDialogProps> = props => {
  const dispatch = useAppDispatch()
  const { recallingSenseiAssetsFromSlots } = useSelector(dojoSelector)

  const recallSensei = async () => {
    await dispatch(recallSenseiFromSlot({ user: props.user, slot: props.slotWithAsset }))
    if (props.afterRecallSensei) {
      props.afterRecallSensei()
    }
  }

  const buttonDisabled = recallingSenseiAssetsFromSlots[props.slotWithAsset.senseiAsset.asset_id] === 'pending'

  return <Dialog onBackdropClick={props.closeDialog} className='recall-sensei-dialog'>
    <RectangleFrame className='recall-sensei-dialog__rectangle-frame'>
      <DojoBuildingModalSubtitle>
        Retrieve this Sensei?
      </DojoBuildingModalSubtitle>
      <SenseiCard asset={props.slotWithAsset.senseiAsset} />
    </RectangleFrame>
    <ButtonsContainer oneRowInPortraitMode>
      <Button onClick={props.closeDialog} appearance='secondary'>No</Button>
      <Button
        onClick={recallSensei} disabled={buttonDisabled}
        dataTestId='recall-sensei-dialog-confirm-button'
      >Yes</Button>
    </ButtonsContainer>
  </Dialog>
}
