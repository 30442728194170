import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Action } from 'eosjs/dist/eosjs-serialize'
import Decimal from 'decimal.js'

import { AsyncThunkLoading, RootState, ThunkApi } from '../types'
import { AtomicAsset } from '../../api/atomic/types/asset'
import { transact } from '../../wax'
import { getAssets } from '../../api/atomic'
import { RegisteredUserData } from '../../api/dungeon-worlds/user'
import { addCharacterNft, addSenseiNft, removeCharacterNft, removeSenseiNft } from './nftsSlice'
import { envVars } from '../../services/envVariables'
import { addToast } from './toastSlice'
import { getSenseiSlotsFromSmartContract, getSlotAndCharactersFromSmartContract } from '../../services/dojoSmartContract'

/**
 * Price that is used for unlocking a slot. The currency should be used with the price is $WOMBAT
 */
export const priceToUnlockSlot = new Decimal('250')
const claimXpInDojoActionName = 'dojoclaimxp'

/**
 * Returns a copied version of a value.
 * Can be used when you need to make a deep copy of a value and then
 * update it without updating the value you copy from.
 * Usually, used with object or arrays since they are reference values
 * @param value Value should be copied
 */
export const deepCopy = <T>(value: T): T => JSON.parse(JSON.stringify(value))

export type TrainingSlot = {
  /**
   * Slot where the character asset is staked at
   */
  slot: number
  /**
   * Staked character asset in dojo for the slot.
   * Slot can be empty and in this case the character asset is `null`
   */
  characterAsset: AtomicAsset | null
  /**
   * Last time when the user claimed XP for this particular asset.
   * The format is unix ms timestamp
   */
  lastXpClaimAt: number
}

export type TrainingSlotWithAsset = {
  /**
   * Slot where the character asset is staked at
   */
  slot: number
  /**
   * Staked character asset in dojo for the slot.
   */
  characterAsset: AtomicAsset
  /**
   * Last time when the user claimed XP for this particular asset.
   * The format is unix ms timestamp
   */
  lastXpClaimAt: number
}

/**
 * Describes structure of the sensei slot in Dojo
 */
export type SenseiSlot = {
  /**
   * Slot where the sensei asset is staked at
   */
  slot: number
  /**
   * Staked sensei asset in dojo for the slot.
   * Slot can be empty and in this case the sensei asset is `null`
   */
  senseiAsset: AtomicAsset | null
}

/**
 * Describes structure of the sensei slot with the guaranteed sensei asset in the slot
 */
export type SenseiSlotWithAsset = {
  /**
   * Slot where the sensei asset is staked at
   */
  slot: number
  /**
   * Staked sensei asset in dojo for the slot
   */
  senseiAsset: AtomicAsset
}

export type LevelConfig = {
  /**
   * Level number
   */
  level: number
  /**
   * XP threshold for reaching the next level from the current level
   */
  xpThreshold?: number
  /**
   * Wombat price for leveling up the next level
   */
  wombatPriceForLevelingUp?: number
}

type InitialDojoState = {
  /**
   * Config with all levels and XP threshold for leveling UP character assets in training slots
   */
  levelsConfig: LevelConfig[]
  /**
   * List of all training slots with possible character assets in there that the user staked
   */
  trainingSlots: TrainingSlot[]
  /**
   * List of all sensei slots with possible sensei assets in there that the user staked
   */
  senseiSlots: SenseiSlot[]
  /**
   * Status of getting all training slots that the user has
   */
  gettingTrainingSlots: AsyncThunkLoading
  /**
   * Status of getting all sensei slots that the user has
   */
  gettingSenseiSlots: AsyncThunkLoading
  /**
   * Status of staking character assets in training slots.
   * String in the record means `asset_id` of the asset used for staking
   */
  stakingAssetsInTrainingSlots: Record<string, AsyncThunkLoading>
  /**
   * Status of staking sensei assets in sensei slots.
   * String in the record means `asset_id` of the asset used for staking
   */
  stakingSenseiAssetsInSlots: Record<string, AsyncThunkLoading>
  /**
   * State of recalling character assets from training slots.
   * String in the record means `asset_id` of the asset used for unstaking
   */
  recallingAssetsFromTrainingSlots: Record<string, AsyncThunkLoading>
  /**
   * Status of recalling sensei assets from slots.
   * String in the record means `asset_id` of the asset used for recalling
   */
  recallingSenseiAssetsFromSlots: Record<string, AsyncThunkLoading>
  /**
   * Status of claiming XP from training slots.
   * String in the record means `slot` the XP is being claimed for
   */
  claimingXpFromTrainingSlots: Record<string, AsyncThunkLoading>
  /**
   * Status of leveling up character assets.
   * String in the record means `asset_id` of the asset used for leveling up
   */
  levelingUpCharacterAssets: Record<string, AsyncThunkLoading>
  /**
   * The latest scored XP value for training slots.
   * The number in the record means slot ID.
   * The string is the amount of scored XP
   */
  lastScoredXpForTrainingSlots: Record<number, string>
  /**
   * State of unlocking a slot in dojo.
   */
  unlockingSlot: AsyncThunkLoading
}

/**
 * Initial state of the {@link dojoSlice}
 */
export const dojoInitialState: InitialDojoState = {
  levelsConfig: [
    { level: 1, xpThreshold: 53 },
    { level: 2, xpThreshold: 56 },
    { level: 3, xpThreshold: 60 },
    { level: 4, xpThreshold: 64 },
    { level: 5, xpThreshold: 68 },
    { level: 6, xpThreshold: 73 },
    { level: 7, xpThreshold: 78 },
    { level: 8, xpThreshold: 83 },
    { level: 9, xpThreshold: 88 },
    { level: 10, xpThreshold: 94 },
    { level: 11, xpThreshold: 100 },
    { level: 12, xpThreshold: 107 },
    { level: 13, xpThreshold: 114 },
    { level: 14, xpThreshold: 121 },
    { level: 15, xpThreshold: 129 },
    { level: 16, xpThreshold: 138 },
    { level: 17, xpThreshold: 147 },
    { level: 18, xpThreshold: 156 },
    { level: 19, xpThreshold: 167 },
    { level: 20, xpThreshold: 178, wombatPriceForLevelingUp: 50 },
    { level: 21, xpThreshold: 189, wombatPriceForLevelingUp: 100 },
    { level: 22, xpThreshold: 202, wombatPriceForLevelingUp: 150 },
    { level: 23, xpThreshold: 215, wombatPriceForLevelingUp: 200 },
    { level: 24, xpThreshold: 229, wombatPriceForLevelingUp: 250 },
    { level: 25, xpThreshold: 244, wombatPriceForLevelingUp: 300 },
    { level: 26, xpThreshold: 260, wombatPriceForLevelingUp: 350 },
    { level: 27, xpThreshold: 278, wombatPriceForLevelingUp: 400 },
    { level: 28, xpThreshold: 296, wombatPriceForLevelingUp: 450 },
    { level: 29, xpThreshold: 315, wombatPriceForLevelingUp: 500 },
    { level: 30, xpThreshold: 336, wombatPriceForLevelingUp: 550 },
    { level: 31, xpThreshold: 358, wombatPriceForLevelingUp: 600 },
    { level: 32, xpThreshold: 381, wombatPriceForLevelingUp: 650 },
    { level: 33, xpThreshold: 407, wombatPriceForLevelingUp: 700 },
    { level: 34, xpThreshold: 433, wombatPriceForLevelingUp: 750 },
    { level: 35, xpThreshold: 462, wombatPriceForLevelingUp: 800 },
    { level: 36, xpThreshold: 492, wombatPriceForLevelingUp: 850 },
    { level: 37, xpThreshold: 524, wombatPriceForLevelingUp: 900 },
    { level: 38, xpThreshold: 559, wombatPriceForLevelingUp: 950 },
    { level: 39, xpThreshold: 595, wombatPriceForLevelingUp: 1000 },
    { level: 40, xpThreshold: 635, wombatPriceForLevelingUp: 1050 },
    { level: 41, xpThreshold: 676, wombatPriceForLevelingUp: 1100 },
    { level: 42, xpThreshold: 721, wombatPriceForLevelingUp: 1150 },
    { level: 43, xpThreshold: 768, wombatPriceForLevelingUp: 1200 },
    { level: 44, xpThreshold: 818, wombatPriceForLevelingUp: 1250 },
    { level: 45, xpThreshold: 872, wombatPriceForLevelingUp: 1300 },
    { level: 46, xpThreshold: 929, wombatPriceForLevelingUp: 1350 },
    { level: 47, xpThreshold: 990, wombatPriceForLevelingUp: 1400 },
    { level: 48, xpThreshold: 1055, wombatPriceForLevelingUp: 1450 },
    { level: 49, xpThreshold: 1124, wombatPriceForLevelingUp: 1500 },
    { level: 50, xpThreshold: 1198, wombatPriceForLevelingUp: 1550 },
    { level: 51, xpThreshold: 1277, wombatPriceForLevelingUp: 1600 },
    { level: 52, xpThreshold: 1361, wombatPriceForLevelingUp: 1650 },
    { level: 53, xpThreshold: 1450, wombatPriceForLevelingUp: 1700 },
    { level: 54, xpThreshold: 1545, wombatPriceForLevelingUp: 1750 },
    { level: 55, xpThreshold: 1647, wombatPriceForLevelingUp: 1800 },
    { level: 56, xpThreshold: 1755, wombatPriceForLevelingUp: 1850 },
    { level: 57, xpThreshold: 1870, wombatPriceForLevelingUp: 1900 },
    { level: 58, xpThreshold: 1992, wombatPriceForLevelingUp: 1950 },
    { level: 59, xpThreshold: 2123, wombatPriceForLevelingUp: 2000 },
    { level: 60, xpThreshold: 2262, wombatPriceForLevelingUp: 2050 },
    { level: 61, xpThreshold: 2411, wombatPriceForLevelingUp: 2100 },
    { level: 62, xpThreshold: 2569, wombatPriceForLevelingUp: 2150 },
    { level: 63, xpThreshold: 2738, wombatPriceForLevelingUp: 2200 },
    { level: 64, xpThreshold: 2917, wombatPriceForLevelingUp: 2250 },
    { level: 65, xpThreshold: 3109, wombatPriceForLevelingUp: 2300 },
    { level: 66, xpThreshold: 3313, wombatPriceForLevelingUp: 2350 },
    { level: 67, xpThreshold: 3530, wombatPriceForLevelingUp: 2400 },
    { level: 68, xpThreshold: 3762, wombatPriceForLevelingUp: 2450 },
    { level: 69, xpThreshold: 4008, wombatPriceForLevelingUp: 2500 },
    { level: 70, xpThreshold: 4271, wombatPriceForLevelingUp: 2550 },
    { level: 71, xpThreshold: 4552, wombatPriceForLevelingUp: 2600 },
    { level: 72, xpThreshold: 4850, wombatPriceForLevelingUp: 2650 },
    { level: 73, xpThreshold: 5169, wombatPriceForLevelingUp: 2700 },
    { level: 74, xpThreshold: 5508, wombatPriceForLevelingUp: 2750 },
    { level: 75, xpThreshold: 5869, wombatPriceForLevelingUp: 2800 },
    { level: 76, xpThreshold: 6254, wombatPriceForLevelingUp: 2850 },
    { level: 77, xpThreshold: 6664, wombatPriceForLevelingUp: 2900 },
    { level: 78, xpThreshold: 7102, wombatPriceForLevelingUp: 2950 },
    { level: 79, xpThreshold: 7568, wombatPriceForLevelingUp: 3000 },
    { level: 80, xpThreshold: 8064, wombatPriceForLevelingUp: 3050 },
    { level: 81, xpThreshold: 8593, wombatPriceForLevelingUp: 3100 },
    { level: 82, xpThreshold: 9157, wombatPriceForLevelingUp: 3150 },
    { level: 83, xpThreshold: 9758, wombatPriceForLevelingUp: 3200 },
    { level: 84, xpThreshold: 10398, wombatPriceForLevelingUp: 3250 },
    { level: 85, xpThreshold: 11080, wombatPriceForLevelingUp: 3300 },
    { level: 86, xpThreshold: 11807, wombatPriceForLevelingUp: 3350 },
    { level: 87, xpThreshold: 12581, wombatPriceForLevelingUp: 3400 },
    { level: 88, xpThreshold: 13407, wombatPriceForLevelingUp: 3450 },
    { level: 89, xpThreshold: 14286, wombatPriceForLevelingUp: 3500 },
    { level: 90, xpThreshold: 15224, wombatPriceForLevelingUp: 3550 },
    { level: 91, xpThreshold: 16222, wombatPriceForLevelingUp: 3600 },
    { level: 92, xpThreshold: 17287, wombatPriceForLevelingUp: 3650 },
    { level: 93, xpThreshold: 18421, wombatPriceForLevelingUp: 3700 },
    { level: 94, xpThreshold: 19629, wombatPriceForLevelingUp: 3750 },
    { level: 95, xpThreshold: 20917, wombatPriceForLevelingUp: 3800 },
    { level: 96, xpThreshold: 22289, wombatPriceForLevelingUp: 3850 },
    { level: 97, xpThreshold: 23751, wombatPriceForLevelingUp: 3900 },
    { level: 98, xpThreshold: 25310, wombatPriceForLevelingUp: 3950 },
    { level: 99, xpThreshold: 26970, wombatPriceForLevelingUp: 4000 },
    { level: 100 }
  ],
  // Can be that a slot is not provided by the smartcontract, but at least one slot
  // should be shown in the UI for the user. That's why one slot is hardcoded here.
  // If the user has slots then it will be overwritten, otherwise one slot is hardcoded.
  trainingSlots: [{ slot: 0, lastXpClaimAt: 0, characterAsset: null }],
  senseiSlots: [],
  gettingTrainingSlots: 'idle',
  gettingSenseiSlots: 'idle',
  stakingAssetsInTrainingSlots: {},
  stakingSenseiAssetsInSlots: {},
  recallingAssetsFromTrainingSlots: {},
  recallingSenseiAssetsFromSlots: {},
  claimingXpFromTrainingSlots: {},
  levelingUpCharacterAssets: {},
  lastScoredXpForTrainingSlots: {},
  unlockingSlot: 'idle',
}

/**
 * Gets all character assets the user has in dojo
 * @param waxAccountName User's wax account name the character assets from dojo should be gotten
 */
export const getTrainingSlots = createAsyncThunk<
  TrainingSlot[],
  { waxAccountName: string }
>('dojo/getTrainingSlots', async ({ waxAccountName }) => {
  const slotsAndCharactersFromSmartContract =
    await getSlotAndCharactersFromSmartContract(waxAccountName)

  if (slotsAndCharactersFromSmartContract.length > 0) {
    const charactersAssetIds: string[] = []

    slotsAndCharactersFromSmartContract.forEach(item => {
      // Filters out absence of character asset IDs
      if (item.characterAssetId !== 0) {
        charactersAssetIds.push(item.characterAssetId)
      }
    })

    let characters: AtomicAsset[] = []

    if (charactersAssetIds.length > 0) {
      const res = await getAssets({ ids: charactersAssetIds })
      characters = res.data
    }

    const charactersInDojo: TrainingSlot[] = []

    slotsAndCharactersFromSmartContract.forEach(characterFromSmartContract => {
      const characterAsset = characters.find(characterAsset => {
        return characterAsset.asset_id === characterFromSmartContract.characterAssetId
      })
      charactersInDojo.push({
        slot: characterFromSmartContract.slot,
        characterAsset: characterAsset || null,
        lastXpClaimAt: characterFromSmartContract.lastXpClaimAt,
      })
    })

    return charactersInDojo
  }

  return []
})

/**
 * Gets all sensei slots with possible sensei NFTs in there
 * @param waxAccountName User's wax account name the sensei slots should be gotten for
 */
export const getSenseiSlots = createAsyncThunk<
  SenseiSlot[],
  { waxAccountName: string }
>('dojo/getSenseiSlots', async ({ waxAccountName }) => {
  const senseiSlotsFromSmartContract =
    await getSenseiSlotsFromSmartContract(waxAccountName)

  if (senseiSlotsFromSmartContract.length > 0) {
    const senseiAssetIds: string[] = []

    senseiSlotsFromSmartContract.forEach(item => {
      // Filters out absence of character asset IDs
      if (item.senseiAssetId !== 0) {
        senseiAssetIds.push(item.senseiAssetId)
      }
    })

    let senseis: AtomicAsset[] = []

    if (senseiAssetIds.length > 0) {
      const res = await getAssets({ ids: senseiAssetIds })
      senseis = res.data
    }

    const senseisInDojo: SenseiSlot[] = []

    senseiSlotsFromSmartContract.forEach(slot => {
      const senseiAsset = senseis.find(asset => {
        return asset.asset_id === slot.senseiAssetId
      })
      senseisInDojo.push({
        slot: slot.slot,
        senseiAsset: senseiAsset || null,
      })
    })

    return senseisInDojo
  }

  return []
})

/**
 * Stakes character asset to dojo
 * @param trainingSlot The training slot with the character asset in there should be staked
 * @param user User the character asset should be staked to
 */
export const stakeCharacterAssetToDojo = createAsyncThunk<
  unknown,
  { trainingSlot: TrainingSlotWithAsset, user: RegisteredUserData }
>('dojo/stakeCharacter', async (
  { trainingSlot, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: 'atomicassets',
      name: 'transfer',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        from: user.waxAccount,
        to: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
        asset_ids: [trainingSlot.characterAsset.asset_id],
        memo: `sendtotraining:${trainingSlot.slot}`,
      },
    }]
  }
  await transact(transaction, user)

  dispatch(removeCharacterNft(trainingSlot.characterAsset.asset_id))
  dispatch(addCharacterNftToDojo({
    slot: trainingSlot.slot,
    characterAsset: trainingSlot.characterAsset,
    lastXpClaimAt: trainingSlot.lastXpClaimAt,
  }))
})

/**
 * Stakes a sensei asset to a slot
 * @param slotWithAsset Slot with a sensei asset in there should be staked
 * @param user Owner of the sensei asset should be staked
 */
export const stakeSenseiAssetToSlot = createAsyncThunk<
  unknown,
  { slotWithAsset: SenseiSlotWithAsset, user: RegisteredUserData }
>('dojo/stakeSensei', async (
  { slotWithAsset, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: 'atomicassets',
      name: 'transfer',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        from: user.waxAccount,
        to: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
        asset_ids: [slotWithAsset.senseiAsset.asset_id],
        memo: `sendsensei:${slotWithAsset.slot}`,
      },
    }]
  }
  await transact(transaction, user)

  dispatch(removeSenseiNft(slotWithAsset.senseiAsset.asset_id))
  dispatch(addSenseiNftToSlot(slotWithAsset))
})

/**
 * Unstakes character asset from dojo
 * @param trainingSlot The training slot with the character asset in there should be recalled
 * @param user User the character asset should be unstaked to
 */
export const recallCharacterAssetFromDojo = createAsyncThunk<
  unknown,
  { trainingSlot: TrainingSlotWithAsset, user: RegisteredUserData }
>('dojo/unstakeCharacter', async (
  { trainingSlot, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
      name: 'dojorecall',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        owner: user.waxAccount,
        slot_id: trainingSlot.slot,
      },
    }]
  }

  const updatedCharacterAsset = deepCopy(trainingSlot.characterAsset)

  const transactionResult = await transact(transaction, user)
  if ('processed' in transactionResult) {
    const scoredXp: string | undefined =
      transactionResult.processed.action_traces[1]?.return_value_data
    if (scoredXp) {
      // Updates XP for the character asset
      updatedCharacterAsset.data.experience =
        new Decimal(updatedCharacterAsset.data.experience).plus(scoredXp).toNumber()
    }
  }

  dispatch(addCharacterNft(updatedCharacterAsset))
  dispatch(removeCharacterNftFromSlot(trainingSlot.slot))
})

/**
 * Recalls (unstakes) a sensei assets from a slot
 * @param slot Slot with sensei asset in there that should be unstaked
 * @param user Owner of the sensei asset should be recalled
 */
export const recallSenseiFromSlot = createAsyncThunk<
  unknown,
  { slot: SenseiSlotWithAsset, user: RegisteredUserData }
>('dojo/recallSensei', async (
  { slot, user },
  { dispatch }
) => {
  const transaction = {
    actions: [{
      account: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
      name: 'senseirecall',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        owner: user.waxAccount,
        slot_id: slot.slot,
      },
    }]
  }

  await transact(transaction, user)

  dispatch(addSenseiNft(slot.senseiAsset))
  dispatch(removeSenseiNftFromSlot(slot.slot))
  dispatch(addToast({ text: 'Your Sensei has been retrieved!' }))
})

/**
 * Claim XP from dojo for the specific slot
 * @param user User the XP should be claimed for
 * @param slot Slot ID (or slot number) the XP should be claimed for
 */
export const claimXpFromDojo = createAsyncThunk<
  string | undefined,
  { user: RegisteredUserData, slot: number }
>('dojo/claimXp', async (
  { user, slot }
) => {
  const transaction = {
    actions: [{
      account: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
      name: claimXpInDojoActionName,
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        owner: user.waxAccount,
        slot_id: slot,
      },
    }]
  }

  const transactionResult = await transact(transaction, user)
  if ('processed' in transactionResult) {
    // Action trace for the claiming XP action can be stored at different index based on
    // the used wallet for the transaction
    const scoredXp: string | undefined = transactionResult.processed.action_traces.find(item => {
      return item.act.name === claimXpInDojoActionName
    })?.return_value_data
    return scoredXp
  }
})

/**
 * Levels UP character asset
 * @param character Slot with staked character asset in there
 * @param user User the asset should be leveled up for
 */
export const levelUpCharacterAsset = createAsyncThunk<
  unknown,
  { character: TrainingSlotWithAsset, user: RegisteredUserData },
  ThunkApi
>('dojo/levelUp', async (
  { character, user },
  { getState }
) => {
  // Gets the level config of the current character level.
  // The config for a level contains the XP threshold and the price for the next level!
  const levelConfig = getState().dojo.levelsConfig.find(item => {
    return new Decimal(character.characterAsset.data.level).eq(item.level)
  })

  const transaction: { actions: Action[] } = { actions: [] }

  // From the level 20 we have the price in wombat tokens for leveling up
  if (levelConfig?.wombatPriceForLevelingUp) {
    // Prepares price to the correct structure
    const price = `${levelConfig.wombatPriceForLevelingUp.toFixed(8)} ${envVars.WOMBAT_TOKEN_SYMBOL}`
    transaction.actions.push({
      account: envVars.WOMBAT_TOKENS_SMARTCONTRACT_NAME,
      name: 'transfer',
      authorization: [ { actor: user.waxAccount, permission: 'active' } ],
      data: {
        from: user.waxAccount,
        to: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
        quantity: price,
        memo: `Level up ${character.characterAsset.asset_id}`
      },
    })
  }

  transaction.actions.push({
    account: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
    name: 'dojolevelup',
    authorization: [ { actor: user.waxAccount, permission: 'active' } ],
    data: {
      owner: user.waxAccount,
      slot_id: character.slot,
    },
  })
  await transact(transaction, user)
})

/**
 * Unlocks the next slot in dojo
 * @param user User the slot should be unlocked for
 */
export const unlockSlotInDojo = createAsyncThunk<
  unknown, RegisteredUserData
>('dojo/unlockSlot', async (user) => {
  // Prepares price to the correct structure
  const price = `${priceToUnlockSlot.toFixed(8)} ${envVars.WOMBAT_TOKEN_SYMBOL}`

  await transact({
    actions: [{
      account: envVars.WOMBAT_TOKENS_SMARTCONTRACT_NAME,
      name: 'transfer',
      authorization: [{ actor: user.waxAccount, permission: 'active' }],
      data: {
        from: user.waxAccount,
        to: envVars.DUNGEON_WORLDS_SMARTCONTRACT_NAME,
        quantity: price,
        memo: 'unlockslot',
      },
    }],
  }, user)
})

export const dojoSlice = createSlice({
  name: 'dojo',
  initialState: dojoInitialState,
  reducers: {
    /**
     * Adds a character NFT to the {@link trainingSlots}
     * @param state State of the {@link dojoSlice}
     * @param action Character asset with the slot should be added to dojo
     */
    addCharacterNftToDojo: (
      state, action: PayloadAction<TrainingSlotWithAsset>
    ) => {
      const updatedTrainingSlots = [...state.trainingSlots]
      const foundExistingSlot = updatedTrainingSlots.find(item => {
        return item.slot === action.payload.slot
      })
      // Checks if the slot already exists.
      // If exists then adds the character to this slot and updates last XP claim field,
      // otherwise creates a new slot in the store
      if (foundExistingSlot) {
        foundExistingSlot.characterAsset = action.payload.characterAsset
        foundExistingSlot.lastXpClaimAt = action.payload.lastXpClaimAt
      } else {
        updatedTrainingSlots.push(action.payload)
      }
      state.trainingSlots = updatedTrainingSlots
    },
    /**
     * Adds a sensei NFT to the {@link senseiSlots} field
     * @param state State of the {@link dojoSlice}
     * @param action Slot with sensei asset should be added
     */
    addSenseiNftToSlot: (
      state, action: PayloadAction<SenseiSlotWithAsset>
    ) => {
      const updatedSenseiSlots = [...state.senseiSlots]
      const foundExistingSlot = updatedSenseiSlots.find(item => {
        return item.slot === action.payload.slot
      })
      // Checks if the slot already exists.
      // If exists then adds the sensei NFT to this slot, otherwise creates a new slot in the store
      if (foundExistingSlot) {
        foundExistingSlot.senseiAsset = action.payload.senseiAsset
      } else {
        updatedSenseiSlots.push(action.payload)
      }
      state.senseiSlots = updatedSenseiSlots
    },
    /**
     * Removes a character NFT from the slot
     * @param state State of the {@link dojoSlice}
     * @param action Slot ID the character asset should be removed from
     */
    removeCharacterNftFromSlot: (
      state, action: PayloadAction<number>
    ) => {
      state.trainingSlots = state.trainingSlots.map(item => {
        if (item.slot === action.payload) {
          return {
            slot: item.slot,
            characterAsset: null,
            lastXpClaimAt: 0,
          }
        }
        return item
      })
    },
    /**
     * Removes a sensei NFT from the slot
     * @param state State of the {@link dojoSlice}
     * @param action Slot ID the sensei asset should be removed from
     */
    removeSenseiNftFromSlot: (
      state, action: PayloadAction<number>
    ) => {
      state.senseiSlots = state.senseiSlots.map(slot => {
        if (slot.slot === action.payload) {
          return {
            slot: slot.slot,
            senseiAsset: null,
          }
        }
        return slot
      })
    },
    /**
     * Removes the last scored XP value for the slot by slot ID
     * @param state State of the {@link dojoSlice}
     * @param action Slot ID the last scored XP should be removed from
     */
    removeLastScoredXpForSlot: (
      state, action: PayloadAction<number>
    ) => {
      const updatedLastScoredXpForTrainingSlots = state.lastScoredXpForTrainingSlots
      delete updatedLastScoredXpForTrainingSlots[action.payload]
      state.lastScoredXpForTrainingSlots = updatedLastScoredXpForTrainingSlots
    },
    /**
     * Resets {@link unlockingSlot} to the default value
     * @param state State of the {@link dojoSlice}
     */
    resetUnlockingSlot: (state) => {
      state.unlockingSlot = 'idle'
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTrainingSlots.pending, state => {
        state.gettingTrainingSlots = 'pending'
      })
      .addCase(getTrainingSlots.fulfilled, (state, action) => {
        state.gettingTrainingSlots = 'succeeded'
        // If there is no slots then shouldn't be written to the store because
        // the store is initialized with one hardcoded slot to show correct info in the UI
        if (action.payload.length > 0) {
          state.trainingSlots = action.payload
        }
      })
      .addCase(getTrainingSlots.rejected, (state, action) => {
        state.gettingTrainingSlots = 'failed'
        console.error('Error getting character assets in dojo', action.error)
      })
      .addCase(getSenseiSlots.pending, state => {
        state.gettingSenseiSlots = 'pending'
      })
      .addCase(getSenseiSlots.fulfilled, (state, action) => {
        state.gettingSenseiSlots = 'succeeded'
        state.senseiSlots = action.payload
      })
      .addCase(getSenseiSlots.rejected, (state, action) => {
        state.gettingSenseiSlots = 'failed'
        console.error('Error getting sensei slots', action.error)
      })
      .addCase(stakeCharacterAssetToDojo.pending, (state, action) => {
        state.stakingAssetsInTrainingSlots[action.meta.arg.trainingSlot.characterAsset.asset_id] = 'pending'
      })
      .addCase(stakeCharacterAssetToDojo.fulfilled, (state, action) => {
        const characterAssetId = action.meta.arg.trainingSlot.characterAsset.asset_id
        const updatedLoadingStakingAssetsInTrainingSlots = {
          ...state.stakingAssetsInTrainingSlots
        }
        delete updatedLoadingStakingAssetsInTrainingSlots[characterAssetId]
        state.stakingAssetsInTrainingSlots = updatedLoadingStakingAssetsInTrainingSlots
      })
      .addCase(stakeCharacterAssetToDojo.rejected, (state, action) => {
        const characterAssetId = action.meta.arg.trainingSlot.characterAsset.asset_id
        state.stakingAssetsInTrainingSlots[characterAssetId] = 'failed'
        console.error('Error staking character asset to dojo', action.error)
      })
      .addCase(stakeSenseiAssetToSlot.pending, (state, action) => {
        state.stakingSenseiAssetsInSlots[action.meta.arg.slotWithAsset.senseiAsset.asset_id] = 'pending'
      })
      .addCase(stakeSenseiAssetToSlot.fulfilled, (state, action) => {
        const senseiAssetId = action.meta.arg.slotWithAsset.senseiAsset.asset_id
        const updatedLoadingStakingSenseiAssetsInSlots = {
          ...state.stakingSenseiAssetsInSlots
        }
        delete updatedLoadingStakingSenseiAssetsInSlots[senseiAssetId]
        state.stakingSenseiAssetsInSlots = updatedLoadingStakingSenseiAssetsInSlots
      })
      .addCase(stakeSenseiAssetToSlot.rejected, (state, action) => {
        const senseiAssetId = action.meta.arg.slotWithAsset.senseiAsset.asset_id
        state.stakingSenseiAssetsInSlots[senseiAssetId] = 'failed'
        console.error('Error staking a sensei asset to a slot', action.error)
      })
      .addCase(recallCharacterAssetFromDojo.pending, (state, action) => {
        const characterAssetId = action.meta.arg.trainingSlot.characterAsset.asset_id
        state.recallingAssetsFromTrainingSlots[characterAssetId] = 'pending'
      })
      .addCase(recallCharacterAssetFromDojo.fulfilled, (state, action) => {
        const characterAssetId = action.meta.arg.trainingSlot.characterAsset.asset_id
        const updatedLoadingUnstakingAssetsFromTrainingSlots = {
          ...state.recallingAssetsFromTrainingSlots
        }
        delete updatedLoadingUnstakingAssetsFromTrainingSlots[characterAssetId]
        state.recallingAssetsFromTrainingSlots =
          updatedLoadingUnstakingAssetsFromTrainingSlots
      })
      .addCase(recallCharacterAssetFromDojo.rejected, (state, action) => {
        const characterAssetId = action.meta.arg.trainingSlot.characterAsset.asset_id
        state.recallingAssetsFromTrainingSlots[characterAssetId] = 'failed'
        console.error('Error unstaking character asset from dojo', action.error)
      })
      .addCase(recallSenseiFromSlot.pending, (state, action) => {
        const senseiAssetId = action.meta.arg.slot.senseiAsset.asset_id
        state.recallingSenseiAssetsFromSlots[senseiAssetId] = 'pending'
      })
      .addCase(recallSenseiFromSlot.fulfilled, (state, action) => {
        const senseiAssetId = action.meta.arg.slot.senseiAsset.asset_id
        const updatedLoadingRecallingSenseiAssetFromSlot = {
          ...state.recallingSenseiAssetsFromSlots
        }
        delete updatedLoadingRecallingSenseiAssetFromSlot[senseiAssetId]
        state.recallingSenseiAssetsFromSlots = updatedLoadingRecallingSenseiAssetFromSlot
      })
      .addCase(recallSenseiFromSlot.rejected, (state, action) => {
        const senseiAssetId = action.meta.arg.slot.senseiAsset.asset_id
        state.recallingSenseiAssetsFromSlots[senseiAssetId] = 'failed'
        console.error('Error recalling sensei asset from a slot', action.error)
      })
      .addCase(claimXpFromDojo.pending, (state, action) => {
        state.claimingXpFromTrainingSlots[action.meta.arg.slot] = 'pending'
      })
      .addCase(claimXpFromDojo.fulfilled, (state, action) => {
        state.claimingXpFromTrainingSlots[action.meta.arg.slot] = 'succeeded'
        const scoredXp = action.payload

        // Updates the `lastScoredXpForTrainingSlots` field for the slot
        if (scoredXp) {
          const slot = action.meta.arg.slot
          state.lastScoredXpForTrainingSlots[slot] = scoredXp
          // Updates the XP value in the character asset
          state.trainingSlots = state.trainingSlots.map(item => {
            if (item.slot === slot && item.characterAsset) {
              const oldExperience = item.characterAsset.data.experience
              item.characterAsset.data.experience =
                new Decimal(oldExperience).plus(scoredXp).toNumber()
            }

            return {
              ...item,
              // Can be a small inaccuracy, but it is not critical
              // since the inaccuracy is minimal (less than a second)
              lastXpClaimAt: Date.now(),
            }
          })
        }
      })
      .addCase(claimXpFromDojo.rejected, (state, action) => {
        state.claimingXpFromTrainingSlots[action.meta.arg.slot] = 'failed'
        console.error('Error claiming XP from dojo', action.error)
      })
      .addCase(levelUpCharacterAsset.pending, (state, action) => {
        state.levelingUpCharacterAssets[action.meta.arg.character.characterAsset.asset_id] = 'pending'
      })
      .addCase(levelUpCharacterAsset.fulfilled, (state, action) => {
        state.levelingUpCharacterAssets[action.meta.arg.character.characterAsset.asset_id] = 'succeeded'

        // Updates the leveled up character asset
        state.trainingSlots = state.trainingSlots.map(character => {
          // Checks if the current asset is the asset has been leveled up
          if (
            character.characterAsset &&
            character.characterAsset.asset_id === action.meta.arg.character.characterAsset.asset_id
          ) {
            character.characterAsset.data.level += 1
            character.characterAsset.data.experience = 0
          }
          return character
        })
      })
      .addCase(levelUpCharacterAsset.rejected, (state, action) => {
        state.levelingUpCharacterAssets[action.meta.arg.character.characterAsset.asset_id] = 'failed'
        console.error('Error leveling up character asset in dojo', action.error)
      })
      .addCase(unlockSlotInDojo.pending, (state) => {
        state.unlockingSlot = 'pending'
      })
      .addCase(unlockSlotInDojo.fulfilled, (state) => {
        const existingSlotIds = state.trainingSlots.map(item => item.slot)
        // Finds the greatest slot number and adds `1` to the new slot
        const newSlotId = Math.max(...existingSlotIds) + 1
        const newSlot: TrainingSlot = {
          slot: newSlotId, lastXpClaimAt: 0, characterAsset: null,
        }

        state.unlockingSlot = 'succeeded'
        state.trainingSlots = [...state.trainingSlots, newSlot]
      })
      .addCase(unlockSlotInDojo.rejected, (state, action) => {
        state.unlockingSlot = 'failed'
        console.error('Error unlocking a slot in dojo', action.error)
      })
  }
})

export const {
  addCharacterNftToDojo, addSenseiNftToSlot, removeCharacterNftFromSlot,
  removeSenseiNftFromSlot, removeLastScoredXpForSlot, resetUnlockingSlot,
} = dojoSlice.actions

export const dojoSelector = (state: RootState) => state.dojo

export const dojoReducer = dojoSlice.reducer
