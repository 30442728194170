import { FC, PropsWithChildren } from 'react'
import './CharacterCard.scss'
import { RoundedFrame } from '../RoundedFrame/RoundedFrame'
import { RectangleFrame } from '../RectangleFrame/RectangleFrame'
import { AtomicAsset } from '../../api/atomic/types/asset'
import SelectedIcon from '../../assets/images/icons/selected.svg'
import EnduranceIcon from 'jsx:./../../assets/images/icons/endurance.svg'
import classnames from 'classnames'
import { settingsSelector } from '../../store/slices/settingsSlice'
import { useSelector } from 'react-redux'
import { mappingCardImage } from './images'

type ConditionalRoundedFrameCharacterCardProps = PropsWithChildren<Pick<CharacterCardProps, 'roundedFrameHidden' | 'onClick'>>

// Decides if the character card should render rounded frame component based on a prop
export const ConditionalRoundedFrameCharacterCard: FC<
  ConditionalRoundedFrameCharacterCardProps
> = ({ children, roundedFrameHidden, onClick }) => {
  if (roundedFrameHidden) {
    return <>{children}</>
  }

  // If the card does not have the `onClick` prop the additional borders should be hidden
  const hideAdditionalBorders = !onClick

  return <RoundedFrame
    hideAdditionalBorders={hideAdditionalBorders}
    dataTestId='rounded-frame' className='character-card__rounded-frame'
  >{children}</RoundedFrame>
}

type ConditionalButtonCharacterCardProps = PropsWithChildren<
  Omit<CharacterCardProps, 'characterAsset' | 'roundedFrameHidden' | 'powerDataHidden'>
>

// Decides if the button tag should render based on the onClick prop availability
export const ConditionalButtonCharacterCard: FC<ConditionalButtonCharacterCardProps> = ({
  children, onClick, disabled, selected,
  className = '', dataTestId = '',
}) => {
  const settings = useSelector(settingsSelector)

  const classNames = classnames('character-card', className, {
    'character-card--selected': selected,
    'character-card--portrait': settings.screenMode === 'PORTRAIT',
    'character-card--landscape': settings.screenMode === 'LANDSCAPE',
    // If the card does not have the `onClick` prop it means the card is inactive.
    // It has to have a special class and based on this class the card becomes a square.
    // Otherwise, it's a rectangle
    'character-card--inactive': !onClick,
  })

  if (!onClick) {
    return <div className={classNames} data-testid={dataTestId}>{children}</div>
  }

  // Needed because a user can change 'disable' attribute by manually in devtools
  // and call the function from the `onClick` prop.
  // For greater security, we can programmatically check this.
  const onInternalClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return <button
    disabled={disabled} onClick={onInternalClick}
    className={classNames} data-testid={dataTestId}
  >{children}</button>
}

type CharacterCardProps = {
  /**
   * If the card is disabled and not clickable
   * @default undefined
   */
  disabled?: boolean
  /**
   * If the card is selected for an action
   * @default undefined
   */
  selected?: boolean
  /**
   * Called by click on the component.
   * If passed the component renders <button> tag, otherwise <div>.
   * Also, if passed then the card has the rectangle proportions,
   * otherwise it has the square proportions without the additional rounded border.
   * @default undefined
   */
  onClick?: () => void
  /**
   * Character asset
   */
  characterAsset: AtomicAsset
  /**
   * If rounded frame in the component hidden
   * @default undefined
   */
  roundedFrameHidden?: boolean
  /**
   * Class name for the component
   * @default Empty string
   */
  className?: string
  /**
   * Data test ID used by Jest
   * @default Empty string
   */
  dataTestId?: string
  /**
   * If passed the block with power information hidden, otherwise the block is shown.
   * @default undefined
   */
  powerDataHidden?: boolean
}

export const CharacterCard: FC<CharacterCardProps> = props => {
  const characterImage = props.characterAsset.template?.template_id &&
    mappingCardImage[props.characterAsset.template.template_id]

  return <ConditionalButtonCharacterCard
    onClick={props.onClick} className={props.className} disabled={props.disabled}
    selected={props.selected} dataTestId={props.dataTestId}
  >
    <ConditionalRoundedFrameCharacterCard
      roundedFrameHidden={props.roundedFrameHidden}
      onClick={props.onClick}
    >
      <RectangleFrame className='character-card__rectangle-frame'>
        {characterImage && <div className='character-card__media-wrapper'>
          <img
            src={characterImage}
            className='character-card__media' alt='Character image'
          />
        </div>}
        {props.selected && <>
          <img src={SelectedIcon} className='character-card__selected-icon' alt='Check icon in a golden background' />
        </>}
        <div className='character-card__info'>
          <RectangleFrame className='character-card__info-frame'>
            <div className='character-card__info-block'>
              <div className='character-card__info-label'>Lv</div>
              <div className='character-card__info-value'>{props.characterAsset.data.level}</div>
            </div>
            {!props.powerDataHidden && <div className='character-card__info-block' data-testid='character-card-power-block'>
              <EnduranceIcon className='character-card__info-icon' />
              <div className='character-card__info-value'>{props.characterAsset.data.power}</div>
            </div>}
          </RectangleFrame>
        </div>
      </RectangleFrame>
    </ConditionalRoundedFrameCharacterCard>
  </ConditionalButtonCharacterCard>
}
