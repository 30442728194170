import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { Button } from '../../../components/Button/Button'

import WombatToken from '../../../assets/images/icons/wombat-token.svg'

const onClick = () => alert('Hello! :)')

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link Button} component for developers
 */
export const ButtonPrimaryComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Primary'>
      <Button onClick={onClick} appearance='primary'>Primary</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Primary with icon'>
      <Button
        onClick={onClick} appearance='primary'
        icon={<img src={WombatToken} alt='Orange wombat in a circle' />}
      >
        Primary with icon
      </Button>
    </ComponentWrapper>
    <ComponentWrapper title='Primary small'>
      <Button onClick={onClick} appearance='primary' size='small'>Primary small</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Primary disabled'>
      <Button onClick={onClick} appearance='primary' disabled>Primary disabled</Button>
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link Button} component for developers
 */
export const ButtonSecondaryComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Secondary'>
      <Button onClick={onClick} appearance='secondary'>Secondary</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Secondary with icon'>
      <Button
        onClick={onClick} appearance='secondary'
        icon={<img src={WombatToken} alt='Orange wombat in a circle' />}
      >
        Secondary with icon
      </Button>
    </ComponentWrapper>
    <ComponentWrapper title='Secondary small'>
      <Button onClick={onClick} appearance='secondary' size='small'>Secondary small</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Secondary disabled'>
      <Button onClick={onClick} appearance='secondary' disabled>Secondary disabled</Button>
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link Button} component for developers
 */
export const ButtonTertiaryComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Tertiary'>
      <Button onClick={onClick} appearance='tertiary'>Tertiary</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Tertiary with icon'>
      <Button
        onClick={onClick} appearance='tertiary'
        icon={<img src={WombatToken} alt='Orange wombat in a circle' />}
      >
        Tertiary with icon
      </Button>
    </ComponentWrapper>
    <ComponentWrapper title='Tertiary small'>
      <Button onClick={onClick} appearance='tertiary' size='small'>Tertiary small</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Tertiary disabled'>
      <Button onClick={onClick} appearance='tertiary' disabled>Tertiary disabled</Button>
    </ComponentWrapper>
  </div>
}

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link Button} component for developers
 */
export const ButtonGhostComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Ghost'>
      <Button onClick={onClick} appearance='ghost'>Ghost</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Ghost with icon'>
      <Button
        onClick={onClick} appearance='ghost'
        icon={<img src={WombatToken} alt='Orange wombat in a circle' />}
      >
        Ghost with icon
      </Button>
    </ComponentWrapper>
    <ComponentWrapper title='Ghost small'>
      <Button onClick={onClick} appearance='ghost' size='small'>Ghost small</Button>
    </ComponentWrapper>
    <ComponentWrapper title='Ghost disabled'>
      <Button onClick={onClick} appearance='ghost' disabled>Ghost disabled</Button>
    </ComponentWrapper>
  </div>
}
