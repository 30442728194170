import {
  claimXpFromDojo,
  LevelConfig,
  TrainingSlotWithAsset,
} from '../../../../../../store/slices/dojoSlice'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'
import { FC } from 'react'
import { useAppDispatch } from '../../../../../../store/types'
import Decimal from 'decimal.js'
import { CharacterCard } from '../../../../../../components/CharacterCard/CharacterCard'
import { Toast } from '../../../../../../components/Toast/Toast'
import { Button } from '../../../../../../components/Button/Button'
import WombatToken from '../../../../../../assets/images/icons/wombat-token.svg'
import UpArrow from '../../../../../../assets/images/icons/up-arrow.svg'
import { LevelUpCharacterConfirmationDialog } from '../LevelUpConfirmationDialog/LevelUpConfirmationDialog'
import { RecallCharacterFromDojoDialog } from '../RecallCharacterFromDojoDialog/RecallCharacterFromDojoDialog'
import { AtomicAsset } from '../../../../../../api/atomic/types/asset'
import './StakedCharacterInDojo.scss'
import { useStakedCharacterInDojoButtons } from './useStakedCharacterInDojoButtons'
import { StakedCharacterInDojoProgressXp } from '../StakedCharacterInDojoProgressXp/StakedCharacterInDojoProgressXp'
import { useStakedCharacterInDojo } from './useStakedCharacterInDojo'
import { calculateXpSenseiBoost } from '../../../../../../components/SenseiCard/SenseiCard'

/**
 * Checks if the character asset has the max level based on the character rarity
 * @param character Character asset should be checked for the max level
 */
export const characterHasMaxLevel = (character: AtomicAsset) => {
  const level = new Decimal(character.data.level)
  if (character.data.rarity === 'Common') {
    return level.eq('35')
  } else if (character.data.rarity === 'Rare') {
    return level.eq('50')
  } else if (character.data.rarity === 'Epic') {
    return level.eq('65')
  } else if (character.data.rarity === 'Legendary') {
    return level.eq('80')
  } else if (character.data.rarity === 'Mythic') {
    return level.eq('100')
  }
  return false
}

/**
 * Check if the character asset has enough XP for leveling up
 * @param character Character asset should be checked
 * @param levelConfig Level config for the current character asset level
 */
export const isEnoughXpForLevelingUp = (character: AtomicAsset, levelConfig: LevelConfig) => {
  return !!(levelConfig.xpThreshold &&
    new Decimal(character.data.experience).gte(levelConfig.xpThreshold))
}

/**
 * Returns progress XP data for a character asset
 * @param character Character progress XP data should be returned for
 * @param levelConfig Config for the current character asset level
 */
export const getProgressDataForCharacterLevel = (
  character: AtomicAsset, levelConfig: LevelConfig,
) => {
  // Checks the last possible level based on the rarity.
  // If the character has the max level leveling up is not possible anymore
  if (characterHasMaxLevel(character)) {
    return undefined
  }

  if (isEnoughXpForLevelingUp(character, levelConfig)) {
    return { title: 'Ready for level up!', progressValue: 100 }
  }

  if (levelConfig.xpThreshold) {
    const progressValue = new Decimal(character.data.experience)
      .div(levelConfig.xpThreshold)
      .mul(100).toNumber()

    return {
      title: `${character.data.experience}/${levelConfig.xpThreshold}`,
      progressValue: progressValue,
    }
  }

  return undefined
}

/**
 * Returns the smartness boost for the staked character in a dojo slot.
 * For every `smart` genetic the asset gets 10% XP boost if a sensei asset is staked
 * @param characterAsset Character asset the boost should be gotten for
 */
export const calculateSmartnessBoost = (characterAsset: AtomicAsset) => {
  let characterBoost = new Decimal('0')

  if (characterAsset.data.genetic1 === 'Smart') {
    characterBoost = characterBoost.add('10')
  }

  if (characterAsset.data.genetic2 === 'Smart') {
    characterBoost = characterBoost.add('10')
  }

  return characterBoost
}

type StakedCharacterInDojoButtonsProps = StakedCharacterInDojoProps & {
  /**
   * Level config of current character asset level
   */
  levelConfig?: LevelConfig
}

/**
 * Renders buttons for the staked character asset in dojo.
 * Component has buttons to claim XP, unstake the character and level the character up.
 * By click on some buttons confirmation dialogs shown.
 */
export const StakedCharacterInDojoButtons: FC<StakedCharacterInDojoButtonsProps> = props => {
  const dispatch = useAppDispatch()
  const stakedCharacterInDojoButtons = useStakedCharacterInDojoButtons(
    props.trainingSlot, props.levelConfig
  )
  const onCloseSuccessLevelUpDialog = () => {
    stakedCharacterInDojoButtons.closeConfirmLevelUpDialog()
  }

  const afterUnstakeAsset = () => {
    stakedCharacterInDojoButtons.closeUnstakeAssetDialog()
  }

  const claimXp = () => {
    dispatch(claimXpFromDojo({ user: props.user, slot: props.trainingSlot.slot }))
  }

  return <>
    <div className='staked-character-in-dojo__buttons'>
      {!stakedCharacterInDojoButtons.levelUpAndScoreXpButtonHidden && <>
        {stakedCharacterInDojoButtons.readyForLevelUp
          ? <Button
            onClick={stakedCharacterInDojoButtons.openConfirmLevelUpDialog} dataTestId='staked-character-in-dojo-level-up-button'
            icon={stakedCharacterInDojoButtons.amountOfTokensForLevelingUp && <img src={WombatToken} alt='Wombat token icon' />}
          >
            {stakedCharacterInDojoButtons.amountOfTokensForLevelingUp || 'Level up'}
          </Button>
          : <Button
            onClick={claimXp} dataTestId='staked-character-in-dojo-score-xp-button'
            disabled={stakedCharacterInDojoButtons.claimXpButtonDisabled} appearance='tertiary'
          >{stakedCharacterInDojoButtons.claimXpButtonChildren}</Button>}
      </>}
      <Button
        onClick={stakedCharacterInDojoButtons.openUnstakeAssetDialog} appearance='secondary'
        dataTestId='staked-character-in-dojo-retrieve-button'
      >Retrieve</Button>
    </div>
    {stakedCharacterInDojoButtons.confirmLevelUpDialogShown && <LevelUpCharacterConfirmationDialog
      trainingSlot={props.trainingSlot} user={props.user}
      onCloseSuccessDialog={onCloseSuccessLevelUpDialog}
      closeDialog={stakedCharacterInDojoButtons.closeConfirmLevelUpDialog}
      amountOfTokensForLevelingUp={stakedCharacterInDojoButtons.amountOfTokensForLevelingUp}
    />}
    {stakedCharacterInDojoButtons.recallAssetDialogShown && <RecallCharacterFromDojoDialog
      closeDialog={stakedCharacterInDojoButtons.closeUnstakeAssetDialog}
      trainingSlot={props.trainingSlot}
      afterUnstake={afterUnstakeAsset} user={props.user}
    />}
  </>
}

type StakedCharacterInDojoBoostsProps = {
  /**
   * Staked sensei asset in a sensei slot
   */
  senseiAsset: AtomicAsset
  /**
   * Staked character asset in a dojo slot
   */
  characterAsset: AtomicAsset
}

/**
 * Shows the sensei boost if a sensei asset is staked and shows the smartness boost
 * if the boost is greater than 0
 */
export const StakedCharacterInDojoBoosts: FC<
  StakedCharacterInDojoBoostsProps
> = props => {
  const senseiBoost = calculateXpSenseiBoost(props.senseiAsset)
  const smartnessBoost = calculateSmartnessBoost(props.characterAsset)

  return <div className='staked-character-in-dojo__boosts'>
    <div className='staked-character-in-dojo__boost'>
      <img src={UpArrow} alt='Green up arrow' />
      <span className='staked-character-in-dojo__boost-label'>
        Sensei XP boost
      </span>
      <span
        className='staked-character-in-dojo__boost-value'
        data-testid='staked-character-in-dojo-slot-sensei-boost'
      >+{senseiBoost.toString()}%</span>
    </div>
    {smartnessBoost.gt('0') && <div className='staked-character-in-dojo__boost'>
      <img src={UpArrow} alt='Green up arrow' />
      <span className='staked-character-in-dojo__boost-label'>
        Smartness boost
      </span>
      <span
        className='staked-character-in-dojo__boost-value'
        data-testid='staked-character-in-dojo-slot-smartness-boost'
      >+{smartnessBoost.toString()}%</span>
    </div>}
  </div>
}

type StakedCharacterInDojoProps = {
  /**
   * The training slot staked in Dojo with a character asset in there
   */
  trainingSlot: TrainingSlotWithAsset
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

/**
 * Renders the staked character asset card in dojo and interaction elements for this card
 */
export const StakedCharacterInDojo: FC<StakedCharacterInDojoProps> = props => {
  const stakedCharacterInDojo = useStakedCharacterInDojo(props.trainingSlot)

  return <>
    <div className='staked-character-in-dojo' data-testid='staked-character-in-dojo'>
      <div className='staked-character-in-dojo__card'>
        <CharacterCard
          powerDataHidden
          characterAsset={props.trainingSlot.characterAsset}
        />
        {stakedCharacterInDojo.scoredXp && <Toast className='staked-character-in-dojo__card-scored-xp'>
          +{stakedCharacterInDojo.scoredXp}XP
        </Toast>}
      </div>
      {stakedCharacterInDojo.senseiSlot && stakedCharacterInDojo.senseiSlot.senseiAsset && (
        <StakedCharacterInDojoBoosts
          senseiAsset={stakedCharacterInDojo.senseiSlot.senseiAsset}
          characterAsset={props.trainingSlot.characterAsset}
        />
      )}
      {stakedCharacterInDojo.progressDataForLevel && <StakedCharacterInDojoProgressXp
        progressValue={stakedCharacterInDojo.progressDataForLevel.progressValue}
        title={stakedCharacterInDojo.progressDataForLevel.title}
        className='staked-character-in-dojo__progress'
      />}
      <StakedCharacterInDojoButtons trainingSlot={props.trainingSlot}
        levelConfig={stakedCharacterInDojo.levelConfig} user={props.user} />
    </div>
  </>
}
