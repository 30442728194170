import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'
import { AtomicAsset } from '../../../../../api/atomic/types/asset'
import { miningSelector, openMine } from '../../../../../store/slices/miningSlice'
import { useAppDispatch } from '../../../../../store/types'

/**
 * Hook manages logic for opening a mine
 * @param user Registered user data
 */
export function useOpenMineModal(user: RegisteredUserData) {
  const dispatch = useAppDispatch()
  const mining = useSelector(miningSelector)

  const [selectedLandToOpenMineWith, setSelectedLandToOpenMineWith] = useState<AtomicAsset>()

  const openNewMineButtonDisabled = !selectedLandToOpenMineWith || mining.openingMine[selectedLandToOpenMineWith.asset_id] === 'pending'

  /**
   * Opens a new mine if a land asset is already selected in {@link selectedLandToOpenMineWith}
   * @param afterSuccessfulOpening Called after a mine is successfully opened
   */
  function openNewMine(afterSuccessfulOpening: () => void) {
    if (selectedLandToOpenMineWith) {
      dispatch(openMine({ land: selectedLandToOpenMineWith, user: user }))
        .unwrap()
        .then(() => {
          setSelectedLandToOpenMineWith(undefined)
          afterSuccessfulOpening()
        })
    }
  }

  /**
   * Selects the given land asset to open the mine with.
   * Basically, adds a land to {@link selectedLandToOpenMineWith}.
   * If the land is already added then removes the land from the state
   * @param land Land has to be selected to open a mine with
   */
  function selectLandToOpenMineWith(land: AtomicAsset) {
    setSelectedLandToOpenMineWith(prev => {
      if (prev?.asset_id === land.asset_id) {
        return undefined
      }
      return land
    })
  }

  return {
    /**
     * Selected land asset that will be used for opening a mine
     */
    selectedLandToOpenMineWith,
    /**
     * Indicates if the button for opening a mine is disabled
     */
    openNewMineButtonDisabled,
    selectLandToOpenMineWith,
    openNewMine,
  }
}
