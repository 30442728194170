import React, { FC } from 'react'

import { AppRoutes } from './AppRoutes'

import './App.scss'
import { useNowTime } from './hooks/useNowTime'
import { useScreenMode } from './hooks/useScreenMode'

export const App: FC = () => {
  useScreenMode()
  useNowTime()

  return <AppRoutes />
}
