import { FC, ReactNode, useMemo, useState } from 'react'
import Decimal from 'decimal.js'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { Modal } from '../../../../../components/Modal/Modal'
import { dojoSelector, priceToUnlockSlot } from '../../../../../store/slices/dojoSlice'
import { RegisteredUserData } from '../../../../../api/dungeon-worlds/user'
import { DojoBuildingHook } from '../useDojoBuilding'
import { settingsSelector } from '../../../../../store/slices/settingsSlice'
import { NftsScrollableContainer } from '../../../../../components/NftsScrollableContainer/NftsScrollableContainer'
import { nftsSelector } from '../../../../../store/slices/nftsSlice'
import { CharacterCard } from '../../../../../components/CharacterCard/CharacterCard'
import { Button } from '../../../../../components/Button/Button'
import { EmptySlot } from '../../../../../components/EmptySlot/EmptySlot'
import WombatTokenIcon from '../../../../../assets/images/icons/wombat-token.svg'
import { UnlockSlotConfirmationDialog } from './UnlockSlotConfirmationDialog/UnlockSlotConfirmationDialog'
import { StakedCharacterInDojo } from './StakedCharacterInDojo/StakedCharacterInDojo'
import { DojoModalHook, useDojoModal } from './useDojoModal'
import { userSelector } from '../../../../../store/slices/userSlice'
import { Toast } from '../../../../../components/Toast/Toast'
import { DojoBuildingImage } from '../DojoBuilding'
import './DojoModal.scss'

/**
 * Redirects user to the market for buying character assets
 */
const redirectToMarket = () => window.location.assign('https://wax.atomichub.io/market?collection_name=dungeonitems&order=desc&schema_name=characters&sort=created&state=1&symbol=WAX')

type DojoModalCharactersProps = {
  /**
   * Manages the business logic around the dojo modal
   */
  dojoModal: DojoModalHook
}

/**
 * Renders characters assets for dojo
 */
export const DojoModalCharacters: FC<DojoModalCharactersProps> = props => {
  const nfts = useSelector(nftsSelector)

  const userHasCharacters = nfts.characterNfts.length > 0

  let dojoModalCharactersContent: ReactNode

  if (userHasCharacters) {
    dojoModalCharactersContent = <NftsScrollableContainer>
      {nfts.characterNfts.map(character => (
        <CharacterCard
          key={character.asset_id} characterAsset={character}
          selected={character.asset_id === props.dojoModal.selectedAssetToTrain?.asset_id}
          powerDataHidden onClick={() => props.dojoModal.addAssetToTrain(character)}
          disabled={props.dojoModal.oneCharacterIsBeingStaked} dataTestId='dojo-modal-stakeable-character'
        />
      ))}
      <Button
        onClick={redirectToMarket} appearance='secondary'
        className='dojo-modal__characters-btn'
      >Buy more</Button>
    </NftsScrollableContainer>
  } else {
    dojoModalCharactersContent = <div className='dojo-modal__characters-no-nfts'>
      <span className='dojo-modal__characters-no-nfts-label'>You have no character.</span>
      <Button
        onClick={redirectToMarket} appearance='secondary'
      >Buy more</Button>
    </div>
  }

  return (
    <div className='dojo-modal__characters-and-slots-wrapper dojo-modal__characters'>
      {dojoModalCharactersContent}
      {props.dojoModal.newSlotToastShown && (
        <Toast className='dojo-modal__characters-toast'>New training slot added!</Toast>
      )}
    </div>
  )
}

type DojoModalSlotsProps = {
  /**
   * Closes the modal where the component is rendered
   */
  closeModal: () => void
  /**
   * Manages the business logic around the dojo modal
   */
  dojoModal: DojoModalHook
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

/**
 * Renders slots for dojo
 */
export const DojoModalSlots: FC<DojoModalSlotsProps> = props => {
  const dojo = useSelector(dojoSelector)
  const user = useSelector(userSelector)

  const [confirmationDialogShown, setConfirmationDialogShown] = useState(false)
  const openConfirmationDialog = () => setConfirmationDialogShown(true)
  const closeConfirmationDialog = () => setConfirmationDialogShown(false)

  const userHasEnoughWombatTokensForUnlocking = useMemo(() => {
    return new Decimal(user.wombatBalance).gte(priceToUnlockSlot)
  }, [user.wombatBalance, priceToUnlockSlot])

  const priceButtonDisabled = dojo.unlockingSlot === 'pending' || !userHasEnoughWombatTokensForUnlocking

  return <>
    <div className='dojo-modal__characters-and-slots-wrapper dojo-modal__slots'>
      <NftsScrollableContainer>
        {/* All available slots */}
        {dojo.trainingSlots.map(slot => {
          // If a character is in the slot then renders the character
          if (slot.characterAsset) {
            return <StakedCharacterInDojo user={props.user}
              trainingSlot={{
                ...slot, characterAsset: slot.characterAsset,
              }} key={slot.slot} />
          }

          // Button is disabled to be clicked if no asset is selected or
          // in the current slot a character asset is being staked.
          const buttonDisabled = !props.dojoModal.selectedAssetToTrain ||
            props.dojoModal.selectedSlotToTrain === slot.slot
          const buttonText = !props.dojoModal.selectedAssetToTrain ? 'Select character' : 'Train character'

          // If a character is not in the slot then renders an empty slot
          return <EmptySlot
            key={slot.slot}
            className='dojo-modal__slots-unlocked-slot'
          >
            <Button
              onClick={() => props.dojoModal.stakeCharacter(slot.slot)} size='small'
              disabled={buttonDisabled} className='dojo-modal__slots-unlocked-slot-button'
            >{buttonText}</Button>
          </EmptySlot>
        })}
        {/* One slot that can be bought and should be rendered always */}
        <EmptySlot warningLabel={userHasEnoughWombatTokensForUnlocking ? undefined : 'Not enough WOMBAT.'}>
          <Button
            onClick={openConfirmationDialog} disabled={priceButtonDisabled} size='small'
            icon={<img src={WombatTokenIcon} alt='Orange wombat in white circle' />}
            className='dojo-modal__slots-unlocked-slot-button'
          >{priceToUnlockSlot.toString()}</Button>
        </EmptySlot>
      </NftsScrollableContainer>
    </div>
    {confirmationDialogShown && <UnlockSlotConfirmationDialog
      closeDialog={closeConfirmationDialog} user={props.user}
    />}
  </>
}

type DojoModalProps = {
  /**
   * Closes modal
   */
  closeModal: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
  /**
   * Hook for managing dojo functionality
   */
  dojoBuilding: DojoBuildingHook
}

/**
 * Renders the modal which interacts with dojo.
 */
export const DojoModal: FC<DojoModalProps> = props => {
  const settings = useSelector(settingsSelector)
  const dojoModal = useDojoModal(props.user)

  return (
    <Modal onClickBackdropAndCloseButtons={props.closeModal} title='Dojo' className={classnames('dojo-modal', {
      'dojo-modal--portrait': settings.screenMode === 'PORTRAIT',
      'dojo-modal--landscape': settings.screenMode === 'LANDSCAPE',
    })}>
      <div className='dojo-modal__container'>
        <DojoBuildingImage dojoBuilding={props.dojoBuilding} className='dojo-modal__media' />
        <div className='dojo-modal__characters-and-slots'>
          <DojoModalCharacters dojoModal={dojoModal} />
          <DojoModalSlots user={props.user} dojoModal={dojoModal} closeModal={props.closeModal} />
        </div>
      </div>
    </Modal>
  )
}
