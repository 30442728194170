import { useAppDispatch } from '../../../../../../store/types'
import { useSelector } from 'react-redux'
import { nftsSelector } from '../../../../../../store/slices/nftsSlice'
import { dojoSelector, stakeSenseiAssetToSlot } from '../../../../../../store/slices/dojoSlice'
import { useState } from 'react'
import { AtomicAsset } from '../../../../../../api/atomic/types/asset'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'

/**
 * Hook contains business logic for the stakeable senseis modal
 * @param user Registered user data
 */
export function useStakeableSenseis (user: RegisteredUserData) {
  const dispatch = useAppDispatch()
  const { senseiNfts } = useSelector(nftsSelector)
  const { stakingSenseiAssetsInSlots } = useSelector(dojoSelector)

  // Selected sensei NFT to be staked later. Can be undefined if no NFT is selected
  const [selectedSenseiNft, setSelectedSenseiNft] = useState<AtomicAsset>()

  // Button is disabled if there is no selected NFT to stake chosen or
  // if one NFT is currently being staked
  const submitButtonDisabled = !selectedSenseiNft ||
    Object.values(stakingSenseiAssetsInSlots).some(item => item === 'pending')

  const stake = () => {
    if (selectedSenseiNft && !submitButtonDisabled) {
      dispatch(stakeSenseiAssetToSlot({
        user: user,
        slotWithAsset: {
          // So far, we support only one slot which starts from 0
          slot: 0, senseiAsset: selectedSenseiNft,
        }
      }))
    }
  }

  return {
    /**
     * All stakeable sensei NFTs the user owns
     */
    senseiNfts,
    /**
     * Selected sensei NFT to be staked later. Can be undefined if no NFT is selected
     */
    selectedSenseiNft,
    /**
     * Sets the selected sensei NFT to be staked later
     */
    setSelectedSenseiNft,
    /**
     * Indicates if the submit button is disabled
     */
    submitButtonDisabled,
    /**
     * Stakes the selected sensei NFT. It works only when {@link selectedSenseiNft} has
     * data in there AND {@link submitButtonDisabled} is false,
     * which means the confirm button is disabled
     */
    stake,
  }
}
