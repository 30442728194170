import { FC } from 'react'
import { ComponentWrapper } from '../ComponentsView'
import { RarityBadge } from '../../../components/RarityBadge/RarityBadge'

/**
 * The component is used by the Components page which is a list of all components the project has.
 * This component shouldn't be used in the production code at all!
 * The component is used to only see all states of the {@link RarityBadge} component
 * for developers
 */
export const RarityBadgeComponent: FC = () => {
  return <div>
    <ComponentWrapper title='Common'>
      <RarityBadge rarity='common' />
    </ComponentWrapper>
    <ComponentWrapper title='Rare'>
      <RarityBadge rarity='rare' />
    </ComponentWrapper>
    <ComponentWrapper title='Epic'>
      <RarityBadge rarity='epic' />
    </ComponentWrapper>
    <ComponentWrapper title='Legendary'>
      <RarityBadge rarity='legendary' />
    </ComponentWrapper>
    <ComponentWrapper title='Mythic'>
      <RarityBadge rarity='mythic' />
    </ComponentWrapper>
  </div>
}
