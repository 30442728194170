import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Dialog } from '../../../../../../components/Dialog/Dialog'
import { dojoSelector, priceToUnlockSlot, unlockSlotInDojo } from '../../../../../../store/slices/dojoSlice'
import { useAppDispatch } from '../../../../../../store/types'
import { RectangleFrame } from '../../../../../../components/RectangleFrame/RectangleFrame'
import WombatTokenIcon from '../../../../../../assets/images/icons/wombat-token.svg'
import { ButtonsContainer } from '../../../../../../components/ButtonsContainer/ButtonsContainer'
import { Button } from '../../../../../../components/Button/Button'
import { RegisteredUserData } from '../../../../../../api/dungeon-worlds/user'
import './UnlockSlotConfirmationDialog.scss'

type UnlockSlotConfirmationDialogProps = {
  /**
   * Closes the dialog
   */
  closeDialog: () => void
  /**
   * Registered user data
   */
  user: RegisteredUserData
}

/**
 * Renders a dialog for confirmation of unlocking a slot
 */
export const UnlockSlotConfirmationDialog: FC<UnlockSlotConfirmationDialogProps> = props => {
  const dispatch = useAppDispatch()
  const dojo = useSelector(dojoSelector)

  const unlockNewSlot = async () => {
    await dispatch(unlockSlotInDojo(props.user))
    props.closeDialog()
  }

  const buttonDisabled = dojo.unlockingSlot === 'pending'

  return <Dialog onBackdropClick={props.closeDialog} className='unlock-slot-confirmation-dialog'>
    <RectangleFrame className='unlock-slot-confirmation-dialog__frame'>
      <h3 className='unlock-slot-confirmation-dialog__title'>
        Do you want to add a new training slot?
      </h3>
      <div className='unlock-slot-confirmation-dialog__price'>
        <img src={WombatTokenIcon} alt='Orange wombat in a white circle' />
        {priceToUnlockSlot.toString()}
      </div>
    </RectangleFrame>
    <ButtonsContainer oneRowInPortraitMode>
      <Button onClick={props.closeDialog} appearance='secondary'>No</Button>
      <Button
        onClick={unlockNewSlot} disabled={buttonDisabled}
        dataTestId='unlock-slot-confirmation-button'
      >Yes</Button>
    </ButtonsContainer>
  </Dialog>
}
