import React, { FC, ReactNode } from 'react'

import { Button } from '../../../../components/Button/Button'
import { RectangleFrame } from '../../../../components/RectangleFrame/RectangleFrame'

import './BuildingAction.scss'

type BuildingActionProps = {
  /**
   * Text for the button
   */
  text: ReactNode
  /**
   * Icon for the button
   */
  icon: ReactNode
  /**
   * On click action
   */
  onClick: () => void
  /**
   * Data test ID used by Jest
   */
  dataTestId?: string
}

export const BuildingAction: FC<BuildingActionProps> = props => {
  return <div className='building-action' data-testid={props.dataTestId}>
    <div className='building-action__external-border'>
      <RectangleFrame frameColor='purple' className='building-action__frame'>
        {props.icon}
      </RectangleFrame>
      <Button
        onClick={props.onClick} appearance='tertiary'
        size='small' className='building-action__button'
      >{props.text}</Button>
    </div>
  </div>
}
